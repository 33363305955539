import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import {
  userProfilesByOwner,
  getPublicProfilesByUserProfileId,
} from "../graphql/queries";
import { useDispatch } from "react-redux";
import GenderList, { genderMap } from "../containers/GenderList";
import { setNickname, setUserProfileId } from "../features/UserProfileSlice";
import JapanPrefectureList, {
  japanProvinceMap,
} from "../containers/JapanProvinceList";
import JapanCitiesList from "../containers/JapanCitiesList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import DOMPurify from "dompurify";
import "../styles/MySettings.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MySettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState({});
  const [editValues, setEditValues] = useState({
    nickname: "",
    gender: "",
    bio: "",
    userProvince: "",
    userCity: "",
  });
  const [isEditing, setIsEditing] = useState({
    nickname: false,
    gender: false,
    bio: false,
    userProvince: false,
    userCity: false,
  });
  const [birthdate, setBirthdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState({
    nickname: "",
    bio: "",
    userProvince: "",
    userCity: "",
  });
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const getJapaneseCity = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error");
    }
  };

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(), // キャッシュした時間を保存
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          // 144000分以上経過している場合は無効にする。
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key); // 有効でないデータを削除
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key); // 有効でないデータを削除
      return null;
    }
  };

  // fetchUserProfile関数内
  async function fetchUserProfile() {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      const owner = user.attributes.sub;

      const userData = await API.graphql({
        query: userProfilesByOwner,
        variables: { owner: owner, limit: 1 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (userData.data.userProfilesByOwner.items.length > 0) {
        const userProfile = userData.data.userProfilesByOwner.items[0];
        setUserProfile(userProfile);
        setEditValues({
          nickname: userProfile.nickname,
          gender: userProfile.gender,
          bio: userProfile.bio,
          userCity: userProfile.userCity,
          userProvince:
            Object.keys(japanProvinceMap).find(
              (key) => japanProvinceMap[key] === userProfile.userProvince
            ) || userProfile.userProvince,
          birthdate: userProfile.birthdate,
        });

        setBirthdate(userProfile.birthdate);
        dispatch(setNickname(userProfile.nickname)); // ニックネームをReduxストアに格納する
        dispatch(setUserProfileId(userProfile.id)); // ユーザープロファイルIDをReduxストアに格納する

        // プロフィール画像のキャッシュチェックを先に行う
        const cachedData = getCachedImageUrl(userProfile.profileImageUrl);

        if (cachedData) {
          const cachedUpdatedAt = new Date(cachedData.updatedAt);
          const userProfileUpdatedAt = new Date(userProfile.updatedAt);
          const diffInMinutes =
            (userProfileUpdatedAt - cachedUpdatedAt) / (1000 * 60);

          if (diffInMinutes < 2) {
            setUserProfile((prevProfile) => ({
              ...prevProfile,
              profileImageURL: cachedData.url,
            }));
          } else {
            const imageUrl = await fetchImageFromS3(userProfile);
            setUserProfile((prevProfile) => ({
              ...prevProfile,
              profileImageURL: imageUrl,
            }));
          }
        } else {
          const imageUrl = await fetchImageFromS3(userProfile);
          setUserProfile((prevProfile) => ({
            ...prevProfile,
            profileImageURL: imageUrl,
          }));
        }
      } else {
        console.warn("User profile not found");
      }
    } catch (err) {
      console.error("Error fetching user profile", err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchImageFromS3(userProfile) {
    try {
      // CloudFrontの基本URL
      const cloudFrontBaseUrl = "https://d1s9xshr26t6r2.cloudfront.net";
      // 画像のキーをCloudFront URLに変換
      const imageUrl = `${cloudFrontBaseUrl}/public/${userProfile.profileImageUrl}`;

      // CloudFront URLをキャッシュに保存
      setCachedImageUrl(
        userProfile.profileImageUrl,
        imageUrl,
        userProfile.updatedAt,
        userProfile.updatedAt
      );
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image from CloudFront:", error);
      return null;
    }
  }

  function toggleEdit(field) {
    setIsEditing((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  }

  function changeProfileImage() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.style.display = "none"; // 入力要素を非表示にする
    fileInput.addEventListener("change", handleFileSelect);
    document.body.appendChild(fileInput); // ドキュメントに追加
    fileInput.click(); // クリックイベントを発火
  }

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file && userProfile.id) {
      navigate(`/changeimages/${userProfile.id}`, { state: { file } });
    } else {
      console.error("ユーザープロフィールのIDが見つかりません");
    }
    document.body.removeChild(event.target); // ファイル入力要素をドキュメントから削除
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    let sanitizedValue = DOMPurify.sanitize(value); // サニタイズされた値
    let errorMessage = "";

    if (name === "nickname" && sanitizedValue.length > 15) {
      errorMessage = "15文字までです";
    } else if (name === "bio" && sanitizedValue.length > 150) {
      errorMessage = "150文字までです";
    } else if (
      name === "userProvince" &&
      japanProvinceMap[sanitizedValue] &&
      japanProvinceMap[sanitizedValue].length > 18
    ) {
      errorMessage = "18文字までです";
    } else if (name === "gender") {
      setEditValues((prevState) => ({ ...prevState, gender: sanitizedValue }));
    }

    setErrorMessages((prevState) => ({ ...prevState, [name]: errorMessage }));
    setEditValues((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
      ...(name === "userProvince" && { userCity: "" }), // userProvinceが変更されたらuserCityをリセット
    }));
  }

  async function handleSubmit(field) {
    try {
      setIsOverlayVisible(true);
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const owner = await user.attributes.sub;

      // すべての変更ボタンと「変更画面を開く」リンクを無効にする
      const allSubmitButtons = document.querySelectorAll(".settings-button");
      const allToggleLinks = document.querySelectorAll(".toggle-edit-link");
      allSubmitButtons.forEach((button) => {
        button.disabled = true;
      });
      allToggleLinks.forEach((link) => {
        link.disabled = true;
        link.style.pointerEvents = "none";
        link.style.color = "gray";
      });

      const submitButton = document.querySelector(
        `.settings-button[data-field="${field}"]`
      );
      if (submitButton) {
        submitButton.disabled = true;
      }

      const sanitizedEditValues = {
        nickname: DOMPurify.sanitize(editValues.nickname),
        gender: DOMPurify.sanitize(editValues.gender),
        bio: DOMPurify.sanitize(editValues.bio),
        userProvince: DOMPurify.sanitize(editValues.userProvince),
        userCity: DOMPurify.sanitize(editValues.userCity),
        owner: owner,
      };

      const updateData = {
        id: userProfile.id,
        nickname: sanitizedEditValues.nickname,
        gender: sanitizedEditValues.gender,
        bio: sanitizedEditValues.bio,
        userProvince: sanitizedEditValues.userProvince,
        userCity: sanitizedEditValues.userCity,
      };

      /* const updateUserProfileResult = await API.graphql({
        query: updateUserProfile,
        variables: { input: updateData },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }); */

      const updateUserProfileResult = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/user-profile",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify(updateData),
        }
      );

      const responseData = await updateUserProfileResult.json();
      const updatedUserProfileId = await responseData.id;

      /*  const updatedUserProfileId =
        updateUserProfileResult.data.updateUserProfile.id; */

      const publicProfileResponse = await API.graphql({
        query: getPublicProfilesByUserProfileId,
        variables: { userProfileId: updatedUserProfileId },
      });

      const publicProfileData = {
        id: publicProfileResponse.data.publicProfilesByUserProfileId.items[0]
          .id,
        nickname: sanitizedEditValues.nickname,
        userProvince: sanitizedEditValues.userProvince,
        userCity: sanitizedEditValues.userCity,
        bio: sanitizedEditValues.bio,
        userProfileId: userProfile.id,
        gender: sanitizedEditValues.gender,
        profileImageUrl:
          publicProfileResponse.data.publicProfilesByUserProfileId.items[0]
            .profileImageUrl,
      };

      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/public-profile",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify(publicProfileData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update public profile");
      }

      setUserProfile((prevProfile) => ({
        ...prevProfile,
        [field]: sanitizedEditValues[field],
        ...(field === "userProvince" && {
          userCity: sanitizedEditValues.userCity,
        }),
      }));
      setIsEditing((prevState) => ({ ...prevState, [field]: false }));
      toast.success("プロフィールを更新しました", {
        autoClose: 1500,
      });
      setIsOverlayVisible(false);
    } catch (err) {
      console.error("Error updating profile", err);
      toast.error("プロフィールを更新できませんでした");
      setIsOverlayVisible(false);
    } finally {
      // すべての変更ボタンと「変更画面を開く」リンクを有効にする
      const allSubmitButtons = document.querySelectorAll(".settings-button");
      const allToggleLinks = document.querySelectorAll(".toggle-edit-link");
      allSubmitButtons.forEach((button) => {
        button.disabled = false;
      });
      allToggleLinks.forEach((link) => {
        link.disabled = false;
        link.style.pointerEvents = "auto";
        link.style.color = "blue";
      });

      const submitButton = document.querySelector(
        `.settings-button[data-field="${field}"]`
      );
      if (submitButton) {
        submitButton.disabled = false;
      }
    }
  }

  return (
    <div className="settings-container">
      {isOverlayVisible && (
        <div className="mySettingsOverlay">
          <div className="spinner"></div>
        </div>
      )}
      <h2>プロフィール設定</h2>
      {loading ? (
        <p className="loading-message">Loading...</p>
      ) : (
        <>
          <div>
            <div>
              {userProfile.profileImageURL && (
                <img
                  src={
                    userProfile.profileImageURL ===
                    "https://d1s9xshr26t6r2.cloudfront.net/public/profileImages/default_images/user_placeholder.png"
                      ? require("../assets/user_placeholder.png")
                      : userProfile.profileImageURL
                  }
                  alt="プロフィール画像"
                />
              )}
            </div>
            <button
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
                background: "none",
                border: "none",
                padding: 0,
              }}
              onClick={changeProfileImage}
            >
              アバターを変更
            </button>
          </div>

          {Object.keys(isEditing).map((key) => {
            if (key === "userCity") {
              // "userCity"の変更欄は表示しない
              return null;
            }

            return (
              <div key={key}>
                <p>
                  {key === "nickname" && "ニックネーム："}
                  {key === "gender" && `性別：${genderMap[userProfile[key]]}`}
                  {key === "bio" && (
                    <>
                      自己紹介：
                      <br />
                      {(() => {
                        const bioLines = userProfile[key]
                          .match(/.{1,40}/g)
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index !==
                                userProfile[key].match(/.{1,40}/g).length -
                                  1 && <br />}
                            </React.Fragment>
                          ));
                        return bioLines;
                      })()}
                    </>
                  )}
                  {key === "userProvince" &&
                    `地域：${
                      japanProvinceMap[userProfile[key]]
                    } ${getJapaneseCity(
                      userProfile.userProvince,
                      userProfile.userCity
                    )}`}
                  {key !== "bio" &&
                    key !== "gender" &&
                    key !== "userProvince" &&
                    userProfile[key]}
                </p>
                {isEditing[key] ? (
                  <>
                    {key === "gender" ? (
                      <GenderList
                        onSelect={(e) =>
                          handleInputChange({
                            target: {
                              name: "gender",
                              value: DOMPurify.sanitize(e.target.value),
                            },
                          })
                        }
                        selectedGender={editValues[key]}
                      />
                    ) : key === "bio" ? (
                      <div className="bio-input-container">
                        <textarea
                          className="settings-input bio-input"
                          value={editValues[key]}
                          onChange={(e) => handleInputChange(e)}
                          name={key}
                          placeholder={`自己紹介文を150文字以内で入力してください`}
                        />
                        <div
                          className="bio-char-count"
                          style={{
                            color:
                              editValues[key].length > 150 ? "red" : "black",
                          }}
                        >
                          {editValues[key].length}/150
                        </div>
                        {errorMessages[key] && (
                          <span style={{ color: "red" }}>
                            {errorMessages[key]}
                          </span>
                        )}
                      </div>
                    ) : key === "userProvince" ? (
                      <>
                        <JapanPrefectureList
                          onSelect={(e) =>
                            handleInputChange({
                              target: {
                                name: "userProvince",
                                value: DOMPurify.sanitize(e.target.value),
                              },
                            })
                          }
                          selectedRegion={editValues[key]}
                          excludeAll={true}
                        />
                        <JapanCitiesList
                          onSelect={(e) =>
                            handleInputChange({
                              target: {
                                name: "userCity",
                                value: DOMPurify.sanitize(e.target.value),
                              },
                            })
                          }
                          selectedCity={editValues.userCity}
                          region={editValues.userProvince} // ここで都道府県の値を反映
                          key={editValues.userCity}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          className={`settings-input ${
                            key === "nickname" ? "nickname-input" : ""
                          }`}
                          value={editValues[key]}
                          onChange={(e) => handleInputChange(e)}
                          name={key}
                          placeholder={`新しい${key}`}
                        />
                        {errorMessages[key] && (
                          <span style={{ color: "red" }}>
                            {errorMessages[key]}
                          </span>
                        )}
                      </>
                    )}

                    <br />
                    <button
                      className="settings-button change-button"
                      data-field={key}
                      onClick={() => handleSubmit(key)}
                      disabled={
                        !!errorMessages[key] ||
                        !editValues[key].trim() ||
                        (key === "bio" && editValues[key].length > 150)
                      }
                    >
                      変更
                    </button>
                    <button
                      className="toggle-edit-link"
                      style={{
                        cursor: "pointer",
                        color: "black",
                        textDecoration: "none",
                        background: "none",
                        border: "none",
                        padding: 0,
                      }}
                      onClick={() => toggleEdit(key)}
                    >
                      ▲閉じる
                    </button>
                  </>
                ) : (
                  <button
                    className="toggle-edit-link"
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                      background: "none",
                      border: "none",
                      padding: 0,
                    }}
                    onClick={() => toggleEdit(key)}
                  >
                    ⇩変更画面を開く
                  </button>
                )}
              </div>
            );
          })}
          <p>生年月日：{birthdate}</p>
        </>
      )}
      <br />
      <br />
      <button className="signout-button" onClick={signOut}>
        サインアウト
      </button>
      <ToastContainer />
    </div>
  );
}

export default MySettings;
