/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      postId
      title
      contactContent
      senderEmail
      recipientEmail
      createdAt
      ip_addr
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postId
        title
        contactContent
        senderEmail
        recipientEmail
        createdAt
        ip_addr
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactsByPostId = /* GraphQL */ `
  query ContactsByPostId(
    $postId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByPostId(
      postId: $postId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        title
        contactContent
        senderEmail
        recipientEmail
        createdAt
        ip_addr
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      content
      category
      postCountry
      postProvince
      postCity
      userProfileId
      comments {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      deletedAt
      options
      postingStatus
      postingViews
      favoritesCount
      commentsCount
      emailForContact
      postImageUrl1
      postImageUrlOthers
      postDetails {
        __typename
      }
      allowDirectMessages
      allowEmailContacts
      ip_addr
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        owner
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByTitle = /* GraphQL */ `
  query PostsByTitle(
    $title: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByTitle(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        owner
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByContent = /* GraphQL */ `
  query PostsByContent(
    $content: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByContent(
      content: $content
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        owner
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByUserProfileId = /* GraphQL */ `
  query PostsByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        owner
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByFavoritesCount = /* GraphQL */ `
  query PostsByFavoritesCount(
    $favoritesCount: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByFavoritesCount(
      favoritesCount: $favoritesCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        owner
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByCommentsCount = /* GraphQL */ `
  query PostsByCommentsCount(
    $commentsCount: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByCommentsCount(
      commentsCount: $commentsCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        owner
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      postID
      userProfileId
      content
      owner
      createdAt
      updatedAt
      deletedAt
      notifications {
        nextToken
        __typename
      }
      ip_addr
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        userProfileId
        content
        owner
        createdAt
        updatedAt
        deletedAt
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByPostID = /* GraphQL */ `
  query CommentsByPostID(
    $postID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByPostID(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        userProfileId
        content
        owner
        createdAt
        updatedAt
        deletedAt
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByUserProfileId = /* GraphQL */ `
  query CommentsByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        userProfileId
        content
        owner
        createdAt
        updatedAt
        deletedAt
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      contributor
      createdAt
      updatedAt
      deletedAt
      messagesListID
      owner
      notifications {
        nextToken
        __typename
      }
      ip_addr
      hiddenFrom
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        contributor
        createdAt
        updatedAt
        deletedAt
        messagesListID
        owner
        ip_addr
        hiddenFrom
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByMessagesListID = /* GraphQL */ `
  query MessagesByMessagesListID(
    $messagesListID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByMessagesListID(
      messagesListID: $messagesListID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        contributor
        createdAt
        updatedAt
        deletedAt
        messagesListID
        owner
        ip_addr
        hiddenFrom
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessagesList = /* GraphQL */ `
  query GetMessagesList($id: ID!) {
    getMessagesList(id: $id) {
      id
      messageFrom
      messageTo
      checkedStatus
      createdAt
      updatedAt
      deletedAt
      owner
      deletedBy
      blockedBy
      __typename
    }
  }
`;
export const listMessagesLists = /* GraphQL */ `
  query ListMessagesLists(
    $filter: ModelMessagesListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageFrom
        messageTo
        checkedStatus
        createdAt
        updatedAt
        deletedAt
        owner
        deletedBy
        blockedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesListsByMessageFromAndCreatedAt = /* GraphQL */ `
  query MessagesListsByMessageFromAndCreatedAt(
    $messageFrom: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessagesListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesListsByMessageFromAndCreatedAt(
      messageFrom: $messageFrom
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageFrom
        messageTo
        checkedStatus
        createdAt
        updatedAt
        deletedAt
        owner
        deletedBy
        blockedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesListsByMessageToAndCreatedAt = /* GraphQL */ `
  query MessagesListsByMessageToAndCreatedAt(
    $messageTo: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessagesListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesListsByMessageToAndCreatedAt(
      messageTo: $messageTo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageFrom
        messageTo
        checkedStatus
        createdAt
        updatedAt
        deletedAt
        owner
        deletedBy
        blockedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      referenceID
      owner
      createdAt
      updatedAt
      deletedAt
      isNotified
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        referenceID
        owner
        createdAt
        updatedAt
        deletedAt
        isNotified
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByOwner = /* GraphQL */ `
  query NotificationsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        referenceID
        owner
        createdAt
        updatedAt
        deletedAt
        isNotified
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      id
      postID
      owner
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        owner
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const favoritesByPostID = /* GraphQL */ `
  query FavoritesByPostID(
    $postID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favoritesByPostID(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        owner
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      nickname
      username
      gender
      bio
      userCountry
      userProvince
      userCity
      owner
      createdAt
      updatedAt
      deletedAt
      birthdate
      family_name
      given_name
      selectLanguage
      profileImageUrl
      ip_addr
      ipAddressAddedAt
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $id: ID
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nickname
        username
        gender
        bio
        userCountry
        userProvince
        userCity
        owner
        createdAt
        updatedAt
        deletedAt
        birthdate
        family_name
        given_name
        selectLanguage
        profileImageUrl
        ip_addr
        ipAddressAddedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userProfilesByOwner = /* GraphQL */ `
  query UserProfilesByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProfilesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nickname
        username
        gender
        bio
        userCountry
        userProvince
        userCity
        owner
        createdAt
        updatedAt
        deletedAt
        birthdate
        family_name
        given_name
        selectLanguage
        profileImageUrl
        ip_addr
        ipAddressAddedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicProfile = /* GraphQL */ `
  query GetPublicProfile($id: ID!) {
    getPublicProfile(id: $id) {
      id
      userProfileId
      nickname
      username
      bio
      userCountry
      userProvince
      userCity
      profileImageUrl
      gender
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPublicProfiles = /* GraphQL */ `
  query ListPublicProfiles(
    $filter: ModelPublicProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userProfileId
        nickname
        username
        bio
        userCountry
        userProvince
        userCity
        profileImageUrl
        gender
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicProfilesByUserProfileId = /* GraphQL */ `
  query PublicProfilesByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPublicProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicProfilesByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProfileId
        nickname
        username
        bio
        userCountry
        userProvince
        userCity
        profileImageUrl
        gender
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      reportedEntityId
      reportedEntityType
      reason
      additionalInfo
      createdAt
      updatedAt
      owner
      userProfileId
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reportedEntityId
        reportedEntityType
        reason
        additionalInfo
        createdAt
        updatedAt
        owner
        userProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsByOwner = /* GraphQL */ `
  query ReportsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportedEntityId
        reportedEntityType
        reason
        additionalInfo
        createdAt
        updatedAt
        owner
        userProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsByUserProfileId = /* GraphQL */ `
  query ReportsByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportedEntityId
        reportedEntityType
        reason
        additionalInfo
        createdAt
        updatedAt
        owner
        userProfileId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearchHistory = /* GraphQL */ `
  query GetSearchHistory($id: ID!) {
    getSearchHistory(id: $id) {
      id
      searchWord
      userProfileId
      userRegion
      gender
      birthdate
      createdAt
      deletedAt
      ip_addr
      owner
      updatedAt
      __typename
    }
  }
`;
export const listSearchHistories = /* GraphQL */ `
  query ListSearchHistories(
    $filter: ModelSearchHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        searchWord
        userProfileId
        userRegion
        gender
        birthdate
        createdAt
        deletedAt
        ip_addr
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchHistoriesByUserProfileId = /* GraphQL */ `
  query SearchHistoriesByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSearchHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchHistoriesByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        searchWord
        userProfileId
        userRegion
        gender
        birthdate
        createdAt
        deletedAt
        ip_addr
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchHistoriesByOwner = /* GraphQL */ `
  query SearchHistoriesByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSearchHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchHistoriesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        searchWord
        userProfileId
        userRegion
        gender
        birthdate
        createdAt
        deletedAt
        ip_addr
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInquiry = /* GraphQL */ `
  query GetInquiry($id: ID!) {
    getInquiry(id: $id) {
      id
      inquiryType
      email
      postURL
      content
      createdAt
      updatedAt
      deletedAt
      status
      responseDetails
      completedAt
      ip_addr
      __typename
    }
  }
`;
export const listInquiries = /* GraphQL */ `
  query ListInquiries(
    $filter: ModelInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInquiries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inquiryType
        email
        postURL
        content
        createdAt
        updatedAt
        deletedAt
        status
        responseDetails
        completedAt
        ip_addr
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listFavoritesWithPost = /* GraphQL */ `
  query ListFavoritesWithPost(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
      }
      nextToken
    }
  }
`;

export const listFavoritesForOwner = /* GraphQL */ `
  query ListFavoritesForOwner(
    $owner: String!
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(
      filter: { owner: { eq: $owner } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
      }
      nextToken
    }
  }
`;

export const getPublicProfilesByUserProfileId = /* GraphQL */ `
  query GetPublicProfilesByUserProfileId(
    $userProfileId: ID!
    $limit: Int
    $nextToken: String
  ) {
    publicProfilesByUserProfileId(
      userProfileId: $userProfileId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userProfileId
        profileImageUrl
        nickname
        username
        bio
        userProvince
        userCity
        gender
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const excludeOwnerComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        postID
        userProfileId
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const excludeOwnerMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        contributor
        createdAt
        updatedAt
        messagesListID
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const excludeOwnerGetMessagesList = /* GraphQL */ `
  query ExcludeOwnerGetMessagesList($id: ID!) {
    getMessagesList(id: $id) {
      id
      messageFrom
      messageTo
      checkedStatus
      createdAt
      updatedAt
      deletedAt
      deletedBy
      blockedBy
      __typename
    }
  }
`;

export const excludeOwnerMessagesLists = /* GraphQL */ `
  query ListMessagesLists(
    $filter: ModelMessagesListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageFrom
        messageTo
        checkedStatus
        createdAt
        updatedAt
        deletedBy
        blockedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const includeOwnerPosts = /* GraphQL */ `
  query ExcludeOwnerPosts($id: ID!) {
    getPost(id: $id) {
      id
      title
      content
      category
      postCountry
      postProvince
      postCity
      userProfileId
      owner
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      options
      postingStatus
      postingViews
      favoritesCount
      commentsCount
      emailForContact
      postImageUrl1
      postImageUrlOthers
      allowDirectMessages
      allowEmailContacts
      postDetails {
        jobPostDetails {
          wageStructure
          payCurrency
          salary
          workLocation
          employmentType
          jobCategory
          contactPerson
          email
          phone
          websiteURL
        }
        rentRealEstatePostDetails {
          rentRealEstateCategory
          payCurrency
          builtYear
          floorPlan
          rent
          exclusiveArea
          deposit
          keyMoney
          moveInDate
          rentRegion
          contactPerson
          email
          websiteURL
        }
        saleRealEstatePostDetails {
          saleRealEstateCategory
          payCurrency
          salePrice
          floorPlan
          landArea
          buildingArea
          builtYear
          availableDate
          saleRegion
          contactPerson
          email
          websiteURL
        }
        memberRecruitmentDetails {
          eventDate
          payCurrency
          subCategory
          recruitmentAge
          participationFee
          email
          websiteURL
        }
        eventDetails {
          startDate
          endDate
          subCategory
          payCurrency
          participationFee
          eventTime
          email
          websiteURL
        }
        bbsDetails {
          subCategory
        }
      }
      __typename
    }
  }
`;
export const excludeOwnerPosts = /* GraphQL */ `
  query ExcludeOwnerPosts($id: ID!) {
    getPost(id: $id) {
      id
      title
      content
      category
      postCountry
      postProvince
      postCity
      userProfileId
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      options
      postingStatus
      postingViews
      favoritesCount
      commentsCount
      emailForContact
      postImageUrl1
      postImageUrlOthers
      allowDirectMessages
      allowEmailContacts
      postDetails {
        jobPostDetails {
          wageStructure
          payCurrency
          salary
          workLocation
          employmentType
          jobCategory
          contactPerson
          email
          phone
          websiteURL
        }
        rentRealEstatePostDetails {
          rentRealEstateCategory
          payCurrency
          builtYear
          floorPlan
          rent
          exclusiveArea
          deposit
          keyMoney
          moveInDate
          rentRegion
          contactPerson
          email
          websiteURL
        }
        saleRealEstatePostDetails {
          saleRealEstateCategory
          payCurrency
          salePrice
          floorPlan
          landArea
          buildingArea
          builtYear
          availableDate
          saleRegion
          contactPerson
          email
          websiteURL
        }
        memberRecruitmentDetails {
          eventDate
          payCurrency
          subCategory
          recruitmentAge
          participationFee
          email
          websiteURL
        }
        eventDetails {
          startDate
          endDate
          subCategory
          payCurrency
          participationFee
          eventTime
          email
          websiteURL
        }
        bbsDetails {
          subCategory
        }
      }
      __typename
    }
  }
`;

export const excludeOwnerListPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount
        emailForContact
        postImageUrl1
        postImageUrlOthers
        allowDirectMessages
        allowEmailContacts
        postDetails {
          jobPostDetails {
            wageStructure
            payCurrency
            salary
            workLocation
            employmentType
            jobCategory
            contactPerson
            email
            phone
            websiteURL
          }
          rentRealEstatePostDetails {
            rentRealEstateCategory
            payCurrency
            builtYear
            floorPlan
            rent
            exclusiveArea
            deposit
            keyMoney
            moveInDate
            rentRegion
            contactPerson
            email
            websiteURL
          }
          saleRealEstatePostDetails {
            saleRealEstateCategory
            payCurrency
            salePrice
            floorPlan
            landArea
            buildingArea
            builtYear
            availableDate
            saleRegion
            contactPerson
            email
            websiteURL
          }
          memberRecruitmentDetails {
            eventDate
            payCurrency
            subCategory
            recruitmentAge
            participationFee
            email
            websiteURL
          }
          eventDetails {
            startDate
            endDate
            subCategory
            payCurrency
            participationFee
            eventTime
            email
            websiteURL
          }
          bbsDetails {
            subCategory
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostOnlyTitle = /* GraphQL */ `
  query GetPostOnlyTitle($id: ID!) {
    getPost(id: $id) {
      id
      title
    }
  }
`;
export const getPostOnlyCommentsCount = /* GraphQL */ `
  query getPostOnlyCommentsCount($id: ID!) {
    getPost(id: $id) {
      id
      commentsCount
    }
  }
`;

export const listPostsByTitleAndContent = /* GraphQL */ `
  query ListPostsByTitleAndContent(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        category
        postCountry
        postProvince
        postCity
        userProfileId
        comments {
          items {
            id
            content
          }
        }
        createdAt
        updatedAt
        deletedAt
        options
        postingStatus
        postingViews
        favoritesCount
        commentsCount

        postImageUrl1
        postImageUrlOthers
        postDetails {
          jobPostDetails {
            wageStructure
            payCurrency
            salary
            workLocation
            employmentType
            jobCategory
            contactPerson
            email
            phone
            websiteURL
          }
          rentRealEstatePostDetails {
            rentRealEstateCategory
            payCurrency
            builtYear
            floorPlan
            rent
            exclusiveArea
            deposit
            keyMoney
            moveInDate
            rentRegion
            contactPerson
            email
            websiteURL
          }
          saleRealEstatePostDetails {
            saleRealEstateCategory
            payCurrency
            salePrice
            floorPlan
            landArea
            buildingArea
            builtYear
            availableDate
            saleRegion
            contactPerson
            email
            websiteURL
          }
          memberRecruitmentDetails {
            eventDate
            payCurrency
            subCategory
            recruitmentAge
            participationFee
            email
            websiteURL
          }
          eventDetails {
            startDate
            endDate
            subCategory
            payCurrency
            participationFee
            eventTime
            email
            websiteURL
          }
          bbsDetails {
            subCategory
          }
        }
        ip_addr
      }
      nextToken
    }
  }
`;
