import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"; // useSelectorをインポート
import "../styles/SelectCategory.css"; // CSSファイルをインポート

function SelectCategory() {
  // カテゴリの定義
  const categories = {
    recruiting: "求人募集",
    joinus: "メンバー募集",
    event: "イベント",
    rentrealestate: "賃貸不動産",
    salerealestate: "売買不動産",
    bbs: "掲示板",
  };

  // useSelectorフックを用いて、Reduxストアから`regionPrefecture`を取得
  const regionPrefecture = useSelector((state) => state.region.prefecture);

  return (
    <div>
      <nav>
        {Object.entries(categories).map(([path, name]) => (
          // 各項目の`to`属性に、選択された都道府県（regionPrefecture）を含むパスを設定
          <div key={path} className="category-item">
            <Link
              to={`/region/${regionPrefecture}/${path}`}
              style={{ textDecoration: "none" }}
            >
              {name}
            </Link>
          </div>
        ))}
      </nav>
    </div>
  );
}

export default SelectCategory;
