import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, Auth } from "aws-amplify";
import {
  getPublicProfilesByUserProfileId,
  userProfilesByOwner,
} from "../graphql/queries";
import styles from "../styles/MessagesList.module.css";
import Modal from "react-modal";

Modal.setAppElement("#root"); // 追加

function MessagesList() {
  const [lists, setLists] = useState([]);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [blockedLists, setBlockedLists] = useState([]); // 追加
  const [isModalOpen, setIsModalOpen] = useState(false); // 追加
  const profileid = useSelector((state) => state.userProfile.profileid); // profileidをReduxのstoreから取得
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [selectedListId, setSelectedListId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const confirmModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: "150px",
    },
  };

  useEffect(() => {
    const initializeData = async () => {
      await fetchUserInfo();
      await fetchLists();
    };

    initializeData();
  }, []);

  /* const truncateMessage = (message, maxLength) => {
    if (message.length > maxLength) {
      return message.slice(0, maxLength) + "...";
    }
    return message;
  }; */
  const truncateMessage = (message, maxLength) => {
    const isMobile = window.innerWidth <= 480; // 画面幅が480px以下の場合はモバイルと判断
    const maxLengthForMobile = 25; // モバイルの場合の最大文字数

    if (isMobile && message.length > maxLengthForMobile) {
      return message.slice(0, maxLengthForMobile) + "...";
    }

    if (message.length > maxLength) {
      return message.slice(0, maxLength) + "...";
    }
    return message;
  };

  const setCachedImageUrl = (key, url, lastModified) => {
    const cachedData = {
      url,
      lastModified,
      cacheTime: Date.now(), // キャッシュした時間を保存
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      // parsedDataがオブジェクトであり、必要なプロパティが存在するかをチェック
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          // 144000分以上経過している場合は無効にする
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key); // 有効でないデータを削除
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key); // 有効でないデータを削除
      return null;
    }
  };

  const fetchUserInfo = async () => {
    setIsLoading(true);
    const userInfo = await Auth.currentUserInfo();
    setCurrentUserInfo(userInfo);
  };

  const fetchProfileImage = async (userProfileId) => {
    try {
      const userProfileData = await API.graphql({
        query: getPublicProfilesByUserProfileId,
        variables: {
          userProfileId,
          limit: 1,
        },
      });

      const userProfile =
        userProfileData.data.publicProfilesByUserProfileId.items[0];
      if (userProfile) {
        const { profileImageUrl } = userProfile;
        const cachedData = getCachedImageUrl(profileImageUrl);

        if (cachedData) {
          const cachedUpdatedAt = new Date(cachedData.lastModified);
          const cachedUpdatedTime = new Date(cachedData.cacheTime);

          const diffInMinutes =
            (Date.now() - cachedData.cacheTime) / (1000 * 60);

          if (cachedUpdatedAt <= cachedUpdatedTime && diffInMinutes < 144000) {
            return {
              nickname: userProfile.nickname,
              profileImageURL: cachedData.url,
            };
          }
        }

        if (profileImageUrl) {
          const profileImageURL = `https://d1s9xshr26t6r2.cloudfront.net/public/${profileImageUrl}`;
          // ここでS3オブジェクトの情報を取得せずに、キャッシュの時間を現在の時間として設定
          const s3LastModified = new Date().toISOString(); // 現在の時間を使用
          setCachedImageUrl(profileImageUrl, profileImageURL, s3LastModified);
          return { nickname: userProfile.nickname, profileImageURL };
        } else {
          return { nickname: userProfile.nickname, profileImageURL: "" };
        }
      } else {
        return { nickname: "", profileImageURL: "" };
      }
    } catch (err) {
      console.error("Error fetching partner profile image:", err);
      return { nickname: "", profileImageURL: "" };
    }
  };

  const fetchLists = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const owner = await user.attributes.sub;
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const userData = await API.graphql({
        query: userProfilesByOwner,
        variables: { owner: owner, limit: 1 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const profile = await userData.data.userProfilesByOwner.items[0].id;

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/myMessagesList?profileid=${profile}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching messages list: ${response.status}`);
      }

      const messagesLists = await response.json();

      // APIから取得したブロックリストをフィルタリング
      const apiBlockedLists = messagesLists.filter(
        (message) => message.blockedBy && message.blockedBy.includes(profileid)
      );

      // 修正箇所: フィルタリングされたメッセージリストからブロックされたユーザーを除外
      const filteredMessagesLists = messagesLists.filter((message) => {
        return !(
          (message.deletedBy && message.deletedBy.includes(profileid)) ||
          apiBlockedLists.some((blocked) => blocked.id === message.id)
        );
      });

      const getLatestMessageFromLocalStorage = (messages) => {
        const validMessages = messages.filter(
          (message) =>
            !(message.hiddenFrom && message.hiddenFrom.includes(profileid)) &&
            !message.deletedAt
        );

        if (validMessages.length === 0) {
          return { content: "", createdAt: "" };
        }

        const latestMessage = validMessages.reduce((latest, current) => {
          return new Date(latest.createdAt) > new Date(current.createdAt)
            ? latest
            : current;
        });

        return {
          content: latestMessage.content,
          createdAt: latestMessage.createdAt,
        };
      };

      const messagesWithNicknamesAndImages = await Promise.all(
        filteredMessagesLists.map(async (message) => {
          const userProfileId =
            message.messageFrom === profileid
              ? message.messageTo
              : message.messageFrom;
          const { nickname, profileImageURL } = await fetchProfileImage(
            userProfileId
          );

          const storedMessages =
            JSON.parse(localStorage.getItem(`messages_${message.id}`)) || [];
          const {
            content: latestMessageContent,
            createdAt: latestMessageCreatedAt,
          } = getLatestMessageFromLocalStorage(storedMessages);

          const checkedAt = localStorage.getItem(`checkedAt_${message.id}`);
          const updatedAt = new Date(message.updatedAt);

          const CheckedStatus = message.checkedStatus
            ? (() => {
                let foundMessage = null;
                for (let i = 0; i < message.checkedStatus.length; i++) {
                  if (message.checkedStatus[i].profileid === profileid) {
                    foundMessage = message.checkedStatus[i];
                    break;
                  }
                }
                return foundMessage;
              })()
            : null;

          const latestCheckedAt = CheckedStatus
            ? CheckedStatus.checkedAt
            : null;

          // localStorageのcheckedAtが存在しない場合、最新のチェック日を設定
          if (!checkedAt && latestCheckedAt) {
            localStorage.setItem(`checkedAt_${message.id}`, latestCheckedAt);
          }

          // 最新のチェック日を取得
          /*    const latestMessage = message.latestMessage
            ? message.latestMessage.find((item) => item.profileid === profileid)
            : null;
  
          const latestCheckedAt = latestMessage
            ? latestMessage.checkedAt
            : null;
  
          // localStorageのcheckedAtが存在しない場合、最新のチェック日を設定
          if (!checkedAt && latestCheckedAt) {
            localStorage.setItem(`checkedAt_${message.id}`, latestCheckedAt);
          } */

          // displayContentの判定を後ろに移動
          const newCheckedAt = localStorage.getItem(`checkedAt_${message.id}`);
          const displayContent =
            !newCheckedAt || updatedAt > new Date(newCheckedAt)
              ? "更新がありました!"
              : typeof latestMessageContent === "string"
              ? latestMessageContent
              : latestMessageContent?.content || "";

          return {
            ...message,
            nickname,
            profileImageURL,
            latestMessage: displayContent,
            latestMessageCreatedAt:
              displayContent === "更新がありました!" || !displayContent
                ? message.updatedAt
                : latestMessageCreatedAt,
          };
        })
      );

      const blockedListsWithNicknamesAndImages = await Promise.all(
        apiBlockedLists.map(async (message) => {
          const userProfileId =
            message.messageFrom === profileid
              ? message.messageTo
              : message.messageFrom;
          const { nickname, profileImageURL } = await fetchProfileImage(
            userProfileId
          );
          return { ...message, nickname, profileImageURL };
        })
      );

      const sortedMessagesWithNicknamesAndImages =
        messagesWithNicknamesAndImages.sort(
          (a, b) =>
            new Date(b.latestMessageCreatedAt) -
            new Date(a.latestMessageCreatedAt)
        );

      const sortedBlockedListsWithNicknamesAndImages =
        blockedListsWithNicknamesAndImages.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

      setLists(sortedMessagesWithNicknamesAndImages);
      setBlockedLists(sortedBlockedListsWithNicknamesAndImages);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const blockMessageList = async (listId, profileid) => {
    setIsLoading(true); // 追加

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/blockMessagesList",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            messagesListId: listId,
            profileId: profileid,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // ブロックが成功した場合、対象のリストを現在のlistsステートから削除
      setLists((prevLists) => prevLists.filter((list) => list.id !== listId));
      setBlockedLists((prevBlockedLists) => [
        ...prevBlockedLists,
        lists.find((list) => list.id === listId),
      ]);
      // メッセージリストを再取得
      await fetchLists();
    } catch (err) {
      console.error("error");
    } finally {
      setIsLoading(false); // 追加
    }
  };

  const unblockMessageList = async (listId, profileid) => {
    setIsLoading(true); // 追加
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/unblockMessagesList",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            messagesListId: listId,
            profileId: profileid,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // ブロック解除が成功した場合、対象のリストをblockedListsから削除してlistsに追加
      const unblockedList = blockedLists.find((list) => list.id === listId);
      setBlockedLists((prevBlockedLists) =>
        prevBlockedLists.filter((list) => list.id !== listId)
      );
      setLists((prevLists) => [unblockedList, ...prevLists]);
    } catch (err) {
      console.error("error");
    } finally {
      setIsLoading(false); // 追加
    }
  };

  const hideMessageList = async (listId, profileid) => {
    setIsLoading(true); // 追加
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/hiddenMessagesList",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            messagesListId: listId,
            profileId: profileid,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("API Gateway responded with status " + response.status);
      }

      // localStorageから該当リストのデータを削除
      localStorage.removeItem(`messages_${listId}`);
      localStorage.removeItem(`checkedAt_${listId}`);

      // メッセージリストを再取得
      await fetchLists();
    } catch (err) {
      console.error("error");
    } finally {
      setIsLoading(false); // 追加
    }
  };

  const showConfirmModal = (action, message, listId, profileId) => {
    setConfirmAction(() => () => action(listId, profileId));
    setConfirmMessage(message);
    setSelectedListId(listId);
    setSelectedProfileId(profileId);
    setIsConfirmModalOpen(true);
  };

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    setIsConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
    setConfirmAction(null);
    setConfirmMessage("");
    setSelectedListId(null);
    setSelectedProfileId(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1; // 月は0から始まるため、1を足す
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${month}月${day}日 ${hours}時${minutes}分`;
  };

  return (
    <div className={styles.container}>
      <div className={styles["header-container"]}>
        <h2 className={styles["header-title"]}>ダイレクトメッセージ一覧</h2>
        <button onClick={toggleModal} className={styles["block-list-button"]}>
          ブロックリスト
        </button>
      </div>

      {isLoading ? (
        <div className={styles["spinner-container"]}>
          <div className={styles.spinner}></div>
        </div>
      ) : lists.length === 0 ? (
        <p>まだメッセージはありません。</p>
      ) : (
        <ul className={styles["message-list"]}>
          {lists.map((list) => (
            <li key={list.id}>
              <div className={styles["message-item"]}>
                <Link
                  to={{
                    pathname: `/messages/${list.id}`,
                    state: {
                      messageFrom: list.messageFrom,
                      messageTo: list.messageTo,
                    },
                  }}
                >
                  <img
                    src={
                      list.profileImageURL ===
                      "https://d1s9xshr26t6r2.cloudfront.net/public/profileImages/default_images/user_placeholder.png"
                        ? require("../assets/user_placeholder.png")
                        : list.profileImageURL
                    }
                    alt={`${list.nickname}のプロフィール画像`}
                    className={styles["profile-image"]}
                  />
                </Link>
                <div className={styles["message-content"]}>
                  <div className={styles["message-details"]}>
                    <Link
                      to={{
                        pathname: `/messages/${list.id}`,
                        state: {
                          messageFrom: list.messageFrom,
                          messageTo: list.messageTo,
                        },
                      }}
                    >
                      <div className={styles["message-header"]}>
                        <h3>{list.nickname}</h3>
                      </div>
                    </Link>
                    {list.latestMessage && list.latestMessage !== "" ? (
                      list.latestMessage === "更新がありました!" ? (
                        <p style={{ fontWeight: "bold", color: "red" }}>
                          {truncateMessage(list.latestMessage, 50)}
                        </p>
                      ) : (
                        <p>{truncateMessage(list.latestMessage, 50)}</p>
                      )
                    ) : (
                      <p>メッセージを表示できません</p>
                    )}
                  </div>
                  <div className={styles["message-buttons"]}>
                    <p>
                      {formatDate(
                        list.latestMessageCreatedAt || list.updatedAt
                      )}
                    </p>
                    <button
                      className={styles["hide-button"]}
                      onClick={() =>
                        showConfirmModal(
                          hideMessageList,
                          "一覧から削除しますか？",
                          list.id,
                          profileid
                        )
                      }
                    >
                      一覧から削除
                    </button>
                    <button
                      className={styles["block-button"]}
                      onClick={() =>
                        showConfirmModal(
                          blockMessageList,
                          "ブロックしますか？",
                          list.id,
                          profileid
                        )
                      }
                    >
                      ブロック
                    </button>
                    <Modal
                      isOpen={isConfirmModalOpen}
                      onRequestClose={handleCancel}
                      style={confirmModalStyles}
                    >
                      <div className={styles["messagelistmodal-header"]}>
                        <h2>{confirmMessage}</h2>
                      </div>
                      <div
                        className={styles["messagesListModalButtonContainer"]}
                      >
                        <button onClick={handleConfirm}>はい</button>
                        <button onClick={handleCancel}>いいえ</button>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        className={styles.messagelistmodal}
      >
        <div className={styles["messagelistmodal-header"]}>
          <h2>ブロックリスト</h2>
          <button
            onClick={toggleModal}
            className={styles["close-modal-button"]}
          >
            閉じる
          </button>
        </div>
        {blockedLists.length === 0 ? (
          <p>ブロックしているユーザーはいません</p>
        ) : (
          <ul>
            {blockedLists.map((blockedList) => (
              <li key={blockedList.id}>
                <p>{blockedList.nickname}</p>
                <button
                  className={styles["unblock-list-button"]}
                  onClick={() => unblockMessageList(blockedList.id, profileid)}
                  disabled={isLoading}
                >
                  ブロック解除
                </button>
              </li>
            ))}
          </ul>
        )}
      </Modal>
    </div>
  );
}

export default MessagesList;
