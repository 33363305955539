import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import "../styles/ChangeEmail.css";

const ChangeEmail = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [stage, setStage] = useState(1); // ステージ1: Eメール変更、ステージ2: 確認コード入力
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canResendCode, setCanResendCode] = useState(true);
  const [countdown, setCountdown] = useState(0);
  const emailRegex =
    /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  useEffect(() => {
    fetchCurrentEmail();
    const savedCountdown = localStorage.getItem("resendCountdown");
    if (savedCountdown) {
      const remainingTime = parseInt(savedCountdown) - Date.now();
      if (remainingTime > 0) {
        setCountdown(remainingTime);
        setCanResendCode(false);
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1000) {
              clearInterval(interval);
              localStorage.removeItem("resendCountdown");
              setCanResendCode(true);
              return 0;
            }
            return prevCountdown - 1000;
          });
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, []);

  const fetchCurrentEmail = async () => {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setCurrentEmail(user.attributes.email);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const handleChangeEmail = async () => {
    if (!isSubmitting && canResendCode) {
      // 日本語を含まないかをチェック
      const japaneseRegex =
        /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]/;
      if (japaneseRegex.test(email)) {
        setError("メールアドレスに日本語を含めないでください。");
        return;
      }

      // 有効なメールアドレスかをチェック
      if (!emailRegex.test(email)) {
        setError("有効なメールアドレスを入力してください。");
        return;
      }

      setIsSubmitting(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { email: email });
        setStage(2);
        setError("");
        setCanResendCode(false);
        const newCountdown = 600000; // 600秒
        setCountdown(newCountdown);
        localStorage.setItem("resendCountdown", Date.now() + newCountdown);
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1000) {
              clearInterval(interval);
              localStorage.removeItem("resendCountdown");
              setCanResendCode(true);
              return 0;
            }
            return prevCountdown - 1000;
          });
        }, 1000);
      } catch (e) {
        setError(e.message);
        setSuccess(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleVerifyEmail = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", code);
        setSuccess(true);
        setError("");
        await fetchCurrentEmail();
        setStage(1);
        setEmail("");
        alert("メールアドレスが更新されました！");
      } catch (e) {
        setError(e.message);
        setSuccess(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div>
      <h2>Eメール変更</h2>
      {loading ? (
        <div style={{ textAlign: "center" }}>Loading...</div>
      ) : (
        <>
          {success && (
            <h3 class="change-email-success-text">
              メールアドレスが更新されました！
            </h3>
          )}

          <p>現在のEメールアドレス: {currentEmail}</p>

          {stage === 1 && (
            <>
              <h4 class="emailwarning-message">
                確認コードの再送信には時間がかかるため、
                <br />
                確認コードが届くまで入力画面から遷移せずにお待ちください
              </h4>

              <input
                className="emailchange-input"
                type="email"
                value={email}
                onChange={(e) => {
                  const value = e.target.value;
                  const filteredValue = value.replace(/[^\x20-\x7E]/g, "");

                  setEmail(filteredValue);
                  if (!emailRegex.test(filteredValue)) {
                    setError("有効なメールアドレスを入力してください。");
                  } else {
                    setError("");
                  }
                }}
                placeholder="新しいEメールアドレス"
                maxLength="50"
              />
              <button
                className="confirmation-button"
                onClick={handleChangeEmail}
                disabled={
                  isSubmitting || !canResendCode || !emailRegex.test(email)
                }
              >
                {canResendCode
                  ? "確認コードを送信"
                  : `再送信まで${Math.ceil(countdown / 1000)}秒`}
              </button>
              <p className="error-message">{error}</p>
            </>
          )}
          {stage === 2 && (
            <>
              <h4>
                確認コードの再送信には時間がかかるため、
                <br />
                確認コードが届くまで入力画面から遷移せずにお待ちください
              </h4>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="確認コード"
              />
              <button
                className="confirmation-button"
                onClick={handleVerifyEmail}
                disabled={isSubmitting}
              >
                メールアドレスを変更
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ChangeEmail;
