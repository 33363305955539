import React, { useState, useEffect } from "react";
import { AccountSettings, Card, ThemeProvider } from "@aws-amplify/ui-react";

// カスタムテーマの設定。必要に応じて調整してください。
const theme = {
  name: "custom-theme",
  tokens: {
    colors: {
      border: {
        primary: { value: "{colors.neutral.100}" },
        secondary: { value: "{colors.neutral.150}" },
        tertiary: { value: "{colors.neutral.200}" },
      },
    },
    radii: {
      small: { value: "2px" },
      medium: { value: "3px" },
      large: { value: "4px" },
      xl: { value: "6px" },
    },
  },
};

const ChangePassword = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    const lastSubmitTime = localStorage.getItem("lastSubmitTime");
    if (lastSubmitTime) {
      const elapsedTime = (Date.now() - parseInt(lastSubmitTime, 10)) / 1000;
      if (elapsedTime < 300) {
        setIsDisabled(true);
        setCountdown(300 - elapsedTime);
      }
    }
  }, []);

  useEffect(() => {
    if (isDisabled) {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isDisabled]);

  const handleSuccess = () => {
    alert("パスワードが正常に変更されました！");
    localStorage.setItem("lastSubmitTime", Date.now().toString());
    setCountdown(300);
    window.location.href = "/mypage";
  };

  const handleError = () => {
    return;
  };

  const passwordValidator = {
    validationMode: "onChange",
    validator: (password) => {
      if (isDisabled) {
        return false; // isDisabledがtrueなら常にfalseを返す
      }

      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const lengthValid = password.length >= 8;

      return hasUpperCase && hasLowerCase && hasNumber && lengthValid;
    },
    message: isDisabled
      ? `再試行まで${Math.ceil(countdown)}秒お待ちください。`
      : "パスワードは8文字以上で、大文字、小文字、数字を各1文字以上含めてください",
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ThemeProvider theme={theme}>
        <Card width="355px">
          <h3>パスワード変更</h3>
          <p>
            パスワードは8文字以上で、大文字、小文字、数字を各1文字以上含めてください
          </p>
          {isDisabled && (
            <p style={{ color: "red" }}>
              再試行まで{Math.ceil(countdown)}秒お待ちください。
            </p>
          )}
          <AccountSettings.ChangePassword
            onSuccess={handleSuccess}
            onError={handleError}
            validators={[passwordValidator]}
          />
        </Card>
      </ThemeProvider>
    </div>
  );
};

function App() {
  return <ChangePassword />;
}

export default App;
