import React, { useEffect, useState } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import { categories } from "../containers/CategoryList";
import { ToastContainer, toast } from "react-toastify"; // トースト通知用のimportを追加
import "react-toastify/dist/ReactToastify.css";
import "../styles/PostManage.css";
import { excludeOwnerListPosts } from "../graphql/queries";
import { updatePost } from "../graphql/mutations";
import { useSelector } from "react-redux";

// カテゴリーのパスから日本語の名前を取得する関数
const getCategoryName = (path) => {
  const category = categories.find((c) => c.path === path);
  return category ? category.name : "未分類";
};

function PostManage() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true); // ローディング状態を追加
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // 追加
  const itemsPerPage = 10;
  const profileid = useSelector((state) => state.userProfile.profileid); // profileidをReduxのstoreから取得
  // ページ番号のリンクを表示するためのロジック
  const totalPageNumbers = Math.ceil(posts.length / itemsPerPage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);

  const openModal = (postId) => {
    setPostToDelete(postId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPostToDelete(null);
    setIsModalOpen(false);
  };

  const getJapaneseCity = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, {
            filter: {
              userProfileId: {
                eq: profileid,
              },
            },
          })
        );
        let filteredPosts = postData.data.listPosts.items.filter(
          (post) => !post.deletedAt
        ); // フィルタリングを追加

        // updatedAtの新しい順に並び替え
        filteredPosts = filteredPosts.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setPosts(filteredPosts);
      } catch (err) {
        console.error("エラーが発生しました:", err);
      } finally {
        setLoading(false); // フェッチが完了したらローディングを終了
      }
    };

    fetchPosts();
  }, [profileid]);

  const handleRepost = async (postId) => {
    if (window.confirm("再投稿しますか？")) {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
        // 投稿ステータスをACTIVEに更新するAPI呼び出し

        const response = await fetch(
          `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/posts`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
            body: JSON.stringify({
              id: postId,
              postingStatus: "ACTIVE",
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // ブックマークのカウントを更新する処理を追加する
        } else {
          console.error("Error updating favorites count");
        }

        // 成功のトースト通知
        toast.success("再投稿しました");

        // 投稿リストの更新
        const updatedPosts = posts.map((post) => {
          if (post.id === postId) {
            return { ...post, postingStatus: "ACTIVE" };
          }
          return post;
        });
        setPosts(updatedPosts);
      } catch (err) {
        console.error("エラーが発生しました:", err);
        // 失敗のトースト通知
        toast.error("再投稿ができませんでした");
      }
    }
  };

  const handleDelete = async () => {
    try {
      closeModal();
      const currentUser = await Auth.currentAuthenticatedUser();
      const userId = currentUser.attributes.sub;

      const input = {
        id: postToDelete,
        owner: userId,
        deletedAt: new Date().toISOString(),
        postingStatus: "INACTIVE", // ここで投稿ステータスをINACTIVEに設定する
      };

      const response = await API.graphql({
        query: updatePost,
        variables: { input: input },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (response.data.updatePost) {
        // 成功のトースト通知する
        toast.success("削除しました");

        // 投稿リストの更新する
        const updatedPosts = posts.filter((post) => post.id !== postToDelete);
        setPosts(updatedPosts);
      } else {
        console.error("Error updating deletedAt");
        // 失敗のトースト通知
        toast.error("削除ができませんでした");
      }
    } catch (err) {
      console.error("エラーが発生しました:", err);
      // 失敗のトースト通知
      toast.error("削除ができませんでした");
    } finally {
      closeModal();
    }
  };

  // 現在のページに基づいて表示する項目を計算
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // ページ番号のリンクを表示するためのロジック
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPageNumbers) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div
      className="postManageContainer"
      style={{ maxWidth: `${windowWidth}px` }}
    >
      <h2>投稿管理</h2>
      <ToastContainer />
      {loading ? (
        <p>Loading...</p> // ローディング中のメッセージ
      ) : posts.length === 0 ? (
        <div>投稿がありません</div>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>投稿日</th>
                <th>投稿ID</th>
                <th className="title-column">タイトル</th>
                <th>カテゴリー</th>
                <th>地域</th>
                <th>編集</th>
                <th>オプション</th>
                <th>再投稿</th>
                <th>投稿ステータス</th>
                <th className="number-column">閲覧数</th>
                <th className="number-column">ブックマーク数</th>
                <th className="number-column">コメント数</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((post) => (
                <tr key={post.id}>
                  <td>{new Date(post.createdAt).toLocaleDateString()}</td>
                  <td>{post.id.slice(-12)}</td>
                  <td className="title-column">
                    <Link
                      to={`/contents/${post.id}`}
                      className="postManageTitle"
                    >
                      {post.title.length > 20
                        ? `${post.title.slice(0, 20)}...`
                        : post.title}
                    </Link>
                  </td>
                  <td>{getCategoryName(post.category)}</td>
                  <td>
                    {post.postProvince
                      ? japanProvinceMap[post.postProvince]
                      : "N/A"}{" "}
                    {getJapaneseCity(post.postProvince, post.postCity)}
                  </td>
                  <td>
                    <Link to={`/postedit/${post.id}`}>編集</Link>
                  </td>
                  <td>
                    {post.options && post.options.length > 0
                      ? post.options.join(", ")
                      : "None"}
                  </td>
                  <td>
                    {post.postingStatus === "ACTIVE" ? (
                      "投稿中"
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleRepost(post.id);
                        }}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          handleRepost(post.id);
                        }}
                        style={{
                          background: "none",
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          padding: 0,
                          font: "inherit",
                        }}
                      >
                        再投稿
                      </button>
                    )}
                  </td>
                  <td>{post.postingStatus}</td>
                  <td className="number-column">{post.postingViews}</td>
                  <td className="number-column">{post.favoritesCount}</td>
                  <td className="number-column">{post.commentsCount}</td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        openModal(post.id);
                      }}
                      style={{
                        background: "none",
                        color: "red",
                        textDecoration: "underline",
                        cursor: "pointer",
                        border: "none",
                        padding: 0,
                        font: "inherit",
                      }}
                    >
                      削除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {isModalOpen && (
              <div className="postModal">
                <div className="post-modal-content">
                  <p>本当に削除しますか？</p>
                  <button onClick={handleDelete}>はい</button>
                  <button onClick={closeModal}>いいえ</button>
                </div>
              </div>
            )}
          </table>
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ◁
            </button>
            <span>
              {currentPage}/{totalPageNumbers}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPageNumbers}
            >
              ▷
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default PostManage;
