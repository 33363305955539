import { useEffect, useRef } from "react";

function useDebounceReload(delay) {
  const lastReloadTimeRef = useRef(Date.now());
  const warningMessage = "";

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const currentTime = Date.now();
      if (currentTime - lastReloadTimeRef.current < delay) {
        event.preventDefault();
        event.returnValue = ""; // メッセージは表示されないが、デフォルトの警告が表示される
        return warningMessage; // 実際にはこのメッセージは使用されない
      }
      lastReloadTimeRef.current = currentTime;
    };

    const handleUnload = () => {
      const currentTime = Date.now();
      if (currentTime - lastReloadTimeRef.current < delay) {
        lastReloadTimeRef.current = currentTime + delay; // 次のリロードをさらに遅らせる
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [delay]);

  return null;
}

export default useDebounceReload;
