import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, graphqlOperation, Auth } from "aws-amplify";
import Modal from "react-modal";
import { categories } from "../containers/CategoryList";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import Chat from "./Chat";
import EmailContactForm from "./EmailContactForm"; // EmailContactFormをインポートする
import {
  excludeOwnerListPosts,
  getPublicProfilesByUserProfileId,
  listFavoritesWithPost,
  excludeOwnerPosts,
} from "../graphql/queries";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Contents.css";

Modal.setAppElement("#root");

function Contents() {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 825;
  const profileid = useSelector((state) => state.userProfile.profileid); // profileidをReduxのstoreから取得
  const { id } = useParams(); // URLパラメータからIDを取得
  const location = useLocation();
  const { listing: stateListing, userProfileId: stateUserProfileId } =
    location.state || {};
  // listingの初期値をlocation.stateから取得した値に設定
  const [listing, setListing] = useState(stateListing || null);
  const [userProfileId, setUserProfileId] = useState(
    stateUserProfileId || null
  );
  const [currentUser, setCurrentUser] = useState(null);
  const [isFavorited, setIsFavorited] = useState(false);
  const [favoriteId, setFavoriteId] = useState(null);
  const [isCheckingFavorite, setIsCheckingFavorite] = useState(true); // ブックマーク情報の取得中かどうかを示すフラグ
  const [isProcessing, setIsProcessing] = useState(false);
  const [postImages, setPostImages] = useState([]);
  const [ownerNickname, setOwnerNickname] = useState("");
  const [relatedContents, setRelatedContents] = useState([]); //のちにリコメンド機能実装時に使う
  const [ownerProfileImageCache, setOwnerProfileImageCache] = useState({});
  const isOwnPost = listing && listing.userProfileId === profileid;
  const [isLoading, setIsLoading] = useState(true);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [ownerProfileImageUrl, setOwnerProfileImageUrl] = useState("");
  const [isOwnerProfileImageLoading, setIsOwnerProfileImageLoading] =
    useState(true);

  useEffect(() => {
    const fetchBookmarkCount = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub;
        const favoritesData = await API.graphql(
          graphqlOperation(listFavoritesWithPost, {
            filter: {
              owner: {
                eq: userId,
              },
            },
          })
        );
        const favoritesItems = favoritesData.data.listFavorites.items;
        setBookmarkCount(favoritesItems.length);
      } catch (err) {
        console.error("Error fetching bookmark count:", err);
      }
    };

    if (currentUser) {
      fetchBookmarkCount();
    }
  }, [currentUser]);

  useEffect(() => {
    setIsLoading(true);
    const initialize = async () => {
      try {
        // 認証済みユーザーを取得
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
        updateViewCount();

        // リスティングを取得 (location.stateからlistingが取得できなかった場合)
        if (!stateListing && id) {
          const postData = await API.graphql(
            graphqlOperation(excludeOwnerPosts, { id })
          );
          setListing(postData.data.getPost);
        } else if (!stateListing && userProfileId) {
          // MyFavoriteからuserProfileIdが渡された場合
          const postData = await API.graphql(
            graphqlOperation(excludeOwnerPosts, { userProfileId })
          );
          setListing(postData.data.getPost);
        }
      } catch (err) {
        if (err !== "The user is not authenticated") {
          console.error("Error:", err);
        }
      }
    };

    initialize();
  }, [id, stateListing, userProfileId]);

  useEffect(() => {
    setIsLoading(true);
    const fetchListing = async () => {
      try {
        const postData = await API.graphql(
          graphqlOperation(excludeOwnerPosts, { id })
        );
        setListing(postData.data.getPost);
      } catch (err) {
        console.error("Error fetching listing:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListing();
  }, [id]);

  useEffect(() => {
    const fetchRelatedContentsAndCheckFavorites = async () => {
      if (listing && currentUser) {
        try {
          // 関連コンテンツを取得
          const postData = await API.graphql(
            graphqlOperation(excludeOwnerListPosts, {
              filter: {
                category: {
                  eq: listing.category,
                },
              },
              limit: 4,
            })
          );
          setRelatedContents(postData.data.listPosts.items);

          // ブックマークの状態をチェック
          await checkFavoriteStatus();
        } catch (err) {
          console.error("Error:", err);
        }
      }
    };

    fetchRelatedContentsAndCheckFavorites();
  }, [listing, currentUser]);

  // 既存のuseEffect内にヘルパー関数を追加
  useEffect(() => {
    const fetchPostImages = async () => {
      if (listing) {
        const imageUrls = [
          listing.postImageUrl1,
          /* listing.postImageUrl2,
          listing.postImageUrl3, */
        ].filter(Boolean);

        const fetchedImages = imageUrls.map((url) => {
          // news_sample.pngまたはnullの場合の処理
          if (url === "../assets/news_sample.png" || !url) {
            return require("../assets/news_sample.png");
          }

          // キャッシュキーを定義
          const cacheKey = url;

          // キャッシュから画像を取得
          const cachedImage = getCachedImageUrl(cacheKey);

          if (cachedImage) {
            const cacheUpdatedAt = new Date(cachedImage.updatedAt).getTime();
            const listingUpdatedAt = new Date(listing.updatedAt).getTime();
            const differenceInMinutes =
              (listingUpdatedAt - cacheUpdatedAt) / 60000;

            // キャッシュが有効期限内の場合はキャッシュを使用
            if (differenceInMinutes <= 2) {
              return cachedImage.url;
            }
          }

          // キャッシュが存在しないか有効期限切れの場合はCloudFrontから取得
          const response = `https://d1s9xshr26t6r2.cloudfront.net/public/${url}`;

          // キャッシュに保存
          setCachedImageUrl(cacheKey, response, null, listing.updatedAt);
          return response;
        });

        setPostImages(fetchedImages);
      }
    };

    fetchPostImages();
  }, [listing]);

  // キャッシュの設定関数
  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(),
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  // キャッシュの取得関数
  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);

        // キャッシュの有効期限を144000分（2400時間）に設定
        if (diffInMinutes > 144000) {
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key);
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key);
      return null;
    }
  };

  useEffect(() => {
    const fetchOwnerNickname = async () => {
      try {
        if (listing && listing.userProfileId) {
          const cacheKey = `nickname_${listing.userProfileId}`;
          const cachedNickname = getNicknameFromCache(cacheKey);

          if (cachedNickname) {
            const cacheUpdatedAt = new Date(cachedNickname.updatedAt).getTime();
            const listingUpdatedAt = new Date(listing.updatedAt).getTime();
            const differenceInMinutes =
              (listingUpdatedAt - cacheUpdatedAt) / 60000;

            setOwnerNickname(cachedNickname.nickname);

            // キャッシュが有効期限内ならここで関数を終了
            if (differenceInMinutes <= 2) {
              return;
            }
          }

          // キャッシュがなかった場合や有効期限切れの場合に新しいニックネームを取得
          const userProfileData = await API.graphql({
            query: getPublicProfilesByUserProfileId,
            variables: {
              userProfileId: listing.userProfileId,
              limit: 1,
            },
          });

          const userProfile =
            userProfileData.data.publicProfilesByUserProfileId.items[0];
          if (userProfile) {
            const nickname = userProfile.nickname || "";
            setOwnerNickname(nickname);
            saveNicknameToCache(cacheKey, nickname, userProfile.updatedAt);
          } else {
            setOwnerNickname(""); // ユーザープロフィールが見つからない場合は空文字列を設定
          }
        }
      } catch (err) {
        console.error("Error fetching owner nickname:", err);
      }
    };

    fetchOwnerNickname();
  }, [listing]);

  useEffect(() => {
    fetchOwnerProfileImage();
  }, [listing, ownerProfileImageCache]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const saveImageToCache = (key, imageUrl, updatedAt) => {
    const now = new Date().getTime();
    const cacheEntry = {
      imageUrl,
      timestamp: now,
      updatedAt, // updatedAtを保存
    };
    localStorage.setItem(key, JSON.stringify(cacheEntry));
  };

  const getImageFromCache = (key) => {
    const cacheEntryStr = localStorage.getItem(key);
    if (!cacheEntryStr) return null;

    const cacheEntry = JSON.parse(cacheEntryStr);
    const now = new Date().getTime();

    // キャッシュの有効期限を144000分（864000000ミリ秒）に設定
    const cacheValidityDuration = 8640000000;

    if (now - cacheEntry.timestamp > cacheValidityDuration) {
      localStorage.removeItem(key);
      return null;
    }

    return cacheEntry;
  };

  const saveNicknameToCache = (key, nickname, updatedAt) => {
    const now = new Date().getTime();
    const cacheEntry = {
      nickname,
      timestamp: now,
      updatedAt,
    };
    localStorage.setItem(key, JSON.stringify(cacheEntry));
  };

  const getNicknameFromCache = (key) => {
    const cacheEntryStr = localStorage.getItem(key);
    if (!cacheEntryStr) return null;

    const cacheEntry = JSON.parse(cacheEntryStr);
    const now = new Date().getTime();

    // キャッシュの有効期限を24時間に設定（24時間 = 86400000ミリ秒）
    const cacheValidityDuration = 86400000;

    if (now - cacheEntry.timestamp > cacheValidityDuration) {
      localStorage.removeItem(key);
      return null;
    }

    return cacheEntry;
  };

  const fetchOwnerProfileImage = async () => {
    try {
      if (listing && listing.userProfileId) {
        const userProfileData = await API.graphql({
          query: getPublicProfilesByUserProfileId,
          variables: {
            userProfileId: listing.userProfileId,
            limit: 1,
          },
        });

        const userProfile =
          userProfileData.data.publicProfilesByUserProfileId.items[0];
        if (userProfile && userProfile.profileImageUrl) {
          const cacheKey = `profileImage_${userProfile.profileImageUrl}`;
          const cachedImage = getImageFromCache(cacheKey);

          // キャッシュが存在し、かつキャッシュが有効期限内の場合はそれを使用する
          if (cachedImage) {
            const cacheUpdatedAt = new Date(cachedImage.updatedAt).getTime();
            const listingUpdatedAt = new Date(listing.updatedAt).getTime();
            const differenceInMinutes =
              (listingUpdatedAt - cacheUpdatedAt) / 60000;

            if (differenceInMinutes <= 5) {
              setOwnerProfileImageUrl(cachedImage.imageUrl);
              setIsOwnerProfileImageLoading(false);
              return;
            }
          }

          // キャッシュが存在しないか有効期限切れの場合はCloudFrontから取得
          const profileImageUrl = `https://d1s9xshr26t6r2.cloudfront.net/public/${userProfile.profileImageUrl}`;

          // キャッシュに保存
          saveImageToCache(cacheKey, profileImageUrl, listing.updatedAt);

          setOwnerProfileImageUrl(profileImageUrl);
          setIsOwnerProfileImageLoading(false);
        } else {
          setOwnerProfileImageUrl("");
          setIsOwnerProfileImageLoading(false);
        }
      }
    } catch (err) {
      console.error("Error fetching owner profile image:", err);
      setIsOwnerProfileImageLoading(false);
    }
  };

  const renderPostDetails = (category, postDetails) => {
    if (!postDetails) return null;

    const getJapaneseWageStructure = (wageStructure) => {
      switch (wageStructure) {
        case "annual_income":
          return "年収";
        case "monthly_income":
          return "月給";
        case "daily_income":
          return "日給";
        case "hourly_income":
          return "時給";
        default:
          return wageStructure;
      }
    };

    const getJapaneseEmploymentType = (employmentType) => {
      switch (employmentType) {
        case "full_time":
          return "正社員";
        case "contract":
          return "契約社員";
        case "part_time":
          return "アルバイト";
        case "freelance":
          return "業務委託";
        case "executive":
          return "役員";
        case "other":
          return "その他";
        default:
          return employmentType;
      }
    };

    const getJapaneseSubCategory = (subCategory) => {
      const subCategoryMap = {
        sports: "スポーツ",
        fave: "推し活",
        gaming: "ゲーム",
        travel_buddies: "旅行仲間",
        festival: "祭り",
        music: "音楽",
        art: "アート",
        theater_show: "演劇/ショー",
        exhibition: "展示会",
        parenting: "育児",
        party: "パーティー",
        seminar: "セミナー",
        flea_market: "フリーマーケット",
        concert: "コンサート",
        other: "その他",
      };
      return subCategoryMap[subCategory] || "該当なし";
    };

    const getJapaneseRentRealEstateCategory = (category) => {
      switch (category) {
        case "rental_apartment":
          return "賃貸マンション";
        case "rental_house":
          return "賃貸戸建";
        case "rental_land":
          return "貸土地";
        default:
          return category;
      }
    };

    const getJapaneseSaleRealEstateCategory = (category) => {
      switch (category) {
        case "new_apartment":
          return "新築マンション";
        case "used_apartment":
          return "中古マンション";
        case "new_house":
          return "新築戸建";
        case "used_house":
          return "中古戸建";
        default:
          return category;
      }
    };

    const getJapaneseBbsSubCategory = (subCategory) => {
      const subCategoryMap = {
        local_news: "地元のニュース",
        local_shops: "地元のお店",
        local_gourmet: "地元グルメ",
        local_products: "地元の商品・サービス",
        chitchat: "雑談",
        live_commentary: "実況",
        sports: "スポーツ",
        work: "仕事",
        friends: "友人",
        romance: "恋愛",
        marriage_hunting: "婚活",
        marriage: "結婚",
        parenting: "育児",
        mom_friends: "ママ友",
        dad_friends: "パパ友",
        relationships: "人間関係",
        volunteer: "ボランティア",
        money_investment: "お金/投資",
        nursing: "介護",
        hospital: "病院",
        regional_revival: "地方創生",
        local_economy: "地元経済",
      };
      return subCategoryMap[subCategory] || "該当なし";
    };

    switch (category) {
      case "recruiting":
        return (
          <div>
            {/* <p>給与体系: {getJapaneseWageStructure(
                postDetails.jobPostDetails?.wageStructure
              )}</p> */}
            {/* <p>通貨: {postDetails.jobPostDetails?.payCurrency}</p> */}
            <p>
              {getJapaneseWageStructure(
                postDetails.jobPostDetails?.wageStructure
              )
                ? getJapaneseWageStructure(
                    postDetails.jobPostDetails?.wageStructure
                  )
                : "収入"}
              :{" "}
              {postDetails.jobPostDetails?.salary
                ? `${Number(
                    postDetails.jobPostDetails?.salary
                  ).toLocaleString()}円`
                : "記載なし"}
            </p>

            <p>勤務地: {postDetails.jobPostDetails?.workLocation}</p>
            <p>
              雇用形態:{" "}
              {getJapaneseEmploymentType(
                postDetails.jobPostDetails?.employmentType
              )}
            </p>
            <p>職種: {postDetails.jobPostDetails?.jobCategory}</p>
            <p>担当者: {postDetails.jobPostDetails?.contactPerson}</p>
            <p>担当者メール: {postDetails.jobPostDetails?.email}</p>
            <p>電話番号: {postDetails.jobPostDetails?.phone}</p>
            <p>ウェブサイト: {postDetails.jobPostDetails?.websiteURL}</p>
          </div>
        );

      case "joinus":
        return (
          <div>
            <p>活動頻度: {postDetails.memberRecruitmentDetails?.eventDate}</p>
            {/* <p>通貨: {postDetails.memberRecruitmentDetails?.payCurrency}</p> */}
            <p>
              サブカテゴリー:{" "}
              {getJapaneseSubCategory(
                postDetails.memberRecruitmentDetails?.subCategory
              )}
            </p>
            <p>
              募集年齢: {postDetails.memberRecruitmentDetails?.recruitmentAge}
            </p>
            <p>
              参加費:
              {postDetails.memberRecruitmentDetails?.participationFee
                ? `¥${Number(
                    postDetails.memberRecruitmentDetails?.participationFee
                  ).toLocaleString()}`
                : "記載なし"}
            </p>

            <p>担当者メール: {postDetails.memberRecruitmentDetails?.email}</p>
            <p>
              ウェブサイト: {postDetails.memberRecruitmentDetails?.websiteURL}
            </p>
          </div>
        );
      case "event":
        return (
          <div>
            <p>開始日: {postDetails.eventDetails?.startDate}</p>
            <p>終了日: {postDetails.eventDetails?.endDate}</p>
            <p>
              サブカテゴリー:{" "}
              {getJapaneseSubCategory(postDetails.eventDetails?.subCategory)}
            </p>
            {/* <p>通貨: {postDetails.eventDetails?.payCurrency}</p> */}
            <p>
              参加費:
              {postDetails.eventDetails?.participationFee
                ? `¥${Number(
                    postDetails.eventDetails?.participationFee
                  ).toLocaleString()}`
                : "記載なし"}
            </p>

            <p>イベント時間: {postDetails.eventDetails?.eventTime}</p>
            <p>担当者メール: {postDetails.eventDetails?.email}</p>
            <p>ウェブサイト: {postDetails.eventDetails?.websiteURL}</p>
          </div>
        );
      case "rentrealestate":
        return (
          <div>
            <p>
              物件カテゴリー:{" "}
              {getJapaneseRentRealEstateCategory(
                postDetails.rentRealEstatePostDetails?.rentRealEstateCategory
              )}
            </p>

            {/* <p>通貨: {postDetails.rentRealEstatePostDetails?.payCurrency}</p> */}
            <p>築年数: {postDetails.rentRealEstatePostDetails?.builtYear}年</p>
            <p>間取り: {postDetails.rentRealEstatePostDetails?.floorPlan}</p>
            <p>
              専有面積: {postDetails.rentRealEstatePostDetails?.exclusiveArea}m2
            </p>
            <p>
              賃料:
              {postDetails.rentRealEstatePostDetails?.rent === ""
                ? "記載なし"
                : `¥${Number(
                    postDetails.rentRealEstatePostDetails?.rent
                  ).toLocaleString()}`}
            </p>
            <p>
              敷金:
              {postDetails.rentRealEstatePostDetails?.deposit === ""
                ? "記載なし"
                : `¥${Number(
                    postDetails.rentRealEstatePostDetails?.deposit
                  ).toLocaleString()}`}
            </p>
            <p>
              礼金:
              {postDetails.rentRealEstatePostDetails?.keyMoney === ""
                ? "記載なし"
                : `¥${Number(
                    postDetails.rentRealEstatePostDetails?.keyMoney
                  ).toLocaleString()}`}
            </p>

            <p>入居日: {postDetails.rentRealEstatePostDetails?.moveInDate}</p>
            <p>賃貸地域: {postDetails.rentRealEstatePostDetails?.rentRegion}</p>
            <p>
              担当者: {postDetails.rentRealEstatePostDetails?.contactPerson}
            </p>
            <p>担当者メール: {postDetails.rentRealEstatePostDetails?.email}</p>
            <p>
              ウェブサイト: {postDetails.rentRealEstatePostDetails?.websiteURL}
            </p>
          </div>
        );
      case "salerealestate":
        return (
          <div>
            <p>
              物件カテゴリー:{" "}
              {getJapaneseSaleRealEstateCategory(
                postDetails.saleRealEstatePostDetails?.saleRealEstateCategory
              )}
            </p>

            {/* <p>通貨: {postDetails.saleRealEstatePostDetails?.payCurrency}</p> */}
            <p>
              販売価格:
              {postDetails.saleRealEstatePostDetails?.salePrice === ""
                ? "記載なし"
                : `¥${Number(
                    postDetails.saleRealEstatePostDetails?.salePrice
                  ).toLocaleString()}`}
            </p>

            <p>間取り: {postDetails.saleRealEstatePostDetails?.floorPlan}</p>
            <p>土地面積: {postDetails.saleRealEstatePostDetails?.landArea}m2</p>
            <p>
              建物面積: {postDetails.saleRealEstatePostDetails?.buildingArea}m2
            </p>
            <p>築年数: {postDetails.saleRealEstatePostDetails?.builtYear}年</p>
            <p>
              利用可能日: {postDetails.saleRealEstatePostDetails?.availableDate}
            </p>
            <p>販売地域: {postDetails.saleRealEstatePostDetails?.saleRegion}</p>
            <p>
              担当者: {postDetails.saleRealEstatePostDetails?.contactPerson}
            </p>
            <p>担当者メール: {postDetails.saleRealEstatePostDetails?.email}</p>
            <p>
              ウェブサイト: {postDetails.saleRealEstatePostDetails?.websiteURL}
            </p>
          </div>
        );

      case "bbs":
        return (
          <div>
            <p>
              サブカテゴリー:{" "}
              {getJapaneseBbsSubCategory(postDetails.bbsDetails?.subCategory)}
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  //閲覧数をインクリメントする
  const updateViewCount = async () => {
    if (listing) {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const lastViewed = sessionStorage.getItem(`viewed_${listing.id}`);
      const now = new Date().getTime();

      if (!lastViewed || now - lastViewed > 360000000) {
        // 100時間は同一投稿者によるインクリメントはない
        try {
          await fetch(
            "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/postingViews",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtToken}`,
                "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
              },
              body: JSON.stringify({
                postId: listing.id,
                lastViewedTimestamp: lastViewed,
              }),
            }
          );

          sessionStorage.setItem(`viewed_${listing.id}`, now);
        } catch (err) {
          console.error("Error updating view count:", err);
        }
      }
    }
  };

  const getJapaneseCategory = (category) => {
    const categoryObj = categories.find((c) => c.path === category);
    return categoryObj ? categoryObj.name : "";
  };

  const getJapaneseProvince = (region) => {
    return japanProvinceMap[region] || "";
  };

  const getJapaneseCity = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  const checkFavoriteStatus = async () => {
    if (!currentUser) {
      // currentUserがnullまたはundefinedの場合は、処理をスキップします。

      return;
    }

    try {
      const userId = currentUser.attributes.sub;
      const favoritesData = await API.graphql(
        graphqlOperation(listFavoritesWithPost, {
          filter: {
            postID: {
              eq: listing.id,
            },
            owner: {
              eq: userId,
            },
          },
        })
      );
      const favoritesItems = favoritesData.data.listFavorites.items;
      if (favoritesItems.length > 0) {
        setIsFavorited(true);
        setFavoriteId(favoritesItems[0].id);
      } else {
        setIsFavorited(false);
        setFavoriteId(null);
      }
    } catch (err) {
      console.error("Error checking favorite status:", err);
    } finally {
      setIsCheckingFavorite(false); // ブックマーク情報の取得が終わったらフラグをfalseに設定
    }
  };

  const updateFavoritesCount = async (value) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/favoritesCount`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            id: listing.id,
            favoritesCountIncrement: value,
          }),
        }
      );

      if (response.ok) {
        return;
      } else {
        console.error("Error updating favorites count");
      }
    } catch (err) {
      console.error("Error updating favorites count:", err);
    }
  };

  const handleFavorite = async () => {
    if (bookmarkCount >= 20) {
      toast.error("ブックマークの上限に達しました");
      return;
    }

    setIsProcessing(true); // 処理開始時にローディング状態をtrueに設定
    try {
      // UIを即時更新
      setIsFavorited(!isFavorited);

      if (isFavorited) {
        // 既にブックマーク登録されている場合、ブックマークを解除
        return;
      } else {
        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        // ブックマーク登録されていない場合、新しいブックマークを作成
        const response = await fetch(
          `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/favorites`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
            body: JSON.stringify({
              postID: listing.id,
              owner: currentUser.attributes.sub,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          const newFavoriteId = data.id; // Lambdaの返り値に合わせて変更
          setFavoriteId(newFavoriteId);
          updateFavoritesCount(1); // ブックマーク数を増やす
          await setIsFavorited(!isFavorited);
          toast.success("ブックマークを追加しました", {
            autoClose: 900,
          });
          setIsProcessing(false);
        } else {
          setIsProcessing(false);
          console.error("Error posting favorite");
        }
      }
    } catch (err) {
      console.error("Error handling favorite:", err);
      // API呼び出しに失敗した場合は状態を元に戻す
      setIsFavorited(!isFavorited);
      setIsProcessing(false);
    }
  };

  const sendMessageToOwner = async () => {
    setIsSendingMessage(true); // 処理開始時にローディング状態をtrueに設定
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/checkmessageslist?profileid=${profileid}&userProfileId=${listing.userProfileId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
        }
      );

      const messagesLists = await response.json();

      if (messagesLists.length === 0) {
        const response = await fetch(
          "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/messageslist",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
            body: JSON.stringify({
              messageFrom: profileid,
              messageTo: listing.userProfileId,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          await navigate(`/messages/${data.id}`);
        } else {
          console.error("Error posting messages list");
        }
      } else {
        navigate(`/messages/${messagesLists[0].id}`);
      }
      setIsSendingMessage(false); // 処理終了時にローディング状態をfalseに設定
    } catch (err) {
      console.error("Error sending message:", err);
      setIsSendingMessage(false); // 処理終了時にローディング状態をfalseに設定
    }
  };

  if (isLoading) {
    return <div className="spinner-container"></div>;
  }

  if (!listing || listing.postingStatus !== "ACTIVE") {
    return <h2>投稿が削除されました</h2>;
  }

  if (!listing) {
    return <h2>Error loading data</h2>;
  }

  return (
    <div className="content-container">
      {isSendingMessage && (
        <div className="contentOverlay">
          <div className="spinner"></div>
          <span>処理中です...</span>
        </div>
      )}

      <div className="content-section">
        <div className="content-header">
          <h3>{listing.title}</h3>
          <div className="listing-meta">
            <p>
              投稿日:{" "}
              {new Date(listing.createdAt).toLocaleString("ja-JP", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            {listing.updatedAt && (
              <p>
                更新日:{" "}
                {new Date(listing.updatedAt).toLocaleString("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
            )}
            <p>カテゴリー: {getJapaneseCategory(listing.category)}</p>
            <p>
              地域: {getJapaneseProvince(listing.postProvince)}{" "}
              {getJapaneseCity(listing.postProvince, listing.postCity)}
            </p>
          </div>
          <div className="content-images">
            {postImages.map((image, index) => (
              <img key={index} src={image} alt={`Post ${index + 1}`} />
            ))}
          </div>
        </div>
        <div className="content-body">
          <p>{listing.content}</p>
          <br />
          {renderPostDetails(listing.category, listing.postDetails)}
          <br />
          <p className="content-body-id">投稿ID: {listing.id.slice(-12)}</p>
        </div>
      </div>
      <div className="sidebar">
        <p>
          {isOwnerProfileImageLoading ? (
            <img
              src={require("../assets/user_placeholder.png")}
              alt=""
              className="owner-profile-image"
            />
          ) : (
            <img
              src={
                ownerProfileImageUrl ===
                "https://d1s9xshr26t6r2.cloudfront.net/public/profileImages/default_images/user_placeholder.png"
                  ? require("../assets/user_placeholder.png")
                  : ownerProfileImageUrl
              }
              alt=""
              className="owner-profile-image"
            />
          )}
          <Link to={`/userprofile/${listing.userProfileId}`}>
            <span className="owner-nickname">{ownerNickname}</span>
          </Link>
        </p>

        {!isOwnPost && currentUser && (
          <>
            {listing.allowEmailContacts &&
              /\S+@\S+\.\S+/.test(listing.emailForContact) && (
                <>
                  <button className="contact-button" onClick={openModal}>
                    投稿者にメールで問い合わせる
                  </button>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="お問い合わせフォーム"
                    className="email-contact-modal"
                    overlayClassName="email-contact-overlay"
                  >
                    <EmailContactForm
                      closeForm={closeModal}
                      title={listing.title}
                      postId={listing.id}
                      emailForContact={listing.emailForContact}
                    />
                  </Modal>
                </>
              )}
            <div className="button-container">
              <button
                className={`processing-button ${
                  isFavorited ? "favorited" : ""
                }`}
                onClick={handleFavorite}
                disabled={
                  isProcessing ||
                  isFavorited ||
                  isCheckingFavorite ||
                  bookmarkCount >= 20
                }
              >
                {bookmarkCount >= 20
                  ? "ブックマークの上限です"
                  : isFavorited
                  ? "ブックマーク済み"
                  : "投稿をブックマークする"}
                {isProcessing && <span>に処理中</span>}
              </button>

              {listing.allowDirectMessages && (
                <button
                  onClick={sendMessageToOwner}
                  disabled={isSendingMessage} // ここでボタンを無効化する
                >
                  DMを送る
                </button>
              )}
            </div>
          </>
        )}
        {!isOwnPost && !currentUser && (
          <p className="direct-warning-text">
            投稿者とコンタクトを取るにはログインしてください
          </p>
        )}
        <div className="chat-container">
          <Chat postId={listing.id} />
        </div>
        {isMobile && (
          <div className="view-chat-button">
            <button onClick={() => navigate(`/chatpage/${listing.id}`)}>
              チャットページで閲覧する
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contents;
