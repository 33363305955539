import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { listFavoritesForOwner, excludeOwnerPosts } from "../graphql/queries";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categories } from "../containers/CategoryList";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import "../styles/MyFavorites.css";

function MyFavorites() {
  const [favorites, setFavorites] = useState([]);
  const [selectedFavorites, setSelectedFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFavorites = favorites.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(favorites.length / itemsPerPage);

  useEffect(() => {
    setLoading(true);
    fetchFavorites();
  }, []);

  // 既存のコードの中でカテゴリと地域の日本語表示部分を修正する
  const getCategoryName = (path) => {
    const category = categories.find((cat) => cat.path === path);
    return category ? category.name : path;
  };

  const getJapaneseProvince = (region) => {
    return japanProvinceMap[region] || region;
  };

  const getJapaneseCity = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const fetchPostById = async (postId) => {
    try {
      const postData = await API.graphql(
        graphqlOperation(excludeOwnerPosts, { id: postId })
      );
      const post = postData.data.getPost;
      return post;
    } catch (err) {
      console.error("Error fetching post", err);
    }
  };

  const fetchFavorites = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const userId = currentUser.attributes.sub;
      const favoriteData = await API.graphql(
        graphqlOperation(listFavoritesForOwner, {
          owner: userId,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      );

      const favorites = favoriteData.data.listFavorites.items;

      const favoritesWithPosts = await Promise.all(
        favorites.map(async (favorite) => {
          const post = await fetchPostById(favorite.postID);
          if (post && post.postingStatus === "ACTIVE") {
            const imageUrl = await fetchImageUrl(
              post.postImageUrl1,
              new Date(post.updatedAt).getTime()
            );
            return { ...favorite, post, imageUrl };
          } else {
            return { ...favorite, post: null };
          }
        })
      );

      setFavorites(favoritesWithPosts);
    } catch (err) {
      console.error("Error fetching favorites", err);
    } finally {
      setLoading(false); // 追加
    }
  };

  // ヘルパー関数を定義
  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(),
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key);
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key);
      return null;
    }
  };

  const fetchImageUrl = async (imageKey, postUpdatedAt) => {
    if (!imageKey || imageKey === "../assets/news_sample.png") {
      return require("../assets/news_sample.png");
    }

    const cachedData = getCachedImageUrl(imageKey);

    if (cachedData) {
      const cachedUpdatedAt = new Date(cachedData.updatedAt);
      const postUpdatedAtDate = new Date(postUpdatedAt);
      const diffInMinutes = (postUpdatedAtDate - cachedUpdatedAt) / (1000 * 60);

      if (diffInMinutes < 2) {
        return cachedData.url;
      }
    }

    try {
      const signedURL = `https://d1s9xshr26t6r2.cloudfront.net/public/${imageKey}`;
      setCachedImageUrl(imageKey, signedURL, null, postUpdatedAt);
      return signedURL;
    } catch (err) {
      console.error("Error fetching image URL:", err);
      return null;
    }
  };

  const updateFavoritesCount = async (postId, value) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/favoritesCount`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            id: postId,
            favoritesCountIncrement: value,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update");
      }
    } catch (err) {
      console.error("Error updating :", err);
    }
  };

  const handleRemoveSelectedFavorites = async () => {
    setLoading(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

      for (const favoriteId of selectedFavorites) {
        const favorite = favorites.find((fav) => fav.id === favoriteId);

        const response = await fetch(
          `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/favorites`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
            body: JSON.stringify({ id: favoriteId }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        updateFavoritesCount(favorite.postID, -1); // ブックマークのカウントを1減らす
      }
      setSelectedFavorites([]);
      await fetchFavorites(); // ブックマークリストを再取得し、UIを更新
      await toast.success("ブックマークを削除しました", {
        autoClose: 900,
      });
      await setLoading(false);
    } catch (err) {
      console.error("Error removing favorites", err);
      toast.error("ブックマークを削除できませんでした"); // 失敗時のトースト通知する
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedFavorites((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <div className="myFavoriteContainer">
      <h3>ブックマーク管理</h3>
      <h4>ブックマークは20件まで登録できます</h4>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <button
            class="bookmarkdelete-button"
            onClick={handleRemoveSelectedFavorites}
            disabled={selectedFavorites.length === 0}
            style={{ marginBottom: "10px" }}
          >
            選択したブックマークを削除
          </button>
          {currentFavorites.length === 0 ? (
            <div>ブックマークはありません</div>
          ) : (
            currentFavorites.map((favorite) => (
              <div key={favorite.id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedFavorites.includes(favorite.id)}
                    onChange={() => handleCheckboxChange(favorite.id)}
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {favorite.imageUrl && (
                      <div style={{ marginRight: "10px" }}>
                        <img src={favorite.imageUrl} alt="Post Thumbnail" />
                      </div>
                    )}
                    <div
                      className="myFavoriteContents"
                      style={{ textAlign: "left" }}
                    >
                      {favorite.post ? (
                        <>
                          <h3>
                            <Link
                              to={{
                                pathname: `/contents/${favorite.post.id}`,
                                state: {
                                  listing: favorite.post,
                                  userProfileId: favorite.post.userProfileId,
                                },
                              }}
                            >
                              {favorite.post.title}
                            </Link>
                          </h3>
                          <div
                            style={{
                              fontSize: "0.8em",
                              color: "gray",
                              marginTop: "-5px",
                            }}
                          >
                            {getJapaneseProvince(favorite.post.postProvince)}{" "}
                            {getJapaneseCity(
                              favorite.post.postProvince,
                              favorite.post.postCity
                            )}{" "}
                            / {getCategoryName(favorite.post.category)}
                          </div>
                          <p>
                            {favorite.post.content
                              ? favorite.post.content.substring(0, 40) +
                                (favorite.post.content.length > 40 ? "…" : "")
                              : "内容がありません"}
                          </p>
                        </>
                      ) : (
                        <h3>削除された投稿</h3>
                      )}
                    </div>
                  </div>
                </div>
                <hr /> {/* この行を追加 */}
              </div>
            ))
          )}
        </>
      )}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ◁
        </button>
        <span style={{ margin: "0 10px" }}>
          {currentPage} / {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          ▷
        </button>
      </div>
      <br />
      <br />
      {loading && (
        <div className="favoriteOverlay">
          <div>
            <div className="processing-text">処理中...</div>
          </div>
        </div>
      )}
      <ToastContainer /> {/* トースト通知コンテナ */}
    </div>
  );
}

export default MyFavorites;
