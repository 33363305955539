// src/components/Terms.js
import React from "react";

function Terms() {
  const termsStyle = {
    width: "80%",
    margin: "0 auto",
    textAlign: "left",
  };

  // メディアクエリ用のスタイルを追加
  const mobileStyle = {
    "@media (max-width: 480px)": {
      width: "355px",
    },
    "@media (max-width: 752px)": {
      width: "600px",
    },
    "@media (min-width: 753px)": {
      width: "900px",
    },
  };
  return (
    <div style={{ ...termsStyle, ...mobileStyle }}>
      <h2>利用規約</h2>
      <h3>第1章　本規約への同意及び本規約の変更</h3>{" "}
      <h4>第1.1条（規約の適用範囲）</h4>{" "}
      <p>
        {" "}
        カコミュ利用規約（以下、「本規約」といいます。）は、合同会社アニトヤ（以下、「当社」といいます。）が提供する地域掲示板サービスである「カコミュ」（以下、「本サービス」といいます。）（ウェブサービス、アプリサービスを含み、以下、特記のない限り「本サービス」と総称します。）の利用に対して適用されます。本サービスを利用する者を「ユーザー」といいます。{" "}
      </p>{" "}
      <h4>第1.2条（本規約への同意及び適用）</h4>{" "}
      <p>
        {" "}
        本規約は、本サービスの利用に関する条件をユーザーと当社との間で定めることを目的とし、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。ユーザーは、本規約に同意をしたうえで、本規約の定めに従って本サービスを利用するものとし、ユーザーは、本サービスを利用することにより本規約に同意をしたものとみなされます。{" "}
      </p>{" "}
      <h4>第1.3条（未成年者の場合）</h4>{" "}
      <p>
        {" "}
        ユーザーが未成年者である場合は、事前に親権者など法定代理人の包括的な同意を得たうえで本サービスを利用しなければなりません。ユーザーが未成年者である場合は、法定代理人の同意の有無に関して、当社からユーザー又は法定代理人に対し、確認の連絡をする場合があります。{" "}
      </p>{" "}
      <h4>第1.4条（本規約の変更）</h4>{" "}
      <p>
        {" "}
        当社は、本サービスの運営上の都合その他当社が必要と判断する場合には、随時、本規約を変更することができます。変更後の本規約は、本サービス上に表示した時点より効力を生じるものとします。ユーザーが本サービスを利用した場合、変更に同意したものとみなします。なお、本規約の変更に同意しないユーザーは、本サービスの利用を停止してください。当社は、本規約の改定又は変更によりユーザーに生じたすべての損害について、一切責任を負いません。{" "}
      </p>{" "}
      <h4>第1.5条（当社からの通知）</h4>{" "}
      <p>
        {" "}
        当社は、本サービス上の掲示や電子メールの送付等により、ユーザーに対し必要な情報を通知します。通知は、掲示または送信した時点より効力を生じるものとします。{" "}
      </p>
      <h3>第2章　ユーザー登録及びアカウント情報</h3>
      <h4>第2.1条（本サービスの利用）</h4>
      <p>
        ユーザーは、本サービスの利用にあたり、本規約を遵守するものとします。本サービスは、投稿の掲載やユーザー間でのメッセージのやり取り等の「場」を提供するものです。
      </p>
      <h4>第2.2条（会員登録）</h4>
      <p>
        本サービスへの登録の申請は必ず本サービスを利用する個人又は法人自身（権限のある代表者）が行わなければならず、原則として代理人による登録申請は認められません。
        当社は、登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
        <ul>
          <li>本規約に違反するおそれがあると当社が判断した場合</li>
          <li>
            当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
          </li>
          <li>過去に本サービスの利用の登録を取り消された者である場合</li>
          <li>未成年者が親権者の同意等を得ていない場合</li>
          <li>
            反社会的勢力等（暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業その他これらに準ずるものを意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
          </li>
          <li>その他、当社が登録を適当でないと判断した場合</li>
        </ul>
      </p>
      <h3>第3章　投稿等に関する規約</h3>
      <h4>第3.1条（投稿等に関する責任）</h4>
      <p>
        当社は、投稿等の内容の信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について何ら保証するものではありません。
        また、当社は、投稿等が本規約に抵触するか否かを積極的に監視する義務を負うものでもありません。
      </p>
      <h4>第3.2条（禁止行為）</h4>
      <p>
        ユーザーは、本サービスを利用して以下の行為を行わないものとします。
        <ul>
          <li>他人の権利を侵害する行為</li>
          <li>法令に違反する行為</li>
          <li>公序良俗に反する行為</li>
          <li>わいせつ、ポルノ、性的な内容を含む行為</li>
          <li>マルチ商法やネズミ講への勧誘行為</li>
          <li>その他当社が不適切と判断する行為</li>
        </ul>
      </p>
      <p>
        禁止行為に該当する又は該当するおそれがあると当社が判断した場合、当社は、ユーザーの投稿、チャット、コメント、ダイレクトメッセージ等についてユーザーの許可を得ることなく送信防止措置（削除あるいは非表示）を講じることができるものとし、
        また、ユーザーの本サービスの以後の利用を禁止することもできるものとします。当社は、本条に基づき当社が行った措置に基づきユーザーに損害が生じた場合でも、一切の責任を負わないものとし、ユーザーはこれを了承します。
      </p>
      <h4>第3.3条（取引等に係る自己責任の原則等）</h4>
      <p>
        ユーザー間の取引等は、ユーザー間の自己責任において行われるものであり、当社は関与しません。トラブルが発生した場合、ユーザー間で解決するものとします。
      </p>
      <h3>第4章　チャット、コメント、ダイレクトメッセージ等に関する規約</h3>{" "}
      <h4>第4.1条（チャット等の利用）</h4>{" "}
      <p>
        {" "}
        　ユーザーは、チャット、コメント、ダイレクトメッセージ等を利用する際、本規約を遵守するものとします。{" "}
      </p>{" "}
      <h4>第4.2条（チャット等に関する責任）</h4>{" "}
      <p>
        {" "}
        　当社は、チャット、コメント、ダイレクトメッセージ等の内容の信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について何ら保証するものではありません。また、当社は、投稿等が本規約に抵触するか否かを積極的に監視する義務を負うものでもありません。{" "}
      </p>
      <h4>第4.3条（禁止行為）</h4>{" "}
      <p>
        {" "}
        　ユーザーは、チャット、コメント、ダイレクトメッセージ等を利用して以下の行為を行わないものとします。{" "}
        <ul>
          {" "}
          <li>他人の権利を侵害する行為</li> <li>法令に違反する行為</li>{" "}
          <li>公序良俗に反する行為</li>{" "}
          <li>わいせつ、ポルノ、性的な内容を含む行為</li>
          <li>マルチ商法やネズミ講への勧誘行為</li>
          <li>その他当社が不適切と判断する行為</li>{" "}
        </ul>{" "}
      </p>{" "}
      <p>
        {" "}
        禁止行為に該当する又は該当するおそれがあると当社が判断した場合、当社は、ユーザーの投稿、チャット、コメント、ダイレクトメッセージ等についてユーザーの許可を得ることなく送信防止措置（削除あるいは非表示）を講じることができるものとし、
        また、ユーザーの本サービスの以後の利用を禁止することもできるものとします。当社は、本条に基づき当社が行った措置に基づきユーザーに損害が生じた場合でも、一切の責任を負わないものとし、ユーザーはこれを了承します。
      </p>{" "}
      <h3>第5章　ユーザーの退会</h3>
      <h4>第5.1条（退会手続）</h4>
      <p>
        ユーザーは、当社所定の手続により、本サービスからいつでも退会することができます。退会により、ユーザーは本サービスの利用に関する一切の権利を失い、未使用の有料サービスの料金は返金されません。
      </p>
      <h4>第5.2条（退会後の情報の取り扱い）</h4>
      <p>
        当社は、退会したユーザーの個人情報を、法令に基づき適切に取り扱います。ただし、退会後も当社は、必要に応じてユーザーの情報を保持する場合があります。
      </p>
      <h3>第6章　免責事項</h3>
      <p>
        1.
        本サービスの利用にあたり、ユーザーは以下の免責事項に同意するものとします。
      </p>
      <p>
        2.
        当社は、本サービスの利用に際して発生するいかなる損害についても、その責任を負いません。ユーザーが本サービス内で投稿、チャット、コメント、ダイレクトメッセージ等を行った内容について、当社は一切の責任を負いません。
      </p>
      <p>
        3.
        当社は、本サービスの運営において、予告なしにサービスの内容を変更、運営を中断、または終了することがあります。これによりユーザーに生じた損害について、当社は一切の責任を負いません。
      </p>
      <p>
        4.
        本サービスの利用に関連して発生した損害について、当社は一切の責任を負いません。これには、データの損失、通信障害、その他の技術的な問題が含まれます。
      </p>
      <p>
        5.
        当社は、本サービスの利用によってユーザーに生じた損害や損失について、一切の責任を負いません。これには、ユーザーの期待に反する結果が生じた場合も含まれます。
      </p>
      <p>
        6.
        当社は、ユーザーが本サービスを利用することによって得た情報の信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について保証しません。ユーザーは自己の責任において情報を利用するものとします。
      </p>
      <p>
        7.
        当社は、本サービスの利用に際して、規約の変更、サービスの中断、終了などに関して、ユーザーが被った損害や損失について一切の責任を負いません。
      </p>
      <p>
        8.
        当社は、投稿の掲示時期、削除、誤配、保存・バックアップの有無について責任を負いません。ユーザーは自己の責任においてデータの管理を行うものとします。
      </p>
      <p>
        9.
        当社がユーザーに対して損害賠償責任を負う場合、その責任は直接かつ通常の損害に限られ、損害発生時点から過去1ヶ月間にユーザーから受領した有料オプション利用料の総額を上限とします。
      </p>
      <p>
        10.
        当社は、ユーザーが使用する機器やソフトウェアの動作について保証いたしません。また、これに関連して生じた損害についても一切の責任を負いません。
      </p>
      <p>
        11.
        本サービス利用時に発生する接続費用は、ユーザーの自己責任で管理されるものであり、当社は一切の保証を行いません。
      </p>
      <p>
        12.
        ユーザーは、本サービスの利用が自身に適用される法令や業界団体の内部規則に違反しないかを自己責任で確認するものとし、当社はその適合性を保証しません。
      </p>
      <p>
        13.
        当社は、ユーザーが本サービスの利用によって他のユーザーや第三者に損害を与えた場合、その責任を負いません。
      </p>
      <p>
        14.
        当社は、本サービスから他のウェブサイトへのリンクや他のウェブサイトから本サービスへのリンクが提供されている場合、そのウェブサイトや情報に関して一切の責任を負いません。
      </p>
      <p>
        15.
        当社は、ユーザーが本サービスの投稿やチャットやコメントやダイレクトメッセージ等の内容の信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について何ら保証するものではありません。また本サービスの利用で知り得た情報や内容からEメールや電話番号、WebサイトのURL、他のウェブサイトへのリンクその他の情報に連絡やアクセス、面会等をして発生した損害に対して、一切の責任を負わないものとします。
      </p>
      <h3>第7章　禁止事項</h3>
      <p>
        ユーザーは、本サービスを利用するにあたり、以下の行為を行ってはなりません。
        <ul>
          <li>他人の権利を侵害する行為</li>
          <li>法令に違反する行為</li>
          <li>公序良俗に反する行為</li>
          <li>わいせつ、ポルノ、性的な内容を含む行為</li>
          <li>マルチ商法やネズミ講への勧誘行為</li>
          <li>その他当社が不適切と判断する行為</li>
        </ul>
      </p>
      禁止行為に該当する又は該当するおそれがあると当社が判断した場合、当社は、ユーザーの投稿、チャット、コメント、ダイレクトメッセージ等についてユーザーの許可を得ることなく送信防止措置（削除あるいは非表示）を講じることができるものとし、
      また、ユーザーの本サービスの以後の利用を禁止することもできるものとします。当社は、本条に基づき当社が行った措置に基づきユーザーに損害が生じた場合でも、一切の責任を負わないものとし、ユーザーはこれを了承します。
      <h3>第8章　コンテンツの権利及び利用</h3>
      <h4>第8.1条（コンテンツの権利）</h4>
      <p>
        本サービスにおける全てのコンテンツ（テキスト、画像、動画等）の著作権その他の知的財産権は、当社又は当社にライセンスを許諾している者に帰属します。ユーザーは、本サービスの全ての素材に関して、一切の権利を取得することはないものとし、権利者の許可なく、所有権、著作権を含む一切の知的財産権、肖像権、パブリシティー権等、コンテンツ素材に関する権利を侵害する一切の行為をしてはならないものとします。本規約に基づく本サービスの利用の許諾は、本サービスに関する当社又は当該権利を有する第三者の権利の使用許諾を意味するものではありません。
      </p>
      <h4>第8.2条（利用許諾）</h4>
      <p>
        ユーザーは、本サービスを通じて得た情報を、私的利用の範囲を超えて利用することはできません。また、当社の許可なく、コンテンツを複製、転載、配布することを禁じます。さらに、ユーザーにより投稿された写真、動画、情報等に関しては、本サービスの宣伝、運営、研究開発及び発表等を目的として、当社及び当社の指定する者が自由に利用できるものとします。
      </p>
      <h4>第8.3条（コンテンツに関する責任）</h4>
      <p>
        ユーザーが本サービスに関連して発信又は掲載したコンテンツに関する一切の責任は、当該ユーザーが負うものとし、当社は、その内容、信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質にについて確認いたしません。また、当社は、それらに関して保証しないものとします。
      </p>
      <h4>第8.4条（コンテンツの内容等）</h4>
      <p>
        ユーザーは、他のユーザーが発信又は掲載するコンテンツについて、その内容、信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について、ユーザー自身で判断する必要があります。当社は、ユーザー及び第三者が当社のコンテンツを利用することにより生じる損害について、当社の故意又は過失に起因する場合を除き、責任を負わないものとします。
      </p>
      <h4>第8.5条（バックアップ）</h4>
      <p>
        当社はコンテンツのバックアップを行う義務を負わないものとします。ユーザーは、コンテンツのバックアップが必要な場合には、自己の費用と責任でこれを行うものとします。
      </p>
      <h4>第8.6条（コンテンツの変更及び削除）</h4>
      <p>
        当社は、ユーザーが本規約に違反又は本規約の精神に照らして不適切な行為を行ったと当社が判断した場合、当該ユーザーが掲載したあらゆるコンテンツを、事前の通知なしに変更及び削除できるものとします。
      </p>
      <h3>第9章　損害賠償</h3>
      <h4>第9.1条（損害賠償の請求）</h4>
      <p>
        ユーザーが本規約に違反した場合、当該ユーザーは、当該違反により損害を受けたユーザー及び第三者に対する損害賠償責任を含む一切の責任を負うものとします。さらに、ユーザーが不正若しくは違法に本サービスを利用することにより当社に損害を与えた場合、当該ユーザーその他関連当事者は連帯して当該損害（弁護士費用を含む）を賠償するものとします。
      </p>
      <h4>第9.2条（当社の免責事項）</h4>
      <p>
        当社は、当社による本サービスの提供の停止、終了又は変更、ユーザー登録の取消、コンテンツの削除又は消失、本サービスの利用によるデータの消失又は機器の故障その他本サービスに関連してユーザーが被った損害につき、当社の故意又は過失に起因する場合を除き、賠償する責任を負わないものとします。
      </p>
      <h4>第9.3条（当社の責任範囲）</h4>
      <p>
        当社がユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任は、直接かつ通常の損害に限り、かつ、損害の事由が生じた時点から遡って過去1ヶ月の期間にユーザーから現実に受領した有料オプション利用料の総額を上限とします。
      </p>
      <h3>第10章　その他</h3>
      <h4>第10.1条（反社会的勢力への対応）</h4>
      <p>
        当社および本サービスは反社会的勢力と一切の関係を持たないことを表明し保証します。ユーザーは、当社又は本サービス又は他のユーザーその他の第三者に対し次の各号に該当する行為を一切行ってはなりません。
      </p>
      <ol>
        <li>脅迫的、暴力的又は法的な責任を超えた要求</li>
        <li>風説を流布し、偽計を用い又は威力を用いた信用毀損又は業務妨害</li>
        <li>虚偽の情報を提供し、又は事実を歪曲する行為</li>
        <li>不正アクセスやハッキングによるシステムへの侵入行為</li>
        <li>詐欺的な手法を用いた金銭の要求</li>
        <li>第三者を利用して間接的に圧力をかける行為</li>
        <li>違法薬物や武器の取引に関与する行為</li>
        <li>マネーロンダリングや資金洗浄に関与する行為</li>
        <li>
          当社および本サービスの運営を妨害する目的での大量のクレームや問い合わせ
        </li>
        <li>その他、前各号に類似するいかなる行為</li>
      </ol>
      <p>
        ユーザーが違反していることが判明した場合、当社は当該ユーザーの投稿を削除し、当該ユーザーによる本サービスの使用を停止することができます。ユーザーは、前項の規定により本サービスの使用を停止された場合、当社に対して何らの損害賠償ないし補償を請求することはできず、また当社に損害が生じたときはその損害を賠償するものとします。
      </p>
      <h4>第10.2条（準拠法と管轄裁判所）</h4>
      <p>
        本規約は日本法に基づき解釈されます。本規約及び本サービスに関する紛争は、その訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とすることとします。
      </p>
      <p>（2024年10月1日制定）</p>
    </div>
  );
}

export default Terms;
