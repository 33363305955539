import React from "react";
import PostForm from "./PostForm"; // PostForm コンポーネントをインポート
import "../styles/PostScreen.css";

const PostScreen = () => {
  return (
    <div className="postScreenContainer">
      <h2>新しい投稿を作成します</h2>
      <div className="post-form-container">
        <PostForm /> {/* PostForm コンポーネントを表示 */}
      </div>
      {/* 他にも投稿リストや追加のUI要素をここに配置できます */}
    </div>
  );
};

export default PostScreen;
