import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import Turnstile from "react-turnstile"; // Turnstileのインポート
import "../styles/Inquiries.css"; // CSSファイルをインポート

function Inquiries() {
  const [formData, setFormData] = useState({
    inquiryType: "",
    email: "",
    postURL: "",
    content: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // 送信成功状態を追加する
  const [inquiryNumber, setInquiryNumber] = useState(""); // お問い合わせ番号を追加
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false); // Turnstile認証の状態を管理

  useEffect(() => {
    const { inquiryType, email, content } = formData;
    const isFormValid =
      inquiryType && email && content.length >= 10 && content.length <= 500; // contentの長さのバリデーションを追加
    setIsSubmitDisabled(!isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // 文字数制限を適用
    if (name === "email" && value.length > 50) return;
    if (name === "postURL" && value.length > 50) return;
    if (name === "content" && value.length > 500) return;

    // 半角英数字とすべての記号のバリデーション
    const validPattern = /^[a-zA-Z0-9!"#$%&'()¥*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;

    if (name === "email" || name === "postURL") {
      if (!validPattern.test(value)) return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    const validPattern = /^[a-zA-Z0-9!"#$%&'()¥*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;

    if (!formData.inquiryType)
      newErrors.inquiryType = "お問い合わせ項目は必須です。";

    if (!formData.email) {
      newErrors.email = "メールアドレスは必須です。";
    } else if (formData.email.length > 50) {
      newErrors.email = "メールアドレスは50文字以内で入力してください。";
    } else if (!validPattern.test(formData.email)) {
      newErrors.email = "有効なメールアドレスを入力してください。";
    }

    if (formData.postURL && formData.postURL.length > 50) {
      newErrors.postURL = "該当する投稿のURLは50文字以内で入力してください。";
    } else if (formData.postURL && !validPattern.test(formData.postURL)) {
      newErrors.postURL = "有効なURLを入力してください。";
    }

    if (!formData.content) {
      newErrors.content = "お問い合わせ内容は必須です。";
    } else if (10 > formData.content.length > 500) {
      newErrors.content =
        "お問い合わせ内容は10文字以上500文字以内で入力してください。";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // フィールドのクリーンアップ
    const cleanFormData = {
      inquiryType: DOMPurify.sanitize(formData.inquiryType),
      email: DOMPurify.sanitize(formData.email),
      postURL: DOMPurify.sanitize(formData.postURL),
      content: DOMPurify.sanitize(formData.content),
    };

    setIsSubmitting(true); // 送信開始時に isSubmitting を true に設定

    try {
      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/inquiries",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
          },
          body: JSON.stringify(cleanFormData), // クリーンアップしたデータを送信
        }
      );

      if (!response.ok) {
        throw new Error("ネットワーク応答が正常ではありませんでした");
      }

      const result = await response.json();

      // お問い合わせ番号を設定
      await setInquiryNumber(result.id.slice(-12));

      // 送信成功状態を設定
      await setSubmissionSuccess(true);

      // フォームをリセットする
      setFormData({
        inquiryType: "",
        email: "",
        postURL: "",
        content: "",
      });
      setErrors({});
    } catch (error) {
      console.error("フォームの送信中にエラーが発生しました:", error);
      setErrors({
        submit: "フォームの送信中にエラーが発生しました。再試行してください。",
      });
    } finally {
      setIsSubmitting(false); // 送信完了時に isSubmitting を false に設定
    }
  };

  const handleTurnstileVerify = (token) => {
    fetch(
      "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/verifyTurnstile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
        },
        body: JSON.stringify({ token }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setIsTurnstileVerified(true); // 認証成功時に状態を更新
        } else {
          setIsTurnstileVerified(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsTurnstileVerified(false);
      });
  };

  return (
    <div>
      {submissionSuccess ? (
        <div className="inquiries-response">
          <h2>お問い合わせが送信されました</h2>
          <p>
            この度はお問い合わせいただき、誠にありがとうございます。
            本メッセージは自動返信システムにより送信されております。お問合せ番号を保存してください。
            ただいま人員不足のため、ご返信にお時間をいただいております。担当者よりメールアドレスにご連絡いたしますので、今しばらくお待ちください。
          </p>
          <p>お問い合わせ番号：{inquiryNumber}</p>
          <a href="/" className="back-to-home">
            トップに戻る
          </a>{" "}
          {/* トップに戻るリンクを追加 */}
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="inquiries-form">
          <h2>お問い合わせ</h2>
          <div>
            <label htmlFor="inquiryType">お問い合わせ項目(必須)</label>
            <select
              name="inquiryType"
              value={formData.inquiryType}
              onChange={handleChange}
            >
              <option value="">選択してください</option>
              <option value=" アカウント作成・ログイン">
                アカウント作成・ログイン
              </option>
              <option value="パスワードのリセット">パスワードのリセット</option>
              <option value="投稿やコメントについて">
                投稿やコメントについて
              </option>
              <option value="機能のリクエスト">機能のリクエスト</option>
              <option value="セキュリティの問題">セキュリティの問題</option>
              <option value="バグの報告">バグの報告</option>
              <option value="プライバシーに関する質問">
                プライバシーに関する質問
              </option>
              <option value="ユーザー間のトラブル">ユーザー間のトラブル</option>
              <option value="詐欺や脅迫などの問題">詐欺や脅迫などの問題</option>
              <option value="退会について">退会について</option>
              <option value="改善提案">改善提案</option>
              <option value="弊社へのインターンや寄付について">
                弊社へのインターンや寄付について
              </option>
              <option value="その他">その他</option>
            </select>
            {errors.inquiryType && <p>{errors.inquiryType}</p>}
          </div>
          <div>
            <label htmlFor="email">メールアドレス(必須)</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p>{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="postURL">該当する投稿のURL(任意)</label>
            <input
              type="url"
              name="postURL"
              value={formData.postURL}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="content">お問い合わせ内容(必須)</label>
            <textarea
              name="content"
              value={formData.content}
              onChange={handleChange}
              placeholder="10文字以上500文字以内"
            ></textarea>
            {errors.content && <p>{errors.content}</p>}
          </div>
          <Turnstile
            sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
            onVerify={handleTurnstileVerify}
          />

          <button
            type="submit"
            disabled={isSubmitDisabled || isSubmitting || !isTurnstileVerified}
          >
            送信
          </button>

          {errors.submit && <p>{errors.submit}</p>}
        </form>
      )}
    </div>
  );
}

export default Inquiries;
