import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";

const PublicRoute = React.memo(({ children }) => {
  const isVerified = useMemo(() => {
    const isVerified = localStorage.getItem("isVerified");
    return isVerified === "true";
  }, []);

  const location = useLocation();

  if (!isVerified) {
    return <Navigate to={`/verify?redirect=${location.pathname}`} replace />;
  }

  return children;
});

export default PublicRoute;
