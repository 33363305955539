// store.js
import { configureStore } from "@reduxjs/toolkit";
import regionReducer from "../features/RegionSlice";
import messageReducer from "../features/MessageSlice";
import authReducer from "../features/AuthSlice";
import userProfileReducer from "../features/UserProfileSlice";

export const store = configureStore({
  reducer: {
    region: regionReducer,
    message: messageReducer,
    auth: authReducer,
    userProfile: userProfileReducer,
  },
});
