export const japanCitiesMap = {
  tokyo: {
    "": "市区町村を選択",
    chiyoda: "千代田区",
    minato: "港区",
    chuo: "中央区",
    shibuya: "渋谷区",
    shinjuku: "新宿区",
    bunkyo: "文京区",
    meguro: "目黒区",
    setagaya: "世田谷区",
    nakano: "中野区",
    shinagawa: "品川区",
    ota: "大田区",
    toshima: "豊島区",
    suginami: "杉並区",
    nerima: "練馬区",
    sumida: "墨田区",
    taito: "台東区",
    kita: "北区",
    edogawa: "江戸川区",
    itabashi: "板橋区",
    koto: "江東区",
    arakawa: "荒川区",
    katsushika: "葛飾区",
    adachi: "足立区",
    hachioji: "八王子市",
    machida: "町田市",
    fuchu: "府中市",
    chofu: "調布市",
    tachikawa: "立川市",
    mitaka: "三鷹市",
    akishima: "昭島市",
    hino: "日野市",
    fussa: "福生市",
    ome: "青梅市",
    nishitokyo: "西東京市",
    musashimurayama: "武蔵村山市",
    musashino: "武蔵野市",
    higashikurume: "東久留米市",
    higashimurayama: "東村山市",
    higashiyamato: "東大和市",
    kodaira: "小平市",
    koganei: "小金井市",
    kokubunji: "国分寺市",
    kunitachi: "国立市",
    inagi: "稲城市",
    akiruno: "あきる野市",
    hamura: "羽村市",
    tama: "多摩市",
    hinode: "日の出町",
    okutama: "奥多摩町",
    hachijo: "八丈町",
    aogashima: "青ヶ島村",
    oshima: "大島町",
    niijima: "新島村",
    kozushima: "神津島村",
    miyakejima: "三宅村",
    mikurajima: "御蔵島村",
    ogasawara: "小笠原村",
  },

  hokkaido: {
    "": "市区町村を選択",
    sapporo: "札幌市",
    asahikawa: "旭川市",
    hakodate: "函館市",
    kushiro: "釧路市",
    obihiro: "帯広市",
    kitami: "北見市",
    tomakomai: "苫小牧市",
    kitahiroshima: "北広島市",
    muroran: "室蘭市",
    otaru: "小樽市",
    ebetsu: "江別市",
    ishiwari: "石狩市",
    nemuro: "根室市",
    wakkakai: "稚内市",
    eniwa: "恵庭市",
    abashiri: "網走市",
    takikawa: "滝川市",
    bibai: "美唄市",
    iwamizawa: "岩見沢市",
    ashibetsu: "芦別市",
    rumoi: "留萌市",
    noboribetsu: "登別市",
    furano: "富良野市",
    biei: "美瑛町",
    monbetsu: "紋別市",
    yubari: "夕張市",
    yoichi: "余市町",
    kutchan: "倶知安町",
    shibetsu: "士別市",
    mikasa: "三笠市",
    esashi: "江差町",
    shihoro: "士幌町",
    otofuke: "音更町",
    shintoku: "新得町",
    ikeda: "池田町",
    toyokoro: "豊頃町",
    urahoro: "浦幌町",
    rebun: "礼文町",
    rishiri: "利尻町",
  },

  aomori: {
    "": "市区町村を選択",
    aomori: "青森市",
    hachinohe: "八戸市",
    hirosaki: "弘前市",
    towada: "十和田市",
    misawa: "三沢市",
    goshogawara: "五所川原市",
    kuroishi: "黒石市",
    mutsu: "むつ市",
    hirakawa: "平川市",
    hiranai: "平内町",
    itayanagi: "板柳町",
    fukaura: "深浦町",
    noheji: "野辺地町",
    rokunohe: "六戸町",
    shingo: "新郷村",
    shichinohe: "七戸町",
    tohoku: "東北町",
    nanbu: "南部町",
    gonohe: "五戸町",
    oirase: "おいらせ町",
    sai: "佐井村",
    higashidori: "東通村",
    kazamaura: "風間浦村",
    yokohama: "横浜町",
    ajigasawa: "鯵ヶ沢町",
  },

  iwate: {
    "": "市区町村を選択",
    morioka: "盛岡市",
    kitakami: "北上市",
    oshu: "奥州市",
    ichinoseki: "一関市",
    hanamaki: "花巻市",
    takizawa: "滝沢市",
    rikuzentakata: "陸前高田市",
    tono: "遠野市",
    miyako: "宮古市",
    kuji: "久慈市",
    kamaishi: "釜石市",
    ofunato: "大船渡市",
    ninohe: "二戸市",
    hachimantai: "八幡平市",
    shizukuishi: "雫石町",
    yamada: "山田町",
    hiraizumi: "平泉町",
    iwate: "岩手町",
    hirono: "洋野町",
    karumai: "軽米町",
  },

  miyagi: {
    "": "市区町村を選択",
    sendai: "仙台市",
    ishinomaki: "石巻市",
    shiogama: "塩竈市",
    kesennuma: "気仙沼市",
    natori: "名取市",
    tagajo: "多賀城市",
    iwanuma: "岩沼市",
    tomiya: "富谷市",
    kakuda: "角田市",
    higashimatsushima: "東松島市",
    osaki: "大崎市",
    zao: "蔵王町",
    shiroishi: "白石市",
    shichigahama: "七ヶ浜町",
    rifu: "利府町",
    yamamoto: "山元町",
    watari: "亘理町",
    shibata: "柴田町",
    ogawara: "大河原町",
    murata: "村田町",
    kawasaki: "川崎町",
    marumori: "丸森町",
    tome: "登米市",
    minamisanriku: "南三陸町",
  },

  akita: {
    "": "市区町村を選択",
    akita: "秋田市",
    daisen: "大仙市",
    odate: "大館市",
    yurihonjo: "由利本荘市",
    yokote: "横手市",
    kazuno: "鹿角市",
    kitaakita: "北秋田市",
    noshiro: "能代市",
    yuzawa: "湯沢市",
    senboku: "仙北市",
    katagami: "潟上市",
    oga: "男鹿市",
    nikaho: "にかほ市",
    kamikoani: "上小阿仁村",
    hachirogata: "八郎潟町",
    mitane: "三種町",
    gojome: "五城目町",
    fujisato: "藤里町",
    kosaka: "小坂町",
    ugo: "羽後町",
    higashinaruse: "東成瀬村",
  },

  yamagata: {
    "": "市区町村を選択",
    yamagata: "山形市",
    sakata: "酒田市",
    tsuruoka: "鶴岡市",
    yonezawa: "米沢市",
    obanazawa: "尾花沢市",
    sagae: "寒河江市",
    shinjo: "新庄市",
    murayama: "村山市",
    tendo: "天童市",
    kamiyama: "上山市",
    nanyo: "南陽市",
    higashine: "東根市",
    takahata: "高畠町",
    nagai: "長井市",
  },

  fukushima: {
    "": "市区町村を選択",
    fukushima: "福島市",
    koriyama: "郡山市",
    iwaki: "いわき市",
    sukagawa: "須賀川市",
    nihonmatsu: "二本松市",
    aizuwakamatsu: "会津若松市",
    soma: "相馬市",
    kitakata: "喜多方市",
    inawashiro: "猪苗代町",
    bandai: "磐梯町",
    minamiaizu: "南会津町",
    shimogo: "下郷町",
    tadami: "只見町",
    hinoemata: "檜枝岐村",
    shirakawa: "白河市",
    minamisoma: "南相馬市",
    tamura: "田村市",
    motomiya: "本宮市",
    kunimi: "国見町",
    kawamata: "川俣町",
    izumizaki: "泉崎村",
    nakajima: "中島村",
    yabuki: "矢吹町",
    asakawa: "浅川町",
    hanawa: "塙町",
    samegawa: "鮫川村",
    ishikawa: "石川町",
    tanagura: "棚倉町",
    yamatsuri: "矢祭町",
  },

  ibaraki: {
    "": "市区町村を選択",
    mito: "水戸市",
    tsukuba: "つくば市",
    hitachi: "日立市",
    hitachinaka: "ひたちなか市",
    tsuchiura: "土浦市",
    toride: "取手市",
    koga: "古河市",
    kasama: "笠間市",
    chikusei: "筑西市",
    moriya: "守谷市",
    oarai: "大洗町",
    kitaibaraki: "北茨城市",
    daigo: "大子町",
    yuki: "結城市",
    joso: "常総市",
    kashima: "鹿嶋市",
    kamisu: "神栖市",
    itako: "潮来市",
    ryugasaki: "龍ケ崎市",
    ushiku: "牛久市",
    inashiki: "稲敷市",
    sakuragawa: "桜川市",
    omitama: "小美玉市",
    shimotsuma: "下妻市",
    bando: "坂東市",
    yachiyo: "八千代町",
    miho: "美浦村",
    ami: "阿見町",
    hokota: "鉾田市",
    kasumigaura: "かすみがうら市",
    hitachiota: "常陸太田市",
    hitachiomiya: "常陸大宮市",
    naka: "那珂市",
    tokai: "東海村",
    shirosato: "城里町",
    ibaraki: "茨城町",
    kochi: "河内町",
    tone: "利根町",
  },

  tochigi: {
    "": "市区町村を選択",
    utsunomiya: "宇都宮市",
    oyama: "小山市",
    ashikaga: "足利市",
    sano: "佐野市",
    tochigi: "栃木市",
    nasushiobara: "那須塩原市",
    moka: "真岡市",
    kanuma: "鹿沼市",
    sakura: "さくら市",
    nikko: "日光市",
    nasu: "那須町",
    yaita: "矢板市",
    ohtawara: "大田原市",
    nasukarasuyama: "那須烏山市",
    shimotsuke: "下野市",
    mashiko: "益子町",
    motegi: "茂木町",
    kamikawa: "上川町",
    nakagawa: "那珂川町",
    shioya: "塩谷町",
    takanezawa: "高根沢町",
    ichikai: "市貝町",
  },

  gunma: {
    "": "市区町村を選択",
    maebashi: "前橋市",
    takasaki: "高崎市",
    kiryu: "桐生市",
    shibukawa: "渋川市",
    numata: "沼田市",
    isesaki: "伊勢崎市",
    ota: "太田市",
    tatebayashi: "館林市",
    tomioka: "富岡市",
    fujioka: "藤岡市",
    annaka: "安中市",
    shimonita: "下仁田町",
    kanra: "甘楽町",
    nakanojo: "中之条町",
    takayama: "高山村",
    naganohara: "長野原町",
    katsurao: "片品村",
    shiga: "草津町",
    minakami: "みなかみ町",
    agatsuma: "吾妻郡",
    higashiagatsuma: "東吾妻町",
    ueno: "上野村",
    tsumagoi: "嬬恋村",
    usui: "碓氷町",
    hara: "原町",
    tate: "館町",
    tono: "富岡市",
    myogi: "妙義町",
    yoshioka: "吉岡町",
  },

  saitama: {
    "": "市区町村を選択",
    saitama: "さいたま市",
    kawaguchi: "川口市",
    kawagoe: "川越市",
    tokorozawa: "所沢市",
    koshigaya: "越谷市",
    souka: "草加市",
    kasukabe: "春日部市",
    ageo: "上尾市",
    kumagaya: "熊谷市",
    iruma: "入間市",
    fujimi: "富士見市",
    fujimino: "ふじみ野市",
    asaka: "朝霞市",
    niiza: "新座市",
    shiki: "志木市",
    wako: "和光市",
    warabi: "蕨市",
    toda: "戸田市",
    sayama: "狭山市",
    hannou: "飯能市",
    chichibu: "秩父市",
    satte: "幸手市",
    tsurugashima: "鶴ヶ島市",
    hidaka: "日高市",
    yoshikawa: "吉川市",
    shiraoka: "白岡市",
    hanyu: "羽生市",
    fukaya: "深谷市",
    honjo: "本庄市",
    higashimatsuyama: "東松山市",
    konosu: "鴻巣市",
    kitamoto: "北本市",
    gyoda: "行田市",
    hasuda: "蓮田市",
    miyoshi: "三芳町",
    matsubushi: "松伏町",
    nagatoro: "長瀞町",
  },

  chiba: {
    "": "市区町村を選択",
    chiba: "千葉市",
    funabashi: "船橋市",
    ichikawa: "市川市",
    matsudo: "松戸市",
    kashiwa: "柏市",
    ichihara: "市原市",
    urayasu: "浦安市",
    narita: "成田市",
    sakura: "佐倉市",
    narashino: "習志野市",
    yachiyo: "八千代市",
    yotsukaido: "四街道市",
    choshi: "銚子市",
    minamiboso: "南房総市",
    tateyama: "館山市",
    kizarazu: "木更津市",
    asahi: "旭市",
    kujukuri: "九十九里町",
    togane: "東金市",
    mobara: "茂原市",
    kamogawa: "鴨川市",
    katsuura: "勝浦市",
    onjuku: "御宿町",
    kimitsu: "君津市",
    futtsu: "富津市",
    kyonan: "鋸南町",
    abiko: "我孫子市",
    nagareyama: "流山市",
    nagara: "長柄町",
    shirako: "白子町",
    otaki: "大多喜町",
    isumi: "いすみ市",
  },

  kanagawa: {
    "": "市区町村を選択",
    yokohama: "横浜市",
    kawasaki: "川崎市",
    sagamihara: "相模原市",
    fujisawa: "藤沢市",
    yokosuka: "横須賀市",
    hiratsuka: "平塚市",
    chigasaki: "茅ヶ崎市",
    yamato: "大和市",
    atsugi: "厚木市",
    odawara: "小田原市",
    ebina: "海老名市",
    isehara: "伊勢原市",
    hadano: "秦野市",
    zama: "座間市",
    ayase: "綾瀬市",
    kamakura: "鎌倉市",
    zushi: "逗子市",
    miura: "三浦市",
    minamiashigara: "南足柄市",
    ninomiya: "二宮町",
    oiso: "大磯町",
    samukawa: "寒川町",
    aiko: "愛川町",
    kiyosu: "清川村",
    hayama: "葉山町",
    hakone: "箱根町",
    yugawara: "湯河原町",
    manazuru: "真鶴町",
  },

  niigata: {
    "": "市区町村を選択",
    niigata: "新潟市",
    nagaoka: "長岡市",
    joetsu: "上越市",
    sanjo: "三条市",
    tsubame: "燕市",
    shibata: "新発田市",
    kashiwazaki: "柏崎市",
    sado: "佐渡市",
    uonuma: "魚沼市",
    tokamachi: "十日町市",
    ojiya: "小千谷市",
    tsunan: "津南町",
    echigoYuzawa: "湯沢町",
    naeba: "苗場",
    itoigawa: "糸魚川市",
    myoko: "妙高市",
    murakami: "村上市",
    agano: "阿賀野市",
    gosen: "五泉市",
    tainai: "胎内市",
    sekikawa: "関川村",
    tagami: "田上町",
    kariwa: "刈羽村",
    minamiuonuma: "南魚沼市",
    yuzawa: "湯沢町",
    mitsuke: "見附市",
  },

  yamanashi: {
    "": "市区町村を選択",
    kofu: "甲府市",
    kai: "甲斐市",
    minamiarupusu: "南アルプス市",
    fujiyoshida: "富士吉田市",
    yamanashi: "山梨市",
    chuo: "中央市",
    nirasaki: "韮崎市",
    tsuru: "都留市",
    otsuki: "大月市",
    hokuto: "北杜市",
    koshu: "甲州市",
    showa: "昭和町",
    ichikawamisato: "市川三郷町",
    fujikawaguchiko: "富士河口湖町",
    narusawa: "鳴沢村",
    nanbu: "南部町",
    minobu: "身延町",
    hayakawa: "早川町",
    doshi: "道志村",
    nishikatsura: "西桂町",
    oshino: "忍野村",
    yamanakako: "山中湖村",
  },

  nagano: {
    "": "市区町村を選択",
    nagano: "長野市",
    matsumoto: "松本市",
    ueda: "上田市",
    iida: "飯田市",
    chikuma: "千曲市",
    saku: "佐久市",
    azumino: "安曇野市",
    chino: "茅野市",
    shiojiri: "塩尻市",
    karuizawa: "軽井沢町",
    obuse: "小布施町",
    takayama: "高山村",
    iizuna: "飯綱町",
    iiyama: "飯山市",
    shinano: "信濃町",
    nozawaonsen: "野沢温泉村",
    kijimadaira: "木島平村",
    komoro: "小諸市",
    fujimi: "富士見町",
    hara: "原村",
    suwa: "諏訪市",
    ina: "伊那市",
    komagane: "駒ヶ根市",
    kiso: "木曽町",
    omachi: "大町市",
    hakuba: "白馬村",
    otari: "小谷村",
  },

  toyama: {
    "": "市区町村を選択",
    toyama: "富山市",
    takaoka: "高岡市",
    imizu: "射水市",
    tonami: "砺波市",
    nanto: "南砺市",
    himi: "氷見市",
    uozu: "魚津市",
    kurobe: "黒部市",
    namerikawa: "滑川市",
    oyabe: "小矢部市",
    tateyama: "立山町",
    kamiichi: "上市町",
    nyuzen: "入善町",
    funahashi: "舟橋村",
    asahi: "朝日町",
  },

  ishikawa: {
    "": "市区町村を選択",
    kanazawa: "金沢市",
    hakusan: "白山市",
    komatsu: "小松市",
    nanao: "七尾市",
    kaga: "加賀市",
    nomi: "能美市",
    kawakita: "川北町",
    tsubata: "津幡町",
    uchinada: "内灘町",
    hakui: "羽咋市",
    wajima: "輪島市",
    suzu: "珠洲市",
    hodatsushimizu: "宝達志水町",
    shika: "志賀町",
    anamizu: "穴水町",
    noto: "能登町",
  },

  fukui: {
    "": "市区町村を選択",
    fukui: "福井市",
    sakai: "坂井市",
    echizen: "越前市",
    tsuruga: "敦賀市",
    sabae: "鯖江市",
    awara: "あわら市",
    ono: "大野市",
    obama: "小浜市",
    katsuyama: "勝山市",
    eiheiji: "永平寺町",
    minamiechizen: "南越前町",
    mihama: "美浜町",
    wakasa: "若狭町",
    ohi: "おおい町",
    takahama: "高浜町",
    ikeda: "池田町",
  },

  gifu: {
    "": "市区町村を選択",
    gifu: "岐阜市",
    ogaki: "大垣市",
    takayama: "高山市",
    kakamigahara: "各務原市",
    tajimi: "多治見市",
    nakatsugawa: "中津川市",
    minokamo: "美濃加茂市",
    ena: "恵那市",
    seki: "関市",
    hashima: "羽島市",
    gujo: "郡上市",
    hida: "飛騨市",
    mino: "美濃市",
    gero: "下呂市",
    mitake: "御嵩町",
    yaotsu: "八百津町",
    higashishirakawa: "東白川村",
    shirakawa: "白川村",
  },

  shizuoka: {
    "": "市区町村を選択",
    shizuoka: "静岡市",
    hamamatsu: "浜松市",
    fuji: "富士市",
    fujinomiya: "富士宮市",
    yaizu: "焼津市",
    fujieda: "藤枝市",
    kakegawa: "掛川市",
    iwata: "磐田市",
    mishima: "三島市",
    numazu: "沼津市",
    gotemba: "御殿場市",
    susono: "裾野市",
    izu: "伊豆市",
    izunokuni: "伊豆の国市",
    atami: "熱海市",
    shimoda: "下田市",
    makinohara: "牧之原市",
    omaezaki: "御前崎市",
    ito: "伊東市",
    kawanehon: "川根本町",
    kawazu: "河津町",
    matsuzaki: "松崎町",
  },

  aichi: {
    "": "市区町村を選択",
    nagoya: "名古屋市",
    toyohashi: "豊橋市",
    toyota: "豊田市",
    okazaki: "岡崎市",
    ichinomiya: "一宮市",
    kasugai: "春日井市",
    anjo: "安城市",
    seto: "瀬戸市",
    komaki: "小牧市",
    inuyama: "犬山市",
    toyoake: "豊明市",
    nisshin: "日進市",
    owariasahi: "尾張旭市",
    nagakute: "長久手市",
    miyoshi: "みよし市",
    togo: "東郷町",
    chiryu: "知立市",
    kariya: "刈谷市",
    tahara: "田原市",
    gamagori: "蒲郡市",
    toyokawa: "豊川市",
    shinshiro: "新城市",
    tokai: "東海市",
    handa: "半田市",
    chita: "知多市",
    minamichita: "南知多町",
    mihama: "美浜町",
    taketoyo: "武豊町",
    agui: "阿久比町",
  },

  mie: {
    "": "市区町村を選択",
    tsu: "津市",
    yokkaichi: "四日市市",
    suzuka: "鈴鹿市",
    matsusaka: "松阪市",
    ise: "伊勢市",
    kuwana: "桑名市",
    iga: "伊賀市",
    inabe: "いなべ市",
    nabari: "名張市",
    kameyama: "亀山市",
    shima: "志摩市",
    owase: "尾鷲市",
    kumano: "熊野市",
    toba: "鳥羽市",
    kiho: "紀宝町",
    kihoku: "紀北町",
    mihama: "御浜町",
    taiki: "大紀町",
    tamaki: "玉城町",
    watarai: "度会町",
    minamiise: "南伊勢町",
  },
  shiga: {
    "": "市区町村を選択",
    otsu: "大津市",
    kusatsu: "草津市",
    hikone: "彦根市",
    nagahama: "長浜市",
    moriyama: "守山市",
    koka: "甲賀市",
    higashiomi: "東近江市",
    omihachiman: "近江八幡市",
    takashima: "高島市",
    konan: "湖南市",
    maibara: "米原市",
    ritto: "栗東市",
    aisho: "愛荘町",
    toyosato: "豊郷町",
    kora: "甲良町",
    taga: "多賀町",
    ryuoh: "竜王町",
  },

  kyoto: {
    "": "市区町村を選択",
    kyoto: "京都市",
    uji: "宇治市",
    yawata: "八幡市",
    nagaokakyo: "長岡京市",
    joyo: "城陽市",
    kameoka: "亀岡市",
    kyotanabe: "京田辺市",
    kizugawa: "木津川市",
    fukuchiyama: "福知山市",
    maizuru: "舞鶴市",
    nantan: "南丹市",
    ayabe: "綾部市",
    kyotango: "京丹後市",
    miyazu: "宮津市",
    muko: "向日市",
    ide: "井手町",
    minamiyamashiro: "南山城村",
    seika: "精華町",
    ujitawara: "宇治田原町",
    kyotanba: "京丹波町",
  },

  osaka: {
    "": "市区町村を選択",
    osaka: "大阪市",
    sakai: "堺市",
    higashiosaka: "東大阪市",
    hirakata: "枚方市",
    takatsuki: "高槻市",
    toyonaka: "豊中市",
    suita: "吹田市",
    ibaraki: "茨木市",
    yao: "八尾市",
    neyagawa: "寝屋川市",
    kadoma: "門真市",
    moriguchi: "守口市",
    daito: "大東市",
    habikino: "羽曳野市",
    matsubara: "松原市",
    izumi: "和泉市",
    kishiwada: "岸和田市",
    kawachinagano: "河内長野市",
    izumisano: "泉佐野市",
    settsu: "摂津市",
    osakasayama: "大阪狭山市",
    fujiidera: "藤井寺市",
    katano: "交野市",
    shijonawate: "四條畷市",
    kashiwara: "柏原市",
    ikeda: "池田市",
    izumiotsu: "泉大津市",
    hannan: "阪南市",
    kaizuka: "貝塚市",
    tajiri: "田尻町",
    kumatori: "熊取町",
    misaki: "岬町",
  },

  hyogo: {
    "": "市区町村を選択",
    kobe: "神戸市",
    himeji: "姫路市",
    nishinomiya: "西宮市",
    amagasaki: "尼崎市",
    akashi: "明石市",
    kakogawa: "加古川市",
    nishiwaki: "西脇市",
    takarazuka: "宝塚市",
    kawanishi: "川西市",
    sanda: "三田市",
    miki: "三木市",
    tatsuno: "たつの市",
    kasai: "加西市",
    tanba: "丹波市",
    shiso: "宍粟市",
    asago: "朝来市",
    toyooka: "豊岡市",
    sumoto: "洲本市",
    minamiawaji: "南あわじ市",
    awaji: "淡路市",
    aioi: "相生市",
    akou: "赤穂市",
    tanbasasayama: "丹波篠山市",
    shinonsen: "新温泉町",
    kami: "香美町",
    fukusaki: "福崎町",
    ichikawa: "市川町",
    kamikawa: "神河町",
    sayo: "佐用町",
    inami: "稲美町",
    harima: "播磨町",
    taishi: "太子町",
  },

  nara: {
    "": "市区町村を選択",
    nara: "奈良市",
    kashihara: "橿原市",
    ikoma: "生駒市",
    yamatotakada: "大和高田市",
    gose: "御所市",
    kashiba: "香芝市",
    tenri: "天理市",
    yamatoKoriyama: "大和郡山市",
    sakurai: "桜井市",
    uda: "宇陀市",
    gojo: "五條市",
    ikaruga: "斑鳩町",
    asuka: "明日香村",
    takatori: "高取町",
    kawakami: "川上村",
    higashiyoshino: "東吉野村",
    yoshino: "吉野町",
    shimoichi: "下市町",
    shimokitayama: "下北山村",
    kamikitayama: "上北山村",
  },
  wakayama: {
    "": "市区町村を選択",
    wakayama: "和歌山市",
    iwade: "岩出市",
    hashimoto: "橋本市",
    kinokawa: "紀の川市",
    tanabe: "田辺市",
    shingu: "新宮市",
    kainan: "海南市",
    gobou: "御坊市",
    arida: "有田市",
    katsuragi: "かつらぎ町",
    koya: "高野町",
    yuasa: "湯浅町",
    hirogawa: "広川町",
    aridagawa: "有田川町",
    shirahama: "白浜町",
    kamitonda: "上富田町",
    susami: "すさみ町",
    nachikatsuura: "那智勝浦町",
    taiji: "太地町",
    koza: "古座川町",
    kushimoto: "串本町",
    kitayama: "北山村",
  },

  tottori: {
    "": "市区町村を選択",
    tottori: "鳥取市",
    yonago: "米子市",
    kurayoshi: "倉吉市",
    sakaiminato: "境港市",
    hokuei: "北栄町",
    kotoura: "琴浦町",
    yazu: "八頭町",
    iwami: "岩美町",
    misasa: "三朝町",
    chizu: "智頭町",
    wakasa: "若桜町",
    hino: "日野町",
    kofu: "江府町",
    nichinan: "日南町",
  },

  shimane: {
    "": "市区町村を選択",
    matsue: "松江市",
    izumo: "出雲市",
    hamada: "浜田市",
    masuda: "益田市",
    yasugi: "安来市",
    unnan: "雲南市",
    ooda: "大田市",
    goutsu: "江津市",
    okuizumo: "奥出雲町",
    nishinoshima: "西ノ島町",
    tsuwano: "津和野町",
    yoshika: "吉賀町",
    ama: "海士町",
    chibu: "知夫村",
    okinoshima: "隠岐の島町",
  },

  okayama: {
    "": "市区町村を選択",
    okayama: "岡山市",
    kurashiki: "倉敷市",
    tsuyama: "津山市",
    soja: "総社市",
    tamano: "玉野市",
    setouchi: "瀬戸内市",
    bizen: "備前市",
    takahashi: "高梁市",
    akaiwa: "赤磐市",
    ibara: "井原市",
    asakuchi: "浅口市",
    maniwa: "真庭市",
    niimi: "新見市",
    kagamino: "鏡野町",
    misaki: "美咲町",
    yakage: "矢掛町",
    hayashima: "早島町",
    kibichuo: "吉備中央町",
    wake: "和気町",
  },

  hiroshima: {
    "": "市区町村を選択",
    hiroshima: "広島市",
    fukuyama: "福山市",
    higashihiroshima: "東広島市",
    kure: "呉市",
    hatsukaichi: "廿日市市",
    mihara: "三原市",
    onomichi: "尾道市",
    miyoshi: "三次市",
    fuchu: "府中市",
    shobara: "庄原市",
    otake: "大竹市",
    takehara: "竹原市",
    etajima: "江田島市",
    akitakata: "安芸高田市",
    osakikamijima: "大崎上島町",
    jinsekikogen: "神石高原町",
  },

  yamaguchi: {
    "": "市区町村を選択",
    yamaguchi: "山口市",
    shimonoseki: "下関市",
    ube: "宇部市",
    shunan: "周南市",
    iwakuni: "岩国市",
    hofu: "防府市",
    kudamatsu: "下松市",
    hagi: "萩市",
    yanai: "柳井市",
    nagato: "長門市",
    mine: "美祢市",
    hikari: "光市",
    tabuse: "田布施町",
    hirao: "平生町",
  },

  tokushima: {
    "": "市区町村を選択",
    tokushima: "徳島市",
    anan: "阿南市",
    naruto: "鳴門市",
    yoshinogawa: "吉野川市",
    awa: "阿波市",
    mima: "美馬市",
    miyoshi: "三好市",
    komatsushima: "小松島市",
    itano: "板野町",
    kitajima: "北島町",
    matsushige: "松茂町",
    kamiita: "上板町",
    ishii: "石井町",
    katsuura: "勝浦町",
    kamiyama: "神山町",
    tsurugi: "つるぎ町",
    minami: "美波町",
    mugi: "牟岐町",
    kaiyo: "海陽町",
    sanagouchi: "佐那河内村",
  },

  kagawa: {
    "": "市区町村を選択",
    takamatsu: "高松市",
    marugame: "丸亀市",
    mitoyo: "三豊市",
    kanonji: "観音寺市",
    sakaide: "坂出市",
    sanuki: "さぬき市",
    higashikagawa: "東かがわ市",
    zentsuji: "善通寺市",
    utazu: "宇多津町",
    kotohira: "琴平町",
    tadotsu: "多度津町",
    manno: "まんのう町",
    shodoshima: "小豆島町",
    tonosho: "土庄町",
    naoshima: "直島町",
  },

  ehime: {
    "": "市区町村を選択",
    matsuyama: "松山市",
    imabari: "今治市",
    niihama: "新居浜市",
    saijo: "西条市",
    shikokuchuo: "四国中央市",
    uwajima: "宇和島市",
    toon: "東温市",
    iyo: "伊予市",
    yawatahama: "八幡浜市",
    ozu: "大洲市",
    seiyo: "西予市",
    masaki: "松前町",
    tobe: "砥部町",
    uchiko: "内子町",
    kumakogen: "久万高原町",
    kihoku: "鬼北町",
    ainan: "愛南町",
    ikata: "伊方町",
    matsuno: "松野町",
  },

  kochi: {
    "": "市区町村を選択",
    kochi: "高知市",
    nangoku: "南国市",
    shimanto: "四万十市",
    konan: "香南市",
    kami: "香美市",
    sukumo: "宿毛市",
    tosa: "土佐市",
    susaki: "須崎市",
    aki: "安芸市",
    tosashimizu: "土佐清水市",
    muroto: "室戸市",
    ino: "いの町",
    sakawa: "佐川町",
    yusuhara: "梼原町",
    hidaka: "日高村",
    otsuki: "大月町",
    mihara: "三原村",
    kitagawa: "北川村",
    motoyama: "本山町",
    umaji: "馬路村",
    yasuda: "安田町",
    geisei: "芸西村",
    nahari: "奈半利町",
    toyo: "東洋町",
  },

  fukuoka: {
    "": "市区町村を選択",
    fukuoka: "福岡市",
    kitakyushu: "北九州市",
    kurume: "久留米市",
    omuta: "大牟田市",
    chikushino: "筑紫野市",
    onojo: "大野城市",
    kasuga: "春日市",
    munakata: "宗像市",
    nakagawa: "中間市",
    nogata: "直方市",
    iizuka: "飯塚市",
    tagawa: "田川市",
    yanagawa: "柳川市",
    yame: "八女市",
    chikugo: "筑後市",
    okawa: "大川市",
    yukuhashi: "行橋市",
    buzen: "豊前市",
    ogori: "小郡市",
    dazaifu: "太宰府市",
    koga: "古賀市",
    fukutsu: "福津市",
    umin: "宇美町",
    shime: "志免町",
    sugi: "須恵町",
    kasuya: "粕屋町",
    hisayama: "久山町",
    sasaguri: "篠栗町",
    shingu: "新宮町",
    miyawaka: "宮若市",
    kama: "嘉麻市",
    itoshima: "糸島市",
    keisen: "桂川町",
    asakura: "朝倉市",
    chikujyo: "築上町",
    kanda: "苅田町",
    miyako: "みやこ町",
    soeda: "添田町",
    kawasaki: "川崎町",
    itoda: "糸田町",
  },

  saga: {
    "": "市区町村を選択",
    saga: "佐賀市",
    karatsu: "唐津市",
    tosu: "鳥栖市",
    takeo: "武雄市",
    imari: "伊万里市",
    kashima: "鹿島市",
    ogi: "小城市",
    ureshino: "嬉野市",
    kanzaki: "神埼市",
    yoshinogari: "吉野ヶ里町",
    kamimine: "上峰町",
    miyaki: "みやき町",
    kiyama: "基山町",
    arita: "有田町",
    omachi: "大町町",
    kohoku: "江北町",
    shiroishi: "白石町",
    tara: "太良町",
  },

  nagasaki: {
    "": "市区町村を選択",
    nagasaki: "長崎市",
    sasebo: "佐世保市",
    isahaya: "諫早市",
    omura: "大村市",
    unzen: "雲仙市",
    shimabara: "島原市",
    saikai: "西海市",
    goto: "五島市",
    tsushima: "対馬市",
    minamishimabara: "南島原市",
    hirado: "平戸市",
    matsuura: "松浦市",
    nagayo: "長与町",
    togitsu: "時津町",
    higashisonogi: "東彼杵町",
    kawauchi: "川棚町",
    hasami: "波佐見町",
    saku: "佐々町",
    shinkamigoto: "新上五島町",
    iki: "壱岐市",
    ojika: "小値賀町",
  },

  kumamoto: {
    "": "市区町村を選択",
    kumamoto: "熊本市",
    yatsushiro: "八代市",
    tamana: "玉名市",
    yamaga: "山鹿市",
    koshi: "合志市",
    uki: "宇城市",
    amakusa: "天草市",
    kamiamakusa: "上天草市",
    arao: "荒尾市",
    kikuchi: "菊池市",
    uto: "宇土市",
    hitoyoshi: "人吉市",
    aso: "阿蘇市",
    minamata: "水俣市",
    ozu: "大津町",
    kikuyo: "菊陽町",
    mashiki: "益城町",
    yamato: "山都町",
    nagasu: "長洲町",
    kashima: "嘉島町",
    gyokuto: "玉東町",
    nagomi: "和水町",
    nishihara: "西原村",
    minamioguni: "南小国町",
    oguni: "小国町",
    misato: "美里町",
    ashikita: "芦北町",
    tsunagi: "津奈木町",
    taragi: "多良木町",
    nishiki: "錦町",
  },

  oita: {
    "": "市区町村を選択",
    oita: "大分市",
    beppu: "別府市",
    nakatsu: "中津市",
    hita: "日田市",
    saiki: "佐伯市",
    usuki: "臼杵市",
    usa: "宇佐市",
    yufu: "由布市",
    bungoono: "豊後大野市",
    kitsuki: "杵築市",
    bungotakada: "豊後高田市",
    kunisaki: "国東市",
    tsukumi: "津久見市",
    taketa: "竹田市",
    kokonoe: "九重町",
    kuju: "玖珠町",
    himeshima: "姫島村",
  },

  miyazaki: {
    "": "市区町村を選択",
    miyazaki: "宮崎市",
    miyakonojou: "都城市",
    nobeoka: "延岡市",
    saito: "西都市",
    hyuga: "日向市",
    nichinan: "日南市",
    kobayashi: "小林市",
    ebino: "えびの市",
    kushima: "串間市",
    mimata: "三股町",
    takachiho: "高千穂町",
    hinokage: "日之影町",
    gokase: "五ヶ瀬町",
    kawaminami: "川南町",
    tsuno: "都農町",
    kadogawa: "門川町",
    shintomi: "新富町",
    kijou: "木城町",
    misato: "美郷町",
    aya: "綾町",
  },

  kagoshima: {
    "": "市区町村を選択",
    kagoshima: "鹿児島市",
    kirishima: "霧島市",
    kanoya: "鹿屋市",
    satsumasendai: "薩摩川内市",
    aira: "姶良市",
    minamisatsuma: "南さつま市",
    minamikyushu: "南九州市",
    izumi: "出水市",
    hioki: "日置市",
    soo: "曽於市",
    shibushi: "志布志市",
    makurazaki: "枕崎市",
    ichikikushikino: "いちき串木野市",
    akune: "阿久根市",
    isa: "伊佐市",
    ibusuki: "指宿市",
    tarumizu: "垂水市",
    yakushima: "屋久島町",
    nishinoomote: "西之表市",
    amami: "奄美市",
    setouchi: "瀬戸内町",
    tatsugo: "龍郷町",
    tokunoshima: "徳之島町",
    amagi: "天城町",
    isen: "伊仙町",
    kikai: "喜界町",
    okinoerabu: "和泊町",
    china: "知名町",
    yoron: "与論町",
  },

  okinawa: {
    "": "市区町村を選択",
    naha: "那覇市",
    okinawa: "沖縄市",
    uruma: "うるま市",
    ginowan: "宜野湾市",
    ishigaki: "石垣市",
    urasoe: "浦添市",
    nago: "名護市",
    itoman: "糸満市",
    tomigusuku: "豊見城市",
    miyakojima: "宮古島市",
    nanjo: "南城市",
    yonabaru: "与那原町",
    haebaru: "南風原町",
    yaese: "八重瀬町",
    kunigami: "国頭村",
    ogimi: "大宜味村",
    higashi: "東村",
    nakijin: "今帰仁村",
    motobu: "本部町",
    onna: "恩納村",
    ginoza: "宜野座村",
    kin: "金武町",
    ie: "伊江村",
    tarama: "多良間村",
    taketomi: "竹富町",
    yonaguni: "与那国町",
    kumejima: "久米島町",
    minamidaito: "南大東村",
    kitadaito: "北大東村",
  },
};
