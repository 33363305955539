import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import Turnstile from "react-turnstile"; // Turnstileのインポート
import "../styles/EmailContactForm.css"; // 必要に応じてCSSを追加

const EmailContactForm = ({ closeForm, title, postId, emailForContact }) => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false); // Turnstile認証の状態を管理

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    let formErrors = {};

    if (!validateEmail(email)) {
      formErrors.email = "有効なEmailアドレスを入力してください。";
    }

    if (email !== confirmEmail) {
      formErrors.confirmEmail = "Emailアドレスが一致しません。";
    }

    if (content.length < 10 || content.length > 500) {
      formErrors.content =
        "問い合わせ内容は10文字以上、500文字以下で入力してください。";
    }

    setErrors(formErrors);

    return formErrors;
  };

  useEffect(() => {
    validateForm();
  }, [email, confirmEmail, content]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // フィールドのクリーンアップ
    const cleanFormData = {
      title: DOMPurify.sanitize(title),
      contactContent: DOMPurify.sanitize(content),
      postId: DOMPurify.sanitize(postId),
      senderEmail: DOMPurify.sanitize(email),
      recipientEmail: DOMPurify.sanitize(emailForContact),
    };

    setIsSubmitting(true); // 送信開始時に isSubmitting を true に設定

    try {
      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/contacts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
          },
          body: JSON.stringify(cleanFormData), // クリーンアップしたデータを送信
        }
      );

      if (!response.ok) {
        throw new Error("ネットワーク応答が正常ではありませんでした");
      }

      alert("問い合わせ内容が投稿者に送信されました。");
      setEmail("");
      setConfirmEmail("");
      setContent("");
      setErrors({});
      closeForm(); // フォームを閉じる
    } catch (error) {
      console.error("問い合わせ内容の送信中にエラーが発生しました:", error);
      setErrors({
        submit:
          "問い合わせ内容の送信中にエラーが発生しました。再試行してください。",
      });
    } finally {
      setIsSubmitting(false); // 送信完了時に isSubmitting を false に設定
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\x00-\x7F]/g, ""); // 全角文字や日本語を削除
    setEmail(sanitizedValue);
  };

  const handleConfirmEmailChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\x00-\x7F]/g, ""); // 全角文字や日本語を削除
    setConfirmEmail(sanitizedValue);
  };

  const handleTurnstileVerify = (token) => {
    fetch(
      "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/verifyTurnstile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
        },
        body: JSON.stringify({ token }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setIsTurnstileVerified(true); // 認証成功時に状態を更新
        } else {
          setIsTurnstileVerified(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsTurnstileVerified(false);
      });
  };

  return (
    <div className="email-contact-form">
      <h2>メール問い合わせフォーム</h2>
      <p>
        投稿者にメールアドレスを通知し、返信は投稿者から直接届きます。
        <br />
        投稿者からの返信を保証するものではありません。
      </p>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Emailアドレス:</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            disabled={isSubmitting}
            placeholder="例:xxxxxxxxx@xxxxx.com(半角英数字)"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div>
          <label>Emailアドレス(再入力):</label>
          <input
            type="email"
            value={confirmEmail}
            onChange={handleConfirmEmailChange}
            disabled={isSubmitting}
            placeholder="例:xxxxxxxxx@xxxxx.com(半角英数字)"
          />
          {errors.confirmEmail && (
            <p className="error">{errors.confirmEmail}</p>
          )}
        </div>
        <div>
          <label>問い合わせ内容:</label>
          <textarea
            placeholder="10文字以上500文字以下で入力してください。"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={isSubmitting}
          />
          {errors.content && <p className="error">{errors.content}</p>}
        </div>
        <Turnstile
          className="turnstile-container"
          sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
          onVerify={handleTurnstileVerify}
        />

        <div className="button-group">
          <button type="button" onClick={closeForm} disabled={isSubmitting}>
            閉じる
          </button>
          <button type="submit" disabled={isSubmitting || !isTurnstileVerified}>
            {isSubmitting ? <span className="loading-spinner"></span> : "送信"}
          </button>
        </div>
        {errors.submit && <p className="error">{errors.submit}</p>}
      </form>
    </div>
  );
};

export default EmailContactForm;
