import React, { useState, useEffect } from "react";
import MySettings from "./MySettings";
import PostManage from "./PostManage";
import MyFavorites from "./MyFavorites";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import "../styles/MyPage.css"; // ここでCSSをインポート

function MyPage() {
  const [activeTab, setActiveTab] = useState("settings");

  // URLのハッシュを利用してタブの状態を保持・復元する
  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      setActiveTab(hash);
    }
  }, []);

  // URLのハッシュ部分を監視してタブの状態を更新する。
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace("#", "");
      if (hash) {
        setActiveTab(hash);
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    window.location.hash = tab;
  };

  return (
    <div className="myPageContainer">
      <h2>マイページ</h2>
      {/*  <p>
        プロフィール設定や投稿管理など、マイページの内容が表示されます。
      </p> */}
      <div style={{ textAlign: "center" }}>
        <button
          className="tab-button"
          onClick={() => handleTabChange("settings")}
        >
          プロフィール設定
        </button>
        <button className="tab-button" onClick={() => handleTabChange("posts")}>
          投稿管理
        </button>
        <button
          className="tab-button"
          onClick={() => handleTabChange("favorites")}
        >
          ブックマーク管理
        </button>
        {/* <button className="tab-button" onClick={() => handleTabChange('notices')}>通知</button> */}
        <button
          className="tab-button"
          onClick={() => handleTabChange("changeEmail")}
        >
          Eメール変更
        </button>
        <button
          className="tab-button"
          onClick={() => handleTabChange("changePassword")}
        >
          パスワード変更
        </button>
      </div>

      <div style={{ textAlign: "center" }}>
        {activeTab === "settings" && <MySettings />}
        {activeTab === "posts" && <PostManage />}
        {activeTab === "favorites" && <MyFavorites />}
        {/* {activeTab === 'notices' && <MyNotices />} */}
        {activeTab === "changeEmail" && <ChangeEmail />}
        {activeTab === "changePassword" && <ChangePassword />}
      </div>
    </div>
  );
}

export default MyPage;
