/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://gx6urcdazrccbmtxxlys7fumii.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-eytmeklytvhldc4ynzzjzqavvy",
    "aws_cognito_identity_pool_id": "us-east-1:992853d2-eb1b-4562-b031-bf7065a3dc0c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GXCbwSRMX",
    "aws_user_pools_web_client_id": "445frg0lo8t5irjctemkntoqgr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cacommu5b003dcb33394503aabe86247c89331d25199-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
