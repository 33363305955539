import React from "react";
import Turnstile from "react-turnstile";
import { useNavigate } from "react-router-dom";
import "../styles/VerificationPage.css"; // CSSファイルをインポート

const VerificationPage = () => {
  const sitekey = process.env.REACT_APP_TURNSTILE_SITE_KEY;
  const navigate = useNavigate();

  const handleVerify = (token) => {
    fetch(
      "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/verifyTurnstile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
        },
        body: JSON.stringify({ token }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("isVerified", "true");
        const redirectTo =
          new URLSearchParams(window.location.search).get("redirect") || "/";
        navigate(redirectTo);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="verification-page">
      <div className="verifycontent">
        <h1>Verification Page</h1>
        <Turnstile sitekey={sitekey} onVerify={handleVerify} />
      </div>
      <div className="overlay"></div>
    </div>
  );
};

export default VerificationPage;
