// src/components/PrivacyPolicy.js
import React from "react";

function PrivacyPolicy() {
  const privacyStyle = {
    width: "80%",
    margin: "0 auto",
    textAlign: "left",
  };

  // メディアクエリ用のスタイルを追加
  const mobileStyle = {
    "@media (max-width: 480px)": {
      width: "355px",
    },
    "@media (max-width: 752px)": {
      width: "600px",
    },
    "@media (min-width: 753px)": {
      width: "900px",
    },
  };

  return (
    <div style={{ ...privacyStyle, ...mobileStyle }}>
      <h2>プライバシーポリシー</h2>
      <section>
        <h3>個人情報の定義</h3>
        <p>
          本サイトでは、個人情報について、ユーザー個人に関する情報であって、当該情報を構成する氏名、住所、メールアドレス、生年月日その他の記述等により当該ユーザー個人を識別できる情報とします。
        </p>
      </section>

      <section>
        <h3>個人情報の適正な取得</h3>
        <p>
          本サイトは、利用目的の達成に必要な個人情報を適正に取得し、偽りその他の不正の手段によって取得することはありません。
        </p>
      </section>

      <section>
        <h3>個人情報の利用目的</h3>
        <p>
          本サイトは、個人情報の取得に際して、お知らせした利用目的または以下の目的のために取得した情報を利用します。
        </p>
        <ul>
          <li>ユーザーの個人認証</li>
          <li>ユーザーへのサービスの提供</li>
          <li>サービス向上等を目的としたアンケート、キャンペーンの実施</li>
          <li>メールによる各種情報の無料提供、お問い合わせへの返答</li>
          <li>利用規約に違反する態様、不正アクセス等の不正利用の防止</li>
        </ul>
      </section>

      <section>
        <h3>個人情報の第三者提供</h3>
        <p>
          本サイトは、以下に記載する場合を除き、原則として、ユーザーご本人の同意を得ずに個人情報を第三者に提供しません。
        </p>
        <ul>
          <li>法令に基づく場合</li>
          <li>人の生命、身体又は財産の保護のために必要がある場合</li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために必要がある場合
          </li>
          <li>
            国の機関若しくは地方公共団体が法令の定める事務や捜査を遂行することに対して協力する必要がある場合
          </li>
        </ul>
      </section>

      <section>
        <h3>個人情報の安全管理</h3>
        <p>
          本サイトは、個人情報の漏えい、減失又は毀損の防止その他の安全管理のための措置を講じます。
        </p>
      </section>

      <section>
        <h3>個人情報の変更等</h3>
        <p>
          ユーザーは、登録した個人情報の変更・追加・削除を請求することができ、本サイトは、当該請求がユーザー本人の請求であることを確認の上、対応します。
        </p>
      </section>

      <section>
        <h3>お問い合わせ窓口</h3>
        <p>
          個人情報の取り扱いに関するお問い合わせは、お問い合わせフォームよりご連絡ください。
        </p>
      </section>
      <p>（2024年10月1日制定）</p>
    </div>
  );
}

export default PrivacyPolicy;
