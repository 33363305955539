import React, { useEffect, useState, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { excludeOwnerListPosts } from "../graphql/queries";
import styles from "../styles/Category.module.css";
import { categories } from "../containers/CategoryList";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";

function Category() {
  const { prefecture, category } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  const [allListings, setAllListings] = useState([]);
  const [displayedListings, setDisplayedListings] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getJapaneseProvince = (region) => {
    if (region === "all") {
      return "全国";
    }
    return japanProvinceMap[region] || region;
  };

  const getJapaneseCity = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  const getJapaneseCategory = (category) => {
    const categoryObj = categories.find((cat) => cat.path === category);
    return categoryObj ? categoryObj.name : category;
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData
      ) {
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key);
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key);
      return null;
    }
  };

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const fetchImageUrl = useCallback(
    async (imageKey, postUpdatedAt) => {
      if (imageKey === "../assets/news_sample.png" || !imageKey) {
        const localImagePath = require("../assets/news_sample.png");
        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          [imageKey]: localImagePath,
        }));
        return localImagePath; // ローカルの画像パスをそのまま返す
      }
      const cachedData = getCachedImageUrl(imageKey);

      if (cachedData) {
        const cachedUpdatedAt = new Date(cachedData.updatedAt);
        const postUpdatedAtDate = new Date(postUpdatedAt);
        const diffInMinutes =
          (postUpdatedAtDate - cachedUpdatedAt) / (1000 * 60);

        if (diffInMinutes < 2) {
          setImageUrls((prevImageUrls) => ({
            ...prevImageUrls,
            [imageKey]: cachedData.url,
          }));
          return cachedData.url;
        }
      }

      try {
        // CloudFrontリンクを使用するように変更する
        const signedURL = `https://d1s9xshr26t6r2.cloudfront.net/public/${imageKey}`;
        setCachedImageUrl(imageKey, signedURL, new Date(), postUpdatedAt);
        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          [imageKey]: signedURL,
        }));
        return signedURL;
      } catch (err) {
        console.error("Error fetching image URL:", err);
        return null;
      }
    },
    [imageUrls]
  );

  const fetchPosts = useCallback(async () => {
    try {
      let postData;
      const commonFilter = {
        and: [
          { category: { eq: category } },
          { postingStatus: { eq: "ACTIVE" } },
        ],
      };

      if (prefecture === "all") {
        postData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, {
            filter: commonFilter,
            limit: 1000, // 全件取得のために大きな数を指定
          })
        );
      } else {
        postData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, {
            filter: {
              and: [{ postProvince: { eq: prefecture } }, ...commonFilter.and],
            },
            limit: 1000, // 全件取得のために大きな数を指定
          })
        );
      }

      if (postData.data.listPosts.items) {
        setAllListings(postData.data.listPosts.items); // 全件をstateに格納
        loadMorePosts(postData.data.listPosts.items, 0); // 最初の10件を表示
      }
      setIsFetched(true);
    } catch (err) {
      console.error("Error fetching posts:", err);
      setIsFetched(true);
    }
  }, [prefecture, category]);

  // 追加: 10件ずつ表示するロジック
  const loadMorePosts = useCallback(
    (allListings, startIndex) => {
      const newItems = allListings.slice(startIndex, startIndex + 10);
      const newImageUrls = {};
      const fetchImages = async () => {
        for (const item of newItems) {
          const imageUrl = await fetchImageUrl(
            item.postImageUrl1,
            item.updatedAt
          );
          newImageUrls[item.postImageUrl1] = imageUrl;
        }
        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          ...newImageUrls,
        }));
      };

      fetchImages();

      setDisplayedListings((prevListings) => [...prevListings, ...newItems]);
      setCurrentIndex(startIndex + 10);
    },
    [fetchImageUrl]
  );

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  // 変更: handleLoadMoreClickを更新
  const handleLoadMoreClick = () => {
    loadMorePosts(allListings, currentIndex); // 次の10件を表示
  };

  const isMobile = window.innerWidth <= 752; // モバイル判定

  const truncateTitle = (title, isMobile) => {
    const maxLength = isMobile ? 22 : 35;
    return title.length > maxLength
      ? `${title.substring(0, maxLength)}...`
      : title;
  };

  const truncateContent = (content, isMobile) => {
    const maxLength = isMobile ? 33 : 80;
    return content.length > maxLength
      ? `${content.substring(0, maxLength)}...`
      : content;
  };

  const linksList = displayedListings.map((listing) => {
    const imageUrl1 = imageUrls[listing.postImageUrl1];
    const truncatedTitle = truncateTitle(listing.title, isMobile);
    const truncatedContent = truncateContent(listing.content, isMobile);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear().toString().slice(-2);
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}/${month}/${day}`;
    };

    const japaneseProvince = getJapaneseProvince(listing.postProvince);
    const japaneseCity = getJapaneseCity(
      listing.postProvince,
      listing.postCity
    );
    const japaneseCategory = getJapaneseCategory(listing.category);

    return (
      <React.Fragment key={listing.id}>
        <div className={styles.listingItem}>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {imageUrl1 && (
                <div style={{ marginRight: "16px" }}>
                  <Link
                    to={`/contents/${listing.id}`}
                    state={{ listing: listing, owner: listing.owner }}
                  >
                    <img
                      src={imageUrl1}
                      alt="Post Thumbnail 1"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </Link>
                </div>
              )}

              <div
                style={{ flexGrow: 1, minwidth: "350px", maxWidth: "1000px" }}
                className={styles.categoryContentSummary}
              >
                <Link
                  to={`/contents/${listing.id}`}
                  state={{ listing: listing, owner: listing.owner }}
                >
                  <h4>{truncatedTitle}</h4>
                </Link>
                <div style={{ fontSize: "0.8em", color: "gray" }}>
                  {japaneseProvince} {japaneseCity} / {japaneseCategory}
                </div>
                <p className={styles.categoryContentStyle}>
                  {truncatedContent}
                </p>
              </div>

              <div style={{ marginLeft: "16px" }}>
                <div style={{ margin: "8px 0" }}>
                  <span className={styles.categoryPostingInfo}>
                    投稿日: <br />
                    {formatDate(listing.createdAt)}
                  </span>
                </div>
                <div style={{ marginbottom: "0px" }}>
                  <span className={styles.categoryPostingInfo}>
                    更新日: <br />
                    {formatDate(listing.updatedAt)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  const noPostsMessage = isFetched && displayedListings.length === 0 && (
    <h3 style={{ textAlign: "center" }}>投稿がありません</h3>
  );

  return (
    <div>
      <h2>{getJapaneseCategory(category)}の情報</h2>
      <div className={styles.listingsContainer}>
        <div>
          {noPostsMessage}
          {linksList}
        </div>
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          {currentIndex < allListings.length && (
            <h4
              onClick={handleLoadMoreClick}
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                fontSize: "16px",
                background: "none",
                border: "none",
                color: "gray",
              }}
            >
              ▼さらに読み込む{" "}
            </h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default Category;
