/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      postId
      title
      contactContent
      senderEmail
      recipientEmail
      createdAt
      ip_addr
      updatedAt
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      content
      category
      postCountry
      postProvince
      postCity
      userProfileId
      comments {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      deletedAt
      options
      postingStatus
      postingViews
      favoritesCount
      commentsCount
      emailForContact
      postImageUrl1
      postImageUrlOthers
      postDetails {
        __typename
      }
      allowDirectMessages
      allowEmailContacts
      ip_addr
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      postID
      userProfileId
      content
      owner
      createdAt
      updatedAt
      deletedAt
      notifications {
        nextToken
        __typename
      }
      ip_addr
      __typename
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      id
      postID
      owner
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const deletePublicProfile = /* GraphQL */ `
  mutation DeletePublicProfile(
    $input: DeletePublicProfileInput!
    $condition: ModelPublicProfileConditionInput
  ) {
    deletePublicProfile(input: $input, condition: $condition) {
      id
      userProfileId
      nickname
      username
      bio
      userCountry
      userProvince
      userCity
      profileImageUrl
      gender
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSearchHistory = /* GraphQL */ `
  mutation DeleteSearchHistory(
    $input: DeleteSearchHistoryInput!
    $condition: ModelSearchHistoryConditionInput
  ) {
    deleteSearchHistory(input: $input, condition: $condition) {
      id
      searchWord
      userProfileId
      userRegion
      gender
      birthdate
      createdAt
      deletedAt
      ip_addr
      owner
      updatedAt
      __typename
    }
  }
`;
export const createInquiry = /* GraphQL */ `
  mutation CreateInquiry(
    $input: CreateInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    createInquiry(input: $input, condition: $condition) {
      id
      inquiryType
      email
      postURL
      content
      createdAt
      updatedAt
      deletedAt
      status
      responseDetails
      completedAt
      ip_addr
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      postId
      title
      contactContent
      senderEmail
      recipientEmail
      createdAt
      ip_addr
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      postId
      title
      contactContent
      senderEmail
      recipientEmail
      createdAt
      ip_addr
      updatedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      content
      category
      postCountry
      postProvince
      postCity
      userProfileId
      comments {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      deletedAt
      options
      postingStatus
      postingViews
      favoritesCount
      commentsCount
      emailForContact
      postImageUrl1
      postImageUrlOthers
      postDetails {
        __typename
      }
      allowDirectMessages
      allowEmailContacts
      ip_addr
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      content
      category
      postCountry
      postProvince
      postCity
      userProfileId
      comments {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      deletedAt
      options
      postingStatus
      postingViews
      favoritesCount
      commentsCount
      emailForContact
      postImageUrl1
      postImageUrlOthers
      postDetails {
        __typename
      }
      allowDirectMessages
      allowEmailContacts
      ip_addr
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      postID
      userProfileId
      content
      owner
      createdAt
      updatedAt
      deletedAt
      notifications {
        nextToken
        __typename
      }
      ip_addr
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      postID
      userProfileId
      content
      owner
      createdAt
      updatedAt
      deletedAt
      notifications {
        nextToken
        __typename
      }
      ip_addr
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      contributor
      createdAt
      updatedAt
      deletedAt
      messagesListID
      owner
      notifications {
        nextToken
        __typename
      }
      ip_addr
      hiddenFrom
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      contributor
      createdAt
      updatedAt
      deletedAt
      messagesListID
      owner
      notifications {
        nextToken
        __typename
      }
      ip_addr
      hiddenFrom
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      contributor
      createdAt
      updatedAt
      deletedAt
      messagesListID
      owner
      notifications {
        nextToken
        __typename
      }
      ip_addr
      hiddenFrom
      __typename
    }
  }
`;
export const createMessagesList = /* GraphQL */ `
  mutation CreateMessagesList(
    $input: CreateMessagesListInput!
    $condition: ModelMessagesListConditionInput
  ) {
    createMessagesList(input: $input, condition: $condition) {
      id
      messageFrom
      messageTo
      checkedStatus
      createdAt
      updatedAt
      deletedAt
      owner
      deletedBy
      blockedBy
      __typename
    }
  }
`;
export const updateMessagesList = /* GraphQL */ `
  mutation UpdateMessagesList(
    $input: UpdateMessagesListInput!
    $condition: ModelMessagesListConditionInput
  ) {
    updateMessagesList(input: $input, condition: $condition) {
      id
      messageFrom
      messageTo
      checkedStatus
      createdAt
      updatedAt
      deletedAt
      owner
      deletedBy
      blockedBy
      __typename
    }
  }
`;
export const deleteMessagesList = /* GraphQL */ `
  mutation DeleteMessagesList(
    $input: DeleteMessagesListInput!
    $condition: ModelMessagesListConditionInput
  ) {
    deleteMessagesList(input: $input, condition: $condition) {
      id
      messageFrom
      messageTo
      checkedStatus
      createdAt
      updatedAt
      deletedAt
      owner
      deletedBy
      blockedBy
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      referenceID
      owner
      createdAt
      updatedAt
      deletedAt
      isNotified
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      referenceID
      owner
      createdAt
      updatedAt
      deletedAt
      isNotified
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      referenceID
      owner
      createdAt
      updatedAt
      deletedAt
      isNotified
      __typename
    }
  }
`;
export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      id
      postID
      owner
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      id
      postID
      owner
      notifications {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      nickname
      username
      gender
      bio
      userCountry
      userProvince
      userCity
      owner
      createdAt
      updatedAt
      deletedAt
      birthdate
      family_name
      given_name
      selectLanguage
      profileImageUrl
      ip_addr
      ipAddressAddedAt
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      nickname
      username
      gender
      bio
      userCountry
      userProvince
      userCity
      owner
      createdAt
      updatedAt
      deletedAt
      birthdate
      family_name
      given_name
      selectLanguage
      profileImageUrl
      ip_addr
      ipAddressAddedAt
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      nickname
      username
      gender
      bio
      userCountry
      userProvince
      userCity
      owner
      createdAt
      updatedAt
      deletedAt
      birthdate
      family_name
      given_name
      selectLanguage
      profileImageUrl
      ip_addr
      ipAddressAddedAt
      __typename
    }
  }
`;
export const createPublicProfile = /* GraphQL */ `
  mutation CreatePublicProfile(
    $input: CreatePublicProfileInput!
    $condition: ModelPublicProfileConditionInput
  ) {
    createPublicProfile(input: $input, condition: $condition) {
      id
      userProfileId
      nickname
      username
      bio
      userCountry
      userProvince
      userCity
      profileImageUrl
      gender
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePublicProfile = /* GraphQL */ `
  mutation UpdatePublicProfile(
    $input: UpdatePublicProfileInput!
    $condition: ModelPublicProfileConditionInput
  ) {
    updatePublicProfile(input: $input, condition: $condition) {
      id
      userProfileId
      nickname
      username
      bio
      userCountry
      userProvince
      userCity
      profileImageUrl
      gender
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      reportedEntityId
      reportedEntityType
      reason
      additionalInfo
      createdAt
      updatedAt
      owner
      userProfileId
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      reportedEntityId
      reportedEntityType
      reason
      additionalInfo
      createdAt
      updatedAt
      owner
      userProfileId
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      reportedEntityId
      reportedEntityType
      reason
      additionalInfo
      createdAt
      updatedAt
      owner
      userProfileId
      __typename
    }
  }
`;
export const createSearchHistory = /* GraphQL */ `
  mutation CreateSearchHistory(
    $input: CreateSearchHistoryInput!
    $condition: ModelSearchHistoryConditionInput
  ) {
    createSearchHistory(input: $input, condition: $condition) {
      id
      searchWord
      userProfileId
      userRegion
      gender
      birthdate
      createdAt
      deletedAt
      ip_addr
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateSearchHistory = /* GraphQL */ `
  mutation UpdateSearchHistory(
    $input: UpdateSearchHistoryInput!
    $condition: ModelSearchHistoryConditionInput
  ) {
    updateSearchHistory(input: $input, condition: $condition) {
      id
      searchWord
      userProfileId
      userRegion
      gender
      birthdate
      createdAt
      deletedAt
      ip_addr
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateInquiry = /* GraphQL */ `
  mutation UpdateInquiry(
    $input: UpdateInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    updateInquiry(input: $input, condition: $condition) {
      id
      inquiryType
      email
      postURL
      content
      createdAt
      updatedAt
      deletedAt
      status
      responseDetails
      completedAt
      ip_addr
      __typename
    }
  }
`;
export const deleteInquiry = /* GraphQL */ `
  mutation DeleteInquiry(
    $input: DeleteInquiryInput!
    $condition: ModelInquiryConditionInput
  ) {
    deleteInquiry(input: $input, condition: $condition) {
      id
      inquiryType
      email
      postURL
      content
      createdAt
      updatedAt
      deletedAt
      status
      responseDetails
      completedAt
      ip_addr
      __typename
    }
  }
`;
