import React from "react";

const genderMap = {
  FEMALE: "女性",
  MALE: "男性",
  UNANSWERED: "無回答",
};

const GenderList = ({ onSelect, selectedGender }) => {
  const handleGenderSelect = (event) => {
    onSelect({ target: { name: "gender", value: event.target.value } });
  };

  return (
    <select onChange={handleGenderSelect} value={selectedGender}>
      <option value="">性別を選択</option>
      {Object.entries(genderMap).map(([key, name]) => (
        <option key={key} value={key}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default GenderList;
export { genderMap };
