import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = () => {
  return (
    <Carousel
      showThumbs={false}
      autoPlay
      interval={6000}
      infiniteLoop
      showStatus={false}
      stopOnHover={false} // マウスオーバーで停止しないようにする
    >
      <div>
        <img
          src={require("../assets/carousel1.jpg")}
          alt="carousel1"
          style={{
            width: "100%",
            height: "300px",
            objectFit: "cover",
          }}
          className="carousel-image"
        />
        <p
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "10px 0",
          }}
        >
          地域交流・広告掲示サイト「カコミュ」
        </p>
      </div>
      <div>
        <img
          src={require("../assets/carousel2.jpg")}
          alt="carousel2"
          style={{
            width: "100%",
            height: "300px",
            objectFit: "cover",
          }}
          className="carousel-image"
        />
        <p
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "10px 0",
          }}
        >
          メンバーを募集したり、コミュニティに参加してみよう!
        </p>
      </div>
      <div>
        <img
          src={require("../assets/carousel3.jpg")}
          alt="carousel3"
          style={{
            width: "100%",
            height: "300px",
            objectFit: "cover",
          }}
          className="carousel-image"
        />
        <p
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "10px 0",
          }}
        >
          住みたい人、住みたい家が無料で見つかる！
        </p>
      </div>
    </Carousel>
  );
};

export default CustomCarousel;
