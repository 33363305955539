// src/components/AboutUs.js
import React from "react";

function AboutUs() {
  const aboutStyle = {
    width: "80%",
    margin: "0 auto",
    textAlign: "left",
  };

  // メディアクエリ用のスタイルを追加
  const mobileStyle = {
    "@media (max-width: 480px)": {
      width: "355px",
    },
    "@media (max-width: 752px)": {
      width: "600px",
    },
    "@media (min-width: 753px)": {
      width: "900px",
    },
  };

  return (
    <div style={{ ...aboutStyle, ...mobileStyle }}>
      <h2>会社概要</h2>
      <h3>会社名</h3>
      <p>合同会社アニトヤ</p>

      <h3>設立</h3>
      <p>2024年9月11日</p>

      <h3>所在地</h3>
      <p>東京都渋谷区神泉町10</p>

      <h3>代表者</h3>
      <p>市塚 博敏</p>

      <h3>事業内容</h3>
      <ul>
        <li>地域掲示板サービスの運営</li>
        <li>オンラインコミュニティの構築</li>
        <li>デジタルマーケティング支援</li>
        <li>Webアプリケーションの開発</li>
      </ul>

      <h3>企業理念</h3>
      <p>
        私たち合同会社アニトヤは、地域社会のつながりを深めるための
        プラットフォームを提供し、誰もが自由に情報を共有できる
        環境を目指します。
      </p>

      <h3>お問い合わせ</h3>
      <p>お問い合わせフォームよりお願いいたします。</p>
    </div>
  );
}

export default AboutUs;
