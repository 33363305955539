import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom"; // useParamsとLinkフックをimport
import { API, graphqlOperation } from "aws-amplify"; // APIとgraphqlOperationをimport
import { getPostOnlyTitle } from "../graphql/queries"; // getPostOnlyTitleクエリをimport
import Chat from "./Chat"; // Chatコンポーネントのパスを正しく設定してください

const ChatPage = () => {
  const { id } = useParams(); // useParamsを使ってURLからidを取得
  const [title, setTitle] = useState(""); // タイトルを保存するためのstateを追加

  useEffect(() => {
    const fetchPostTitle = async () => {
      try {
        const postResult = await API.graphql(
          graphqlOperation(getPostOnlyTitle, { id: id })
        );
        setTitle(postResult.data.getPost.title);
      } catch (error) {
        console.error("Error fetching post title:", error);
      }
    };

    if (id) {
      fetchPostTitle();
    }
  }, [id]);

  return (
    <div>
      <Link
        to={`/contents/${id}`}
        style={{ textDecoration: "none", color: "blue" }}
      >
        <h3 style={{ margin: "5px" }}>{title}</h3> {/* タイトルを表示 */}
      </Link>
      <Chat postId={id} chatPage={true} /> {/* chatPageプロップを追加 */}
    </div>
  );
};

export default ChatPage;
