import React from "react";
import { Link } from "react-router-dom";

const listStyle = {
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "800px",
  padding: 0,
  margin: "0 auto",
  boxSizing: "border-box",
};

const listItemStyle = {
  flex: "1 0 25%",
  listStyleType: "none",
  padding: "10px",
  boxSizing: "border-box",
};

const mediaQueryStyle = `
  @media (max-width: 600px) {
    .listItemStyle {
      flex: 1 0 50%; /* モバイルでは2列にする */
    }
  }
`;

function SelectRegion() {
  return (
    <div>
      <style>{mediaQueryStyle}</style>
      <h2>都道府県を選択します</h2>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          <Link to="/region/hokkaido">北海道</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/aomori">青森県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/iwate">岩手県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/miyagi">宮城県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/akita">秋田県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/yamagata">山形県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/fukushima">福島県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/ibaraki">茨城県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/saitama">埼玉県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/chiba">千葉県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/tokyo">東京都</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/kanagawa">神奈川県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/tochigi">栃木県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/gunma">群馬県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/yamanashi">山梨県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/niigata">新潟県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/toyama">富山県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/ishikawa">石川県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/fukui">福井県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/nagano">長野県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/shizuoka">静岡県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/gifu">岐阜県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/aichi">愛知県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/mie">三重県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/shiga">滋賀県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/kyoto">京都府</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/osaka">大阪府</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/hyogo">兵庫県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/nara">奈良県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/wakayama">和歌山県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/tottori">鳥取県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/shimane">島根県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/okayama">岡山県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/hiroshima">広島県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/yamaguchi">山口県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/tokushima">徳島県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/kagawa">香川県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/ehime">愛媛県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/kochi">高知県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/fukuoka">福岡県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/saga">佐賀県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/nagasaki">長崎県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/kumamoto">熊本県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/oita">大分県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/miyazaki">宮崎県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/kagoshima">鹿児島県</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/region/okinawa">沖縄県</Link>
        </li>
      </ul>
    </div>
  );
}

export default SelectRegion;
