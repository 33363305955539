import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { API, graphqlOperation, Auth } from "aws-amplify";
import bannedWords from "../containers/BannedWordsList";
import {
  excludeOwnerComments,
  getPublicProfilesByUserProfileId,
  getPostOnlyCommentsCount,
} from "../graphql/queries";
import { createReport } from "../graphql/mutations";
import styles from "../styles/Chat.module.css";
import { FaCommentSlash, FaAngleDown, FaAngleUp, FaFlag } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import DOMPurify from "dompurify";

function Chat({ postId, chatPage }) {
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isComposing, setIsComposing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [expandedComments, setExpandedComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const commentsEndRef = useRef(null);
  const profileid = useSelector((state) => state.userProfile.profileid);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [isUndoConfirmOpen, setIsUndoConfirmOpen] = useState(false);
  const [currentMessageIdForAction, setCurrentMessageIdForAction] =
    useState(null);

  const openUndoConfirm = (commentId) => {
    setCurrentMessageIdForAction(commentId);
    setIsUndoConfirmOpen(true);
  };

  const closeUndoConfirm = () => {
    setIsUndoConfirmOpen(false);
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // Reportモーダル用のスタイル
  const reportModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: "400px",
    },
  };
  const confirmModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: "150px",
    },
  };

  const fetchCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
    } catch (err) {
      if (err !== "The user is not authenticated") {
        console.error("Error fetching current user:", err);
      }
    }
  };

  const fetchComments = useCallback(async () => {
    try {
      setIsLoading(true); // ローディング開始
      const commentData = await API.graphql(
        graphqlOperation(excludeOwnerComments, {
          filter: { postID: { eq: postId } },
        })
      );

      // commentsCountを取得
      const countData = await API.graphql(
        graphqlOperation(getPostOnlyCommentsCount, { id: postId })
      );
      setCommentsCount(countData.data.getPost.commentsCount);

      const commentsWithNicknames = await Promise.all(
        commentData.data.listComments.items
          .filter((comment) => !comment.deletedAt) // deletedAtが存在しないコメントのみを対象
          .map(async (comment) => {
            const profileData = await API.graphql({
              query: getPublicProfilesByUserProfileId,
              variables: {
                userProfileId: comment.userProfileId,
                limit: 1,
              },
            });
            const profile =
              profileData.data.publicProfilesByUserProfileId.items[0];
            const nickname = profile ? profile.nickname : "匿名";
            return { ...comment, nickname };
          })
      );

      // 古い順にソートする
      const sortedComments = commentsWithNicknames.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });

      setComments(sortedComments);
    } catch (err) {
      console.error("Error fetching comments:", err);
    } finally {
      setIsLoading(false); // ローディング終了
    }
  }, [postId]);

  useEffect(() => {
    fetchComments();
    fetchCurrentUser();
  }, [fetchComments, postId]);

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollTop = commentsEndRef.current.scrollHeight;
    }
  }, [comments]);

  // サニタイズ関数を追加
  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  // addComment関数内での修正
  async function addComment(e) {
    e.preventDefault();
    if (commentsCount >= 100) {
      toast.warn("コメントの上限に達しました");
      return;
    }
    if (inputValue.length < 2) {
      toast.warn("コメントを2文字以上入力してください");
      return;
    }
    if (inputValue.length > 50) {
      toast.warn("投稿は50字までです");
      return;
    }

    // 禁止ワードチェック
    const containsBannedWord = bannedWords.some((word) =>
      inputValue.includes(word)
    );
    if (containsBannedWord) {
      toast.warn("禁止ワードが含まれています", {
        autoClose: 1000,
      });
      return;
    }

    if (isComposing || isSubmitting) return;
    setIsSubmitting(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const owner = user.attributes.sub;
      const jwtToken = user.signInUserSession.idToken.jwtToken; // JWTトークンを取得

      const profileData = await API.graphql({
        query: getPublicProfilesByUserProfileId,
        variables: {
          userProfileId: profileid,
          limit: 1,
        },
      });
      const profile = profileData.data.publicProfilesByUserProfileId.items[0];
      const nickname = profile ? profile.nickname : "匿名";

      if (!inputValue || !user || !nickname) {
        setIsSubmitting(false);
        return;
      }

      const sanitizedInputValue = sanitizeInput(inputValue); // サニタイズ

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/comments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // JWTトークンをヘッダーに追加
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            content: sanitizedInputValue, // サニタイズされた値を使用
            postID: postId,
            userProfileId: profileid,
            owner: owner,
          }),
        }
      );

      if (response.ok) {
        toast.success("コメントを追加しました", {
          autoClose: 900,
        });
      } else {
        console.error("Error posting comment");
        toast.error("コメントの追加に失敗しました");
      }

      const newComment = {
        content: sanitizedInputValue, // サニタイズされた値を使用
        postID: postId,
        userProfileId: profileid,
        owner: owner,
        nickname: nickname,
        createdAt: new Date().toISOString(),
      };
      setComments((prevComments) => [...prevComments, newComment]);
      setInputValue("");

      updateCommentsCount(postId, 1);
      await fetchComments();
    } catch (err) {
      console.error("Error adding comment:", err);
      toast.error("コメントの追加に失敗しました");
    }
    setIsSubmitting(false);
  }

  const updateCommentsCount = async (postId, value) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken; // JWTトークンを取得

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/commentsCount`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // JWTトークンをヘッダーに追加
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            id: postId,
            commentsCountIncrement: value,
          }),
        }
      );

      if (response.ok) {
        setCommentsCount((prevCount) => prevCount + value); // commentsCountを更新
        return;
      } else {
        console.error("Error updating comments count");
      }
    } catch (err) {
      console.error("Error updating comments count:", err);
    }
  };

  const handleReportSubmit = (commentId) => {
    const sanitizedReason = sanitizeInput(reportReason); // サニタイズ
    const sanitizedInfo = sanitizeInput(additionalInfo); // サニタイズ

    reportComment(commentId, sanitizedReason, sanitizedInfo);
    closeModal();
  };

  const handleReportComment = (commentId) => {
    setReportReason("");
    setAdditionalInfo("");
    openModal();
    // モーダルを開ける前に、commentIdを一時的に保存する
    setCurrentCommentId(commentId);
  };

  const reportComment = async (commentId, reason, additionalInfo) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const owner = user.attributes.sub;

      const newReport = {
        reportedEntityId: commentId,
        reportedEntityType: "COMMENT",
        reason: reason,
        additionalInfo: additionalInfo,
        owner: owner,
        userProfileId: profileid,
        createdAt: new Date().toISOString(),
      };

      await API.graphql({
        query: createReport,
        variables: { input: newReport },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      toast.success("通報が完了しました");
    } catch (err) {
      console.error("Error reporting comment:", err);
      toast.error("通報に失敗しました");
    }
  };

  const handleDeleteComment = (commentId) => {
    const comment = comments.find((comment) => comment.id === commentId);
    if (
      comment &&
      new Date() - new Date(comment.createdAt) < 24 * 60 * 60 * 1000
    ) {
      openUndoConfirm(commentId);
    } else {
      toast.warn("コメントの送信取り消しは24時間以内にしかできません");
    }
  };

  const handleDeleteMessage = async (commentId) => {
    try {
      setIsSubmitting(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/deleteComments`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.signInUserSession.idToken.jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({ id: commentId }),
        }
      );

      if (response.ok) {
        toast.success("コメントを削除しました", {
          autoClose: 900,
        });
        // コメントリストから削除したコメントを取り除く
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId)
        );
        updateCommentsCount(postId, -1); // コメントのカウントを1減らす
      } else {
        console.error("Error deleting comment");
        toast.error("コメントの削除に失敗しました");
      }
      setIsSubmitting(false);
    } catch (err) {
      console.error("Error deleting comment:", err);
      toast.error("コメントの削除に失敗しました");
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.isComposing) {
      e.preventDefault();
      addComment(e);
    }
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  const toggleExpandComment = (commentId) => {
    setExpandedComments((prev) =>
      prev.includes(commentId)
        ? prev.filter((id) => id !== commentId)
        : [...prev, commentId]
    );
  };

  return (
    <div className={chatPage ? styles.chatPageContainer : styles.chatContainer}>
      {(isSubmitting || isLoading) && (
        <div className={styles.overlay}>
          <div className={styles.spinner}></div>
          {isLoading ? "読み込み中..." : "投稿中..."}
        </div>
      )}
      <ToastContainer />
      <Modal
        isOpen={isUndoConfirmOpen}
        onRequestClose={closeUndoConfirm}
        style={confirmModalStyles}
        contentLabel="Undo Confirm Modal"
      >
        <h2>
          送信を取り消しますか？ <br />
          (全員にコメントが表示されなくなります)
        </h2>
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              handleDeleteMessage(currentMessageIdForAction);
              closeUndoConfirm(); // 追加
            }}
          >
            はい
          </button>
          <button onClick={closeUndoConfirm}>いいえ</button>
        </div>
      </Modal>

      <div className={styles.commentsContainer} ref={commentsEndRef}>
        {comments.length === 0 && !isLoading && (
          <div className={styles.noCommentsMessage}>
            まだコメントはありません。
          </div>
        )}
        {comments.map((comment) => (
          <div
            key={comment.id || `${comment.createdAt}-${comment.userProfileId}`}
            className={styles.commentContainer}
          >
            <div
              className={styles.commentContent}
              style={{ textAlign: "left" }}
            >
              <div
                className={styles.authorAndTimestamp}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // 変更：アイコンを右端に配置
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={styles.commentAuthor}>
                    {comment.nickname} {/* nicknameを優先的に表示 */}
                  </span>
                  <span className={styles.commentTimestamp}>
                    {new Date(comment.createdAt).toLocaleString()}
                  </span>
                </div>
                {currentUser && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {profileid === comment.userProfileId &&
                      new Date() - new Date(comment.createdAt) <
                        24 * 60 * 60 * 1000 && (
                        <FaCommentSlash
                          className={`${styles.deleteIcon} ${styles.additionalStyles}`}
                          onClick={() => handleDeleteComment(comment.id)}
                        />
                      )}

                    {profileid !== comment.userProfileId && (
                      <FaFlag
                        className={`${styles.reportIcon} ${styles.additionalStyles}`} // 必要に応じてスタイルを追加
                        onClick={() => handleReportComment(comment.id)}
                      />
                    )}
                  </div>
                )}
              </div>

              <div>
                {comment.content.length > 50 ? (
                  expandedComments.includes(comment.id) ? (
                    <>
                      {comment.content}{" "}
                      <FaAngleUp
                        onClick={() => toggleExpandComment(comment.id)}
                      />
                    </>
                  ) : (
                    <>
                      {comment.content.substring(2, 50)}...{" "}
                      <FaAngleDown
                        onClick={() => toggleExpandComment(comment.id)}
                      />
                    </>
                  )
                ) : (
                  comment.content
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {currentUser ? (
        <div>
          {commentsCount >= 100 ? (
            <div className={styles.limitReachedMessage}>
              チャットの上限に達しました
            </div>
          ) : (
            <form
              className={styles.inputAndButtonContainer}
              onSubmit={addComment}
            >
              <textarea
                className={`${styles.commentinput} ${
                  chatPage ? styles.noMarginBottom : ""
                }`}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyPress={handleKeyPress}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
                placeholder="コメントを入力(2文字以上50文字以下)"
                rows="1"
                disabled={isSubmitting}
              />
              <button
                type="submit"
                className={`${styles.commentbutton} ${
                  (inputValue.length < 2 ||
                    inputValue.length > 50 ||
                    isSubmitting) &&
                  styles.disabledButton
                }`}
                disabled={
                  inputValue.length < 2 ||
                  inputValue.length > 50 ||
                  isSubmitting
                }
              >
                {isSubmitting ? <span>処理</span> : "送信"}
              </button>
            </form>
          )}
        </div>
      ) : (
        <div className={styles.loginRequiredMessage}>
          コメントをするにはログインが必要です
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={reportModalStyles}
        contentLabel="Report Modal"
      >
        <h2>通報理由を選択してください</h2>
        <div>
          <label>
            <input
              type="radio"
              value="Violent_post"
              checked={reportReason === "Violent_post"}
              onChange={(e) => setReportReason(e.target.value)}
            />
            暴力的な投稿
          </label>
          <label>
            <input
              type="radio"
              value="Sexual_post"
              checked={reportReason === "Sexual_post"}
              onChange={(e) => setReportReason(e.target.value)}
            />
            性的な投稿
          </label>
          <label>
            <input
              type="radio"
              value="Discriminatory_post"
              checked={reportReason === "Discriminatory_post"}
              onChange={(e) => setReportReason(e.target.value)}
            />
            差別的な投稿
          </label>
          <br />
          <label>
            <input
              type="radio"
              value="Criminal_post"
              checked={reportReason === "Criminal_post"}
              onChange={(e) => setReportReason(e.target.value)}
            />
            犯罪的な投稿
          </label>
          <label>
            <input
              type="radio"
              value="Abusive_post"
              checked={reportReason === "Abusive_post"}
              onChange={(e) => setReportReason(e.target.value)}
            />
            虐待的な投稿
          </label>
          <label>
            <input
              type="radio"
              value="Fraudulent_post"
              checked={reportReason === "Fraudulent_post"}
              onChange={(e) => setReportReason(e.target.value)}
            />
            詐欺的な投稿
          </label>
        </div>
        <textarea
          className={styles.modalTextarea}
          placeholder="任意:通報内容を記入(200文字以内)"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value.slice(0, 200))}
          style={{
            width: "100%",
            boxSizing: "border-box",
            minHeight: "150px",
          }} // 追加
        />

        <div className={styles.buttonContainer}>
          {" "}
          <button
            className={styles.sendReportButton}
            onClick={() => {
              handleReportSubmit(currentCommentId);
              closeModal(); // 追加
            }}
            disabled={!reportReason}
          >
            送信
          </button>
          <button onClick={closeModal}>キャンセル</button>
        </div>
      </Modal>
    </div>
  );
}

export default Chat;
