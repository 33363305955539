import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import { Auth, Storage, API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  userProfilesByOwner,
  getPublicProfilesByUserProfileId,
} from "../graphql/queries";
import { updateUserProfile } from "../graphql/mutations";

const ChangeAvatar = () => {
  const location = useLocation();
  const [fileData, setFileData] = useState(
    location.state ? location.state.file : null
  );
  const [objectUrl, setObjectUrl] = useState();
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    color: "#fff",
    fontSize: "24px",
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  async function fetchUserProfile() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const owner = user.attributes.sub;

      const fetchedUserData = await API.graphql({
        query: userProfilesByOwner,
        variables: { owner: owner, limit: 1 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      setUserData(fetchedUserData.data); // userDataを設定
    } catch (err) {
      console.error("Error fetching user profile", err);
    }
  }

  //Crop
  const [crop, setCrop] = useState({
    unit: "px", // 'px' または '%' にすることができます
    x: 0,
    y: 0,
    width: 300,
    height: 300,
  });

  //アップロードした画像のObjectUrlをステートに保存する
  useEffect(() => {
    if (fileData instanceof File) {
      objectUrl && URL.revokeObjectURL(objectUrl);

      // 画像をリサイズする処理を追加
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 300; // リサイズ後の幅
          canvas.height = 300; // リサイズ後の高さ
          ctx.drawImage(img, 0, 0, 300, 300);
          const resizedImageUrl = canvas.toDataURL("image/jpeg");
          setObjectUrl(resizedImageUrl);
        };
      };
      reader.readAsDataURL(fileData);
    } else {
      setObjectUrl(undefined);
    }
  }, [fileData]);

  // canvasで画像を扱うため
  // アップロードした画像のObjectUrlをもとに、imgのHTMLElementを作る
  const loadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
    });
  };

  const deleteExistingImages = async (imagePath) => {
    try {
      // 認証されたユーザーを取得
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/changeAvatar?imagePath=${imagePath}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error deleting images: ${response.status}`);
      }
    } catch (err) {
      console.error("Error deleting existing images:", err);
      return;
    }
  };

  // 切り取りと画像アップロードの統合
  const handleCropAndUpload = async () => {
    if (!isUploading) {
      setIsUploading(true);
      setIsOverlayVisible(true);
      try {
        if (objectUrl) {
          const canvas = document.createElement("canvas");
          canvas.width = 100;
          canvas.height = 100;

          const ctx = canvas.getContext("2d");
          ctx.beginPath();
          ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2,
            0,
            2 * Math.PI,
            false
          );
          ctx.clip();

          const img = await loadImage(objectUrl);
          ctx.drawImage(
            img,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            100,
            100
          );

          canvas.toBlob(async (result) => {
            if (result instanceof Blob) {
              try {
                // 既存の画像を削除
                await deleteExistingImages(
                  userData.userProfilesByOwner.items[0].profileImageUrl
                );

                const createdAt = new Date().toISOString(); // createdAtの取得
                const fileName = `avatar_${createdAt}.jpg`; // ファイル名の作成

                await Storage.put(
                  `profileImages/${userData.userProfilesByOwner.items[0].username}/${fileName}`,
                  result,
                  { contentType: "image/jpg" }
                );

                const updateData = {
                  id: userData.userProfilesByOwner.items[0].id,
                  profileImageUrl: `profileImages/${userData.userProfilesByOwner.items[0].username}/${fileName}`,
                };

                const updateUserProfileResult = await API.graphql({
                  query: updateUserProfile,
                  variables: { input: updateData },
                  authMode: "AMAZON_COGNITO_USER_POOLS",
                });

                const updatedUserProfileId =
                  updateUserProfileResult.data.updateUserProfile.id;

                const publicProfileResponse = await API.graphql({
                  query: getPublicProfilesByUserProfileId,
                  variables: { userProfileId: updatedUserProfileId },
                });

                const publicProfileArray =
                  publicProfileResponse.data.publicProfilesByUserProfileId
                    .items[0];

                /* const publicProfileData = {
                  id: publicProfileArray.id,
                  nickname: publicProfileArray.nickname,
                  userProvince: publicProfileArray.userProvince,
                  userCity: publicProfileArray.userCity,
                  bio: publicProfileArray.bio,
                  gender: publicProfileArray.gender,
                  profileImageUrl: `profileImages/${userData.userProfilesByOwner.items[0].username}/${fileName}`,
                }; */

                const publicProfileData = {
                  id: publicProfileArray.id,
                  profileImageUrl: `profileImages/${userData.userProfilesByOwner.items[0].username}/${fileName}`,
                };

                const currentUser = await Auth.currentAuthenticatedUser();
                const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

                const response = await fetch(
                  "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/changeAvatar",
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${jwtToken}`,
                      "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
                    },
                    body: JSON.stringify(publicProfileData),
                  }
                );

                if (!response.ok) {
                  throw new Error("Failed to update public profile");
                }

                toast.success("アバターを更新しました", { autoClose: 1200 });
                setTimeout(() => {
                  setIsUploading(false);
                  setIsOverlayVisible(false);
                  navigate("/mypage");
                }, 2500);
              } catch (error) {
                console.error("Error uploading image", error);
                setIsUploading(false);
                setIsOverlayVisible(false);
                toast.error("アバターを更新できませんでした", {
                  autoClose: 2000,
                });
                setIsOverlayVisible(false);
                setIsUploading(false);
              }
            } else {
              console.error(
                "Error uploading image: No cropped image available"
              );
              toast.error("アバターを更新できませんでした。", {
                autoClose: 2000,
              });
              setIsOverlayVisible(false);
              setIsUploading(false);
            }
          });
        }
      } catch (error) {
        console.error("Error uploading image", error);
        toast.error("アバターを更新できませんでした", { autoClose: 2000 });
        setIsOverlayVisible(false);
        setIsUploading(false);
      }
    }
  };

  return (
    <div>
      <h2>アバター設定</h2>
      {isOverlayVisible && <div style={overlayStyle}>アップロード中...</div>}
      <div>
        {objectUrl && (
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={1}
            circularCrop={true}
            keepSelection={true}
            minWidth={200}
            minHeight={200}
            maxWidth={300}
            maxHeight={300}
          >
            <img src={objectUrl} alt="" style={{ width: "100%" }} />
          </ReactCrop>
        )}
      </div>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCropAndUpload(); // 切り取りとアップロードの統合関数を呼び出す
          }}
        >
          <input
            type="file"
            onChange={(e) => {
              e.target.files && setFileData(e.target.files[0]);
            }}
          />
          <button type="submit" disabled={isUploading}>
            {isUploading ? "アップロード中..." : "アバターを更新"}
          </button>
          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default ChangeAvatar;
