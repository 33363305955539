import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJapaneseRegionName } from "../features/RegionSlice";
import { API, graphqlOperation } from "aws-amplify";
import { excludeOwnerListPosts } from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import SelectCategory from "./SelectCategory";
/* import CustomCarousel from "./CustomCarousel"; */
import { categories } from "../containers/CategoryList";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import "../styles/Home.css";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const japaneseRegionName = useSelector(
    (state) => state.region.japaneseRegionName
  );
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [posts, setPosts] = useState(null);
  const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("screen and (max-width: 752px)").matches
  );

  const [chunkSize, setChunkSize] = useState(6); // デフォルトのチャンクサイズを6に設定

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1200) {
        setChunkSize(6);
      } else if (width > 900) {
        setChunkSize(4);
      } else {
        setChunkSize(3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 初回実行

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = (e) => {
      setIsMobile(e.matches);
    };

    const mediaQuery = window.matchMedia("screen and (max-width: 752px)");
    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  // 初期値として「全国」をセット
  useEffect(() => {
    if (!japaneseRegionName) {
      dispatch(setJapaneseRegionName("全国"));
    }
  }, [dispatch]); // 依存配列から japaneseRegionName を削除する

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const getCategoryNameInJapanese = (path) => {
    const category = categories.find((category) => category.path === path);
    return category ? category.name : path;
  };

  const postsChunks = posts ? chunkArray(posts.items, chunkSize) : [];
  const imageUrlsChunks = postImageUrls
    ? chunkArray(postImageUrls, chunkSize)
    : [];

  const getJapaneseProvinceName = (region) => {
    if (!region) return "不明な地域";
    return japanProvinceMap[region.toLowerCase()] || "不明な地域";
  };

  const getJapaneseCityName = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(),
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key);
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key);
      return null;
    }
  };

  const fetchPostImages = async (post) => {
    const { postImageUrl1, updatedAt } = post;

    // もし postImageUrl1 が ../assets/news_sample.png なら、そのまま返す
    if (postImageUrl1 === "../assets/news_sample.png" || !postImageUrl1) {
      return require("../assets/news_sample.png");
    }

    const cachedData = getCachedImageUrl(postImageUrl1);

    if (cachedData) {
      const cachedUpdatedAt = new Date(cachedData.updatedAt);
      const postUpdatedAt = new Date(updatedAt);
      const diffInMinutes = (postUpdatedAt - cachedUpdatedAt) / (1000 * 60);

      if (diffInMinutes < 2) {
        return cachedData.url;
      }
    }

    try {
      // CloudfrontのURLに置き換える
      const imageUrl = `https://d1s9xshr26t6r2.cloudfront.net/public/${postImageUrl1}`;
      // S3オブジェクトの取得は不要になるため、この部分は削除
      setCachedImageUrl(postImageUrl1, imageUrl, null, updatedAt);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching post image:", error);
      return null;
    }
  };

  const handleImageClick = (postId, owner) => {
    navigate(`/contents/${postId}`, {
      state: { listing: { id: postId }, owner },
    });
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let filter = {
          postingStatus: { eq: "ACTIVE" },
        };

        if (japaneseRegionName && japaneseRegionName !== "全国") {
          // 日本語の地域名から英語のキーを取得
          const regionKey = Object.keys(japanProvinceMap).find(
            (key) => japanProvinceMap[key] === japaneseRegionName
          );

          if (regionKey) {
            filter.postProvince = { eq: regionKey };
          }
        }

        const postsData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, { filter, limit: 48 })
        );

        setPosts(postsData.data.listPosts);

        if (postsData.data.listPosts.items.length === 0) {
          setPostImageUrls([]);
          setIsFetchingCompleted(true);
          return;
        }

        const postImageUrls = postsData.data.listPosts.items.map(
          async (post) => {
            const imageUrl = await fetchPostImages(post);
            return imageUrl;
          }
        );

        setPostImageUrls(await Promise.all(postImageUrls));
        setIsFetchingCompleted(true);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setIsFetchingCompleted(true);
      }
    };

    fetchPosts();
  }, [japaneseRegionName, dispatch]);

  return (
    <div className="home-container">
      <div className="select-category-container">
        <SelectCategory />
      </div>

      {!isFetchingCompleted && (
        <div style={{ fontWeight: "bold", fontSize: "16px" }}>Loading...</div>
      )}

      {isFetchingCompleted && posts && postImageUrls && (
        <>
          {isFetchingCompleted && (!posts || posts.items.length === 0) && (
            <div>投稿がありません</div>
          )}
          {isMobile ? (
            <div className="posts-list">
              {posts.items.map((post, index) => (
                <div key={index} className="post-list-item">
                  <img
                    src={postImageUrls[index]}
                    alt={`Post ${index + 1}`}
                    onClick={() => handleImageClick(post.id, post.owner)}
                  />
                  <div style={{ textAlign: "center" }}>
                    <p onClick={() => handleImageClick(post.id, post.owner)}>
                      {post.title.length > 18
                        ? `${post.title.slice(0, 18)}...`
                        : post.title}
                    </p>
                    <div
                      style={{ margin: 0, fontSize: "12px" }}
                      className="location-category"
                    >
                      {getJapaneseProvinceName(post.postProvince)}{" "}
                      {getJapaneseCityName(post.postProvince, post.postCity)} /{" "}
                      {getCategoryNameInJapanese(post.category)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            postsChunks.map((postsChunk, chunkIndex) => (
              <div key={chunkIndex} className="posts-chunk">
                {imageUrlsChunks[chunkIndex] &&
                  imageUrlsChunks[chunkIndex].map((imageUrl, index) => (
                    <div key={`${chunkIndex}-${index}`} className="post-item">
                      <img
                        src={imageUrl}
                        alt={`Post ${chunkIndex * 7 + index + 1}`}
                        onClick={() =>
                          handleImageClick(
                            postsChunk[index].id,
                            postsChunk[index].owner
                          )
                        }
                      />
                      <div style={{ textAlign: "center", maxWidth: "150px" }}>
                        <p
                          onClick={() =>
                            handleImageClick(
                              postsChunk[index].id,
                              postsChunk[index].owner
                            )
                          }
                        >
                          {postsChunk[index].title.length > 18
                            ? `${postsChunk[index].title.slice(0, 18)}...`
                            : postsChunk[index].title}
                        </p>
                        <div
                          style={{ margin: 0, fontSize: "12px" }}
                          className="location-category"
                        >
                          {getJapaneseProvinceName(
                            postsChunk[index].postProvince
                          )}{" "}
                          {getJapaneseCityName(
                            postsChunk[index].postProvince,
                            postsChunk[index].postCity
                          )}
                          /{" "}
                          {getCategoryNameInJapanese(
                            postsChunk[index].category
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
}

export default Home;
