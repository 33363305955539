import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import {
  listPostsByTitleAndContent,
  userProfilesByOwner,
} from "../graphql/queries";
import { categories } from "../containers/CategoryList"; // カテゴリー情報をインポート
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import styles from "../styles/SearchResults.module.css"; // CSSモジュール

const SearchResults = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = decodeURIComponent(params.get("keyword")); // decodeURIComponentを追加
  const [previousKeyword, setPreviousKeyword] = useState("");
  const [results, setResults] = useState([]);
  const [allResults, setAllResults] = useState([]); // 全件数を保存するstate
  const [loading, setLoading] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(15); // 一度に表示する件数を15に設定
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // モバイル判定用のstate

  const japanProvinceMap = {
    hokkaido: "北海道",
    aomori: "青森県",
    iwate: "岩手県",
    miyagi: "宮城県",
    akita: "秋田県",
    yamagata: "山形県",
    fukushima: "福島県",
    ibaraki: "茨城県",
    tochigi: "栃木県",
    gunma: "群馬県",
    saitama: "埼玉県",
    chiba: "千葉県",
    tokyo: "東京都",
    kanagawa: "神奈川県",
    niigata: "新潟県",
    toyama: "富山県",
    ishikawa: "石川県",
    fukui: "福井県",
    yamanashi: "山梨県",
    nagano: "長野県",
    gifu: "岐阜県",
    shizuoka: "静岡県",
    aichi: "愛知県",
    mie: "三重県",
    shiga: "滋賀県",
    kyoto: "京都府",
    osaka: "大阪府",
    hyogo: "兵庫県",
    nara: "奈良県",
    wakayama: "和歌山県",
    tottori: "鳥取県",
    shimane: "島根県",
    okayama: "岡山県",
    hiroshima: "広島県",
    yamaguchi: "山口県",
    tokushima: "徳島県",
    kagawa: "香川県",
    ehime: "愛媛県",
    kochi: "高知県",
    fukuoka: "福岡県",
    saga: "佐賀県",
    nagasaki: "長崎県",
    kumamoto: "熊本県",
    oita: "大分県",
    miyazaki: "宮崎県",
    kagoshima: "鹿児島県",
    okinawa: "沖縄県",
    all: "全国",
  };

  const categoryMap = categories.reduce((map, category) => {
    map[category.path] = category.name;
    return map;
  }, {});

  const getJapaneseCity = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  // saveSearchHistory関数を追加
  const saveSearchHistory = async (searchTerms) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
      const userId = currentUser.attributes.sub;

      const userProfileData = await API.graphql({
        query: userProfilesByOwner,
        variables: { owner: userId, limit: 1 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const userProfile = userProfileData.data.userProfilesByOwner.items[0];

      // IPアドレスを取得する
      /* const ipResponse = await fetch("https://ipapi.co/json/");
      const ipData = await ipResponse.json();
      const ipAddr = ipData.ip; */

      const searchHistoryPayload = {
        searchWord: searchTerms, // 配列をスペースで結合して文字列にする
        userProfileId: userProfile.id, // ユーザープロファイルIDを設定
        userProvince: userProfile.userProvince, // ユーザー地域を設定
        gender: userProfile.gender, // 性別を設定
        birthdate: userProfile.birthdate, // 誕生日を設定
        owner: userId, // 所有者を設定
      };

      await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/searchHistory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
          },
          body: JSON.stringify(searchHistoryPayload),
        }
      );
    } catch (error) {
      // 未認証ユーザーのエラーであれば、何も出力しない
      if (error !== "The user is not authenticated") {
        console.error("Error saving search history:", error);
      }
    }
  };

  // キャッシュされた画像URLを取得する関数
  const getCachedImageUrl = (post) => {
    if (
      !post.postImageUrl1 ||
      post.postImageUrl1 === "../assets/news_sample.png"
    ) {
      // デフォルト画像を返す
      return require("../assets/news_sample.png");
    }

    const cachedData = localStorage.getItem(`imageUrl_${post.postImageUrl1}`);
    if (!cachedData) {
      return null;
    }

    try {
      const parsedData = JSON.parse(cachedData);
      const cacheTime = parsedData.cacheTime;
      const now = Date.now();
      const diffInMinutes = (now - cacheTime) / (1000 * 60);

      if (diffInMinutes > 144000) {
        // 144000分以上経過している場合はキャッシュを無効にする
        localStorage.removeItem(`imageUrl_${post.postImageUrl1}`);
        return null;
      }

      const cachedUpdatedAt = new Date(parsedData.updatedAt);
      const postUpdatedAt = new Date(post.updatedAt);
      const diffInPostMinutes = (postUpdatedAt - cachedUpdatedAt) / (1000 * 60);

      if (diffInPostMinutes < 2) {
        // 投稿の更新が2分以内であればキャッシュを使用
        return parsedData.url;
      }

      return null;
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(`imageUrl_${post.postImageUrl1}`);
      return null;
    }
  };

  // キャッシュされた画像URLを設定する関数
  const setCachedImageUrl = (post, url) => {
    if (
      !post.postImageUrl1 ||
      post.postImageUrl1 === "../assets/news_sample.png"
    ) {
      return; // デフォルト画像の場合はキャッシュしない
    }

    const cachedData = {
      url,
      updatedAt: post.updatedAt,
      cacheTime: Date.now(),
    };
    localStorage.setItem(
      `imageUrl_${post.postImageUrl1}`,
      JSON.stringify(cachedData)
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      const savedKeyword = localStorage.getItem("searchQuery") || keyword;
      const storedPreviousKeyword =
        localStorage.getItem("previousKeyword") || "";

      if (!savedKeyword || savedKeyword.length < 2) {
        alert("検索語は２文字以上である必要があります");
        return;
      }

      const searchTerms = savedKeyword.split(/[\s　]+/);
      const invalidTerms = searchTerms.filter((term) => term.length < 2);
      if (invalidTerms.length > 0) {
        alert("各検索語は２文字以上である必要があります。");
        return;
      }

      setLoading(true);
      try {
        const filter = {
          and: [
            {
              or: searchTerms.map((term) => ({
                or: [
                  { title: { contains: term } },
                  { content: { contains: term } },
                ],
              })),
            },
            { postingStatus: { eq: "ACTIVE" } },
          ],
        };

        const result = await API.graphql({
          query: listPostsByTitleAndContent,
          variables: {
            filter: filter,
          },
        });

        const mergedResults = result.data.listPosts.items || [];

        const resultsWithImages = await Promise.all(
          mergedResults.map(async (post) => {
            const cachedImageUrl = getCachedImageUrl(post);
            if (cachedImageUrl) {
              return { ...post, imageUrl: cachedImageUrl };
            }

            const imageUrl = post.postImageUrl1
              ? `https://d1s9xshr26t6r2.cloudfront.net/public/${post.postImageUrl1}`
              : null;

            if (imageUrl) {
              setCachedImageUrl(post, imageUrl);
            }

            return { ...post, imageUrl };
          })
        );

        // スコアを計算し、スコアに基づいてソート
        const scoredResults = resultsWithImages.map((post) => {
          const score = searchTerms.reduce((acc, term) => {
            const titleMatches = (
              post.title.match(new RegExp(term, "gi")) || []
            ).length;
            const contentMatches = (
              post.content.match(new RegExp(term, "gi")) || []
            ).length;
            // タイトル、コンテンツの一致回数に2倍の重みを与える
            return acc + titleMatches * 2 + contentMatches * 1;
          }, 0);
          return { ...post, score };
        });

        const sortedResults = scoredResults.sort((a, b) => b.score - a.score);

        setAllResults(sortedResults); // 全件数をスコア順に保存
        setResults(sortedResults.slice(0, itemsToShow)); // 最初のページ分を設定

        setLoading(false);

        if (savedKeyword !== storedPreviousKeyword) {
          localStorage.setItem("previousKeyword", savedKeyword);
          saveSearchHistory(searchTerms).catch((error) => {
            console.error("Error saving search history:", error);
          });
        }
      } catch (error) {
        console.error("Error fetching posts", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [keyword, previousKeyword]);

  const loadMoreResults = async () => {
    const nextItems = allResults.slice(
      results.length,
      results.length + itemsToShow
    ); // itemsToShow 件数ずつ読み込む
    const resultsWithImages = await Promise.all(
      nextItems.map(async (post) => {
        const cachedImageUrl = getCachedImageUrl(post);
        if (cachedImageUrl) {
          return { ...post, imageUrl: cachedImageUrl };
        }

        const imageUrl = post.postImageUrl1
          ? `https://d1s9xshr26t6r2.cloudfront.net/public/${post.postImageUrl1}`
          : null;

        if (imageUrl) {
          setCachedImageUrl(post, imageUrl);
        }

        return { ...post, imageUrl };
      })
    );

    setResults((prevResults) => [...prevResults, ...resultsWithImages]);
  };

  return (
    <div>
      <h2>検索結果: {keyword}</h2>
      {loading ? (
        <p>検索中...</p>
      ) : results.length > 0 ? (
        <>
          <ul className={styles.searchResultsList}>
            {results.map((post) => (
              <div key={post.id} className={styles.listItem}>
                <Link to={`/contents/${post.id}`}>
                  {post.imageUrl && (
                    <img
                      src={post.imageUrl}
                      alt={post.title}
                      className={styles.searchImage}
                    />
                  )}
                </Link>
                <div className={styles.searchContent}>
                  <Link
                    to={`/contents/${post.id}`}
                    className={styles.searchTitle}
                  >
                    <h4>
                      {post.title.length > (isMobile ? 22 : 35)
                        ? `${post.title.slice(0, isMobile ? 22 : 35)}...`
                        : post.title}
                    </h4>
                  </Link>
                  <div
                    style={{
                      fontSize: "0.9em",
                      color: "gray",
                    }}
                  >
                    {japanProvinceMap[post.postProvince] ||
                      `不明 (${post.postProvince})`}{" "}
                    {getJapaneseCity(post.postProvince, post.postCity)}/{" "}
                    {categoryMap[post.category] || `不明 (${post.category})`}
                  </div>
                  <p style={{ marginTop: "5px" }}>
                    {post.content.length > (isMobile ? 33 : 80)
                      ? `${post.content.slice(0, isMobile ? 33 : 80)}...`
                      : post.content}
                  </p>
                </div>
                <div className={styles.dateInfo}>
                  <p>
                    投稿日:
                    <br />{" "}
                    {new Date(post.createdAt).toLocaleDateString(undefined, {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </p>
                  <p>
                    更新日: <br />
                    {new Date(post.updatedAt).toLocaleDateString(undefined, {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </p>
                </div>
              </div>
            ))}
          </ul>

          {results.length < allResults.length && (
            <h4 onClick={loadMoreResults} className={styles.loadMoreButton}>
              ▼さらに読み込む{" "}
            </h4>
          )}
        </>
      ) : (
        <p>検索結果がありません。</p>
      )}
    </div>
  );
};

export default SearchResults;
