// src/components/Disclaimer.js
import React from "react";

function Disclaimer() {
  const disclaimerStyle = {
    width: "80%",
    margin: "0 auto",
    textAlign: "left",
  };

  return (
    <div style={disclaimerStyle}>
      <h2>免責事項</h2>
      <p>
        1.
        本サービス「カコミュ」（以下、「本サービス」といいます。）は、合同会社アニトヤ（以下、「当社」といいます。）によって提供されています。本サービスの利用にあたり、ユーザーは以下の免責事項に同意するものとします。
      </p>
      <p>
        2.
        当社は、本サービスの利用に際して発生するいかなる損害についても、その責任を負いません。ユーザーが本サービス内で投稿、チャット、コメント、ダイレクトメッセージ等を行った内容について、当社は一切の責任を負いません。
      </p>
      <p>
        3.
        当社は、本サービスの運営において、予告なしにサービスの内容を変更、運営を中断、または終了することがあります。これによりユーザーに生じた損害について、当社は一切の責任を負いません。
      </p>
      <p>
        4.
        本サービスの利用に関連して発生した損害について、当社は一切の責任を負いません。これには、データの損失、通信障害、その他の技術的な問題が含まれます。
      </p>
      <p>
        5.
        当社は、本サービスの利用によってユーザーに生じた損害や損失について、一切の責任を負いません。これには、ユーザーの期待に反する結果が生じた場合も含まれます。
      </p>
      <p>
        6.
        当社は、ユーザーが本サービスを利用することによって得た情報の信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について保証しません。ユーザーは自己の責任において情報を利用するものとします。
      </p>
      <p>
        7.
        当社は、本サービスの利用に際して、規約の変更、サービスの中断、終了などに関して、ユーザーが被った損害や損失について一切の責任を負いません。
      </p>
      <p>
        8.
        当社は、投稿の掲示時期、削除、誤配、保存・バックアップの有無について責任を負いません。ユーザーは自己の責任においてデータの管理を行うものとします。
      </p>
      <p>
        9.
        当社がユーザーに対して損害賠償責任を負う場合、その責任は直接かつ通常の損害に限られ、損害発生時点から過去1ヶ月間にユーザーから受領した有料オプション利用料の総額を上限とします。
      </p>
      <p>
        10.
        当社は、ユーザーが使用する機器やソフトウェアの動作について保証いたしません。また、これに関連して生じた損害についても一切の責任を負いません。
      </p>
      <p>
        11.
        本サービス利用時に発生する接続費用は、ユーザーの自己責任で管理されるものであり、当社は一切の保証を行いません。
      </p>
      <p>
        12.
        ユーザーは、本サービスの利用が自身に適用される法令や業界団体の内部規則に違反しないかを自己責任で確認するものとし、当社はその適合性を保証しません。
      </p>
      <p>
        13.
        当社は、ユーザーが本サービスの利用によって他のユーザーや第三者に損害を与えた場合、その責任を負いません。
      </p>
      <p>
        14.
        当社は、本サービスから他のウェブサイトへのリンクや他のウェブサイトから本サービスへのリンクが提供されている場合、そのウェブサイトや情報に関して一切の責任を負いません。
      </p>
      <p>
        15.
        当社は、ユーザーが本サービスの投稿やチャットやコメントやダイレクトメッセージ等の内容の信頼性、真実性、正確性、妥当性、適法性、完全性、品質、信憑性、最新性、有用性、第三者の権利を侵害していないことその他一切の性質について何ら保証するものではありません。また本サービスの利用で知り得た情報や内容からEメールや電話番号、WebサイトのURL、他のウェブサイトへのリンクその他の情報に連絡やアクセス、面会等をして発生した損害に対して、一切の責任を負わないものとします。
      </p>

      <p>（2024年10月1日制定）</p>
    </div>
  );
}

export default Disclaimer;
