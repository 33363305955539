import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setJapaneseRegionName, setPrefecture } from "../features/RegionSlice";
import { API, graphqlOperation } from "aws-amplify";
import { excludeOwnerListPosts } from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import SelectCategory from "./SelectCategory";
import { categories } from "../containers/CategoryList";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import "../styles/Home.css";

function Region() {
  const navigate = useNavigate();
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);
  const [allPosts, setAllPosts] = useState([]); // 全投稿データを保存
  const [displayedPosts, setDisplayedPosts] = useState([]); // 表示する投稿データ
  const [currentPage, setCurrentPage] = useState(0); // 現在のページ
  const [chunkSize, setChunkSize] = useState(6); // デフォルトのチャンクサイズを6に設定

  const dispatch = useDispatch();
  const regionPrefecture = useParams().prefecture;

  const loadMorePosts = useCallback(async () => {
    const nextPage = currentPage + 1;
    const startIndex = nextPage * 12;
    const endIndex = startIndex + 12;
    const newPosts = allPosts.slice(startIndex, endIndex);

    // 新しい投稿の画像URLを取得
    const newImageUrls = await Promise.all(
      newPosts.map((post) => fetchPostImages(post))
    );

    setDisplayedPosts((prevPosts) => [...prevPosts, ...newPosts]);
    setPostImageUrls((prevUrls) => [...prevUrls, ...newImageUrls]);
    setCurrentPage(nextPage);
  }, [currentPage, allPosts]);

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const getCategoryNameInJapanese = (path) => {
    const category = categories.find((category) => category.path === path);
    return category ? category.name : path;
  };

  let { prefecture: regionName } = useParams();
  let { prefecture } = useParams();

  const japaneseRegionName =
    (regionName && japanProvinceMap[regionName.toLowerCase()]) || "不明な地域";

  const getJapaneseProvinceName = (region) => {
    if (!region) return "不明な地域";
    return japanProvinceMap[region.toLowerCase()] || "不明な地域";
  };

  const getJapaneseCityName = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  useEffect(() => {
    dispatch(setJapaneseRegionName(japaneseRegionName));
  }, [japaneseRegionName, dispatch]);

  useEffect(() => {
    dispatch(setPrefecture(prefecture));
  }, [dispatch, prefecture]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1200) {
        setChunkSize(6);
      } else if (width > 900) {
        setChunkSize(5);
      } else if (width > 752) {
        setChunkSize(4);
      } else {
        setChunkSize(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 初回実行

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(), // キャッシュした時間を保存
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      // parsedDataがオブジェクトであり、必要なプロパティが存在するかをチェック
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          // 144000分以上経過している場合は無効にする
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key); // 有効でないデータを削除
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key); // 有効でないデータを削除
      return null;
    }
  };

  const fetchPostImages = async (post) => {
    const { postImageUrl1, updatedAt } = post;

    // もし postImageUrl1 が ../assets/news_sample.png なら、そのまま返す
    if (postImageUrl1 === "../assets/news_sample.png" || !postImageUrl1) {
      return require("../assets/news_sample.png");
    }

    const cachedData = getCachedImageUrl(postImageUrl1);

    if (cachedData) {
      const cachedUpdatedAt = new Date(cachedData.updatedAt);
      const postUpdatedAt = new Date(updatedAt);
      const diffInMinutes = (postUpdatedAt - cachedUpdatedAt) / (1000 * 60);

      if (diffInMinutes < 2) {
        return cachedData.url;
      }
    }

    try {
      // CloudFrontの基本URL
      const cloudFrontBaseUrl = "https://d1s9xshr26t6r2.cloudfront.net";
      // 画像のキーをCloudFront URLに変換
      const imageUrl = `${cloudFrontBaseUrl}/public/${postImageUrl1}`;

      // CloudFront URLをキャッシュに保存
      setCachedImageUrl(postImageUrl1, imageUrl, updatedAt, updatedAt);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching post image:", error);
      return null;
    }
  };

  const handleImageClick = (postId, owner) => {
    navigate(`/contents/${postId}`, {
      state: { listing: { id: postId }, owner },
    });
  };

  // useEffect内のfetchPosts関数の修正
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let filter = {
          postingStatus: { eq: "ACTIVE" },
        };

        if (regionPrefecture !== "all") {
          filter.postProvince = { eq: regionPrefecture };
        }

        const postsData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, { filter, limit: 1000 })
        );

        const allPostsData = postsData.data.listPosts.items;
        setAllPosts(allPostsData); // 全投稿データを保存

        // 初回読み込み分
        const initialPosts = allPostsData.slice(0, 30);
        setDisplayedPosts(initialPosts);

        // 初回の画像取得
        const initialImageUrls = await Promise.all(
          initialPosts.map((post) => fetchPostImages(post))
        );
        setPostImageUrls(initialImageUrls);

        setIsFetchingCompleted(true);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setIsFetchingCompleted(true);
      }
    };

    fetchPosts();
  }, [regionPrefecture, dispatch]);

  return (
    <div className="home-container">
      <div className="select-category-container">
        <SelectCategory />
      </div>

      {!isFetchingCompleted && (
        <div style={{ fontWeight: "bold", fontSize: "16px" }}>Loading...</div>
      )}

      {isFetchingCompleted && displayedPosts && postImageUrls && (
        <>
          {isFetchingCompleted &&
            (!displayedPosts || displayedPosts.length === 0) && (
              <div>投稿がありません</div>
            )}
          {chunkArray(displayedPosts, chunkSize).map(
            (postChunk, chunkIndex) => (
              <div key={chunkIndex} className="posts-chunk">
                {postChunk.map((post, index) => (
                  <div key={index} className="post-item">
                    <img
                      src={postImageUrls[chunkIndex * chunkSize + index]}
                      alt={`Post ${chunkIndex * chunkSize + index + 1}`}
                      onClick={() => handleImageClick(post.id, post.owner)}
                      className="post-image"
                    />
                    <div className="post-details">
                      <p
                        className="post-title"
                        onClick={() => handleImageClick(post.id, post.owner)}
                      >
                        {post.title.length > 18
                          ? `${post.title.slice(0, 18)}...`
                          : post.title}
                      </p>
                      <div className="post-location-category">
                        {getJapaneseProvinceName(post.postProvince)}{" "}
                        {getJapaneseCityName(post.postProvince, post.postCity)}{" "}
                        / {getCategoryNameInJapanese(post.category)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          )}

          {displayedPosts.length < allPosts.length && (
            <h4 onClick={loadMorePosts} className="load-more">
              ▼さらに読み込む
            </h4>
          )}
        </>
      )}
    </div>
  );
}

export default Region;
