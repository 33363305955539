// store/userProfileSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nickname: null,
  profileid: null,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setNickname: (state, action) => {
      state.nickname = action.payload;
    },
    setUserProfileId: (state, action) => {
      state.profileid = action.payload;
    },
  },
});

export const { setNickname, setUserProfileId } = userProfileSlice.actions;
export default userProfileSlice.reducer;
