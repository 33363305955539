// BannedWordsList.js
const bannedWords = [
  "死な",
  "死に",
  "死ぬ",
  "死ね",
  "死ナ",
  "死ニ",
  "死ヌ",
  "死ネ",
  "死ン",
  "死ヌ",
  "死ネ",
  "死んじ",
  "死んだ",
  "死んぢ",
  "死んで",
  "死んど",
  "しねよ",
  "しねば",
  "しねしね",
  "しんで",
  "しんじま",
  "しんじゃ",
  "タヒな",
  "タヒに",
  "タヒぬ",
  "タヒね",
  "タヒの",
  "タヒは",
  "タヒふ",
  "タヒほ",
  "タヒら",
  "タヒる",
  "タヒれ",
  "タヒろ",
  "タヒを",
  "タヒん",
  "消えろ",
  "きえろ",
  "消えれ",
  "きえれ",
  "消え去れ",
  "消えされ",
  "消え失せ",
  "消えうせ",
  "きえうせ",
  "滅びろ",
  "ほろびろ",
  "ちんこ",
  "チンコ",
  "ちんちん",
  "チンチン",
  "ちんぽ",
  "チンポ",
  "ちんぽこ",
  "ぽこちん",
  "男根",
  "巨根",
  "粗ちん",
  "粗チン",
  "おめこ",
  "クリトリ",
  "アヘ顔",
  "○こ",
  "*こ",
  "○コ",
  "*コ",
  "ンカス",
  "陰毛",
  "ちん毛",
  "チン毛",
  "まんこ",
  "マンコ",
  "まん臭",
  "マン臭",
  "まん毛",
  "マン毛",
  "まん穴",
  "マン穴",
  "アナル",
  "あなる",
  "あんなる",
  "尻穴",
  "尻あな",
  "尻アナ",
  "尻けつ",
  "尻ケツ",
  "ケツ毛",
  "パイパン",
  "ぱいぱん",
  "おっぱぶ",
  "おっぱい",
  "ぱいおつ",
  "パイオツ",
  "ぱいぱい",
  "パイパイ",
  "オッパイ",
  "ヌード",
  "ファック",
  "ファッキ",
  "ふぁっく",
  "ふぁっき",
  "セフレ",
  "せふれ",
  "男娼",
  "淫乱",
  "尻軽",
  "あばずれ",
  "アバズレ",
  "ヤリチン",
  "ヤリちん",
  "やりちん",
  "ヤリマン",
  "ヤリまん",
  "やりまん",
  "ディープキス",
  "肉便器",
  "乳首",
  "乳輪",
  "愛撫",
  "我慢汁",
  "早漏",
  "遅漏",
  "愛液",
  "ペッティング",
  "マスターベーション",
  "手コキ",
  "てこき",
  "手マン",
  "てマン",
  "パイズリ",
  "ぱいずり",
  "素股",
  "はぷば",
  "ハプバ",
  "ハプニングバ",
  "セックス",
  "せっくす",
  "エスイーエックス",
  "えすいーえっくす",
  "イラマチ",
  "輪姦",
  "青姦",
  "ラブホ",
  "ダッチワイフ",
  "ラブドール",
  "セクロス",
  "せくろす",
  "えっち",
  "エッチ",
  "オナニ",
  "オナる",
  "オナ禁",
  "おなに",
  "おなる",
  "オナホ",
  "おなほ",
  "イカ臭",
  "しこしこ",
  "シコる",
  "シコシコ",
  "フェラ",
  "フ◯ラ",
  "ふぇら",
  "性交",
  "性行為",
  "射精",
  "ザーメン",
  "ｻﾞｰﾒﾝ",
  "小便",
  "しょんべん",
  "しょうべん",
  "ションベン",
  "ショウベン",
  "拷問",
  "勃起",
  "しっこ",
  "シッコ",
  "SMク",
  "SMく",
  "エスエム",
  "女王様",
  "うんこ",
  "うんち",
  "ウンチ",
  "ウンコ",
  "脱糞",
  "放尿",
  "お前",
  "オマエ",
  "オマェ",
  "おまえ",
  "おまぇ",
  "オ前",
  "おめえ",
  "おめぇ",
  "オメエ",
  "オメェ",
  "おめー",
  "オメー",
  "童貞",
  "どうてい",
  "筆おろし",
  "ふでおろし",
  "筆下ろし",
  "処女",
  "しょじょ",
  "このやろう",
  "この野郎",
  "コノヤロウ",
  "エロ",
  "クソ",
  "バカ",
  "バーカ",
  "ばか",
  "ばーか",
  "アホ",
  "あほ",
  "阿保",
  "阿呆",
  "クズ",
  "ブス",
  "デブ",
  "でぶ",
  "ハゲ",
  "はげ",
  "ガイジ",
  "キチガイ",
  "つんぼ",
  "めくら",
  "ちきがい",
  "白痴",
  "痴女",
  "くろんぼ",
  "土人",
  "ばかちん",
  "うざい",
  "うぜえ",
  "うぜぇ",
  "ウゼえ",
  "ウゼぇ",
  "うざす",
  "ウザい",
  "ウザす",
  "ウザっ",
  "うざっ",
  "うっざ",
  "ウッザ",
  "キモオ",
  "キモい",
  "キモイ",
  "キモス",
  "キモ客",
  "キモ投",
  "きもい",
  "きめえ",
  "きめぇ",
  "キメえ",
  "キメェ",
  "きもす",
  "きっも",
  "キッモ",
  "きっしょ",
  "キッショ",
  "きしょす",
  "きしょい",
  "キショい",
  "ハメ撮り",
  "ハメドリ",
  "飲尿",
  "スケベ",
  "SUKEBE",
  "Sukebe",
  "sukebe",
  "我慢汁",
  "変態",
  "へんたい",
  "ヘンタイ",
  "HENTAI",
  "hentai",
  "Hentai",
  "FC2",
  "fc2",
  "Fc2",
  "fC2",
  "レイプ",
  "スカトロ",
  "すかとろ",
  "れいぷ",
  "レイパー",
  "れいぱー",
  "オトカン",
  "風俗",
  "ソープ嬢",
  "ソプ嬢",
  "そーぷ",
  "デリヘル",
  "デリバリーヘルス",
  "パネマジ",
  "口内発射",
  "顔射",
  "箱ヘル",
  "ホテヘル",
  "ピンサロ",
  "立ちんぼ",
  "たちんぼ",
  "勃ち",
  "テメー",
  "テメエ",
  "テメェ",
  "てめー",
  "てめえ",
  "てめぇ",
  "姫予約",
  "汁男",
  "乞食",
  "大久保公園",
  "ホテル別",
  "ホ別",
  "ホベツ",
  "ほ別",
  "ほべつ",
  "中出し",
  "中だし",
  "裏ビデオ",
  "鉄梨",
  "G着",
  "Ｇ着",
  "g着",
  "s着",
  "S着",
  "Ｓ着",
  "強姦",
  "痴漢",
  "盗撮",
  "糞",
  "馬鹿",
  "買春",
  "売春",
  "パパ活",
  "ママ活",
  "P活",
  "Ｐ活",
  "阿呆",
  "浣腸",
  "シックスナイン",
  "正常位",
  "まんぐり",
  "クンニ",
  "娼婦",
  "街婦",
  "巨乳",
  "爆乳",
  "くたばれ",
  "殺",
  "ぶっころ",
  "刺す",
  "殴る",
  "シバい",
  "シバか",
  "シバき",
  "シバく",
  "シバけ",
  "シバこ",
  "シバイ",
  "シバカ",
  "シバキ",
  "シバク",
  "シバケ",
  "シバコ",
  "しばい",
  "しばか",
  "しばき",
  "しばく",
  "しばけ",
  "しばこ",
  "自さつ",
  "ころされ",
  "ころした",
  "ころして",
  "ころしと",
  "ころす",
  "ころせ",
  "ころそ",
  "コロされ",
  "コロした",
  "コロして",
  "コロしと",
  "コロす",
  "コロせ",
  "ころそ",
  "コロシタ",
  "コロシテ",
  "コロサレ",
  "コロス",
  "コロセ",
  "ｺﾛした",
  "ｺﾛして",
  "ｺﾛされ",
  "ｺﾛす",
  "ｺﾛせ",
  "ｺﾛｼﾀ",
  "ｺﾛｼﾃ",
  "ｺﾛｻﾚ",
  "ｺﾛｽ",
  "ｺﾛｾ",
  "ｺﾛｼﾀ",
  "ｺﾛｼﾃ",
  "ｺﾛｻﾚ",
  "ｺﾛｽ",
  "ｺﾛｾ",
  "やり捨て",
  "痴漢",
  "盗撮",
  "児童ポルノ",
  "児ポル",
  "ディルド",
  "ボケ",
  "でぶす",
  "くそ",
  "ﾁﾝｺ",
  "ﾁﾝﾁﾝ",
  "ﾁﾝﾎﾟ",
  "ﾁﾝｶｽ",
  "ﾏﾝｺ",
  "ﾏﾝ穴",
  "尻ｱﾅ",
  "尻ｹﾂ",
  "ｹﾂ毛",
  "ﾏﾝｶｽ",
  "ﾊﾟｲﾊﾟﾝ",
  "ｵｯﾊﾟｲ",
  "ﾌｧｯｸ",
  "ﾌｧｯｷｭ",
  "ｾﾌﾚ",
  "ｱﾊﾞｽﾞﾚ",
  "ﾔﾘﾁﾝ",
  "ﾔﾘﾏﾝ",
  "ｸﾘﾄﾘ",
  "ﾍﾟｯﾃｨﾝｸﾞ",
  "ﾏｽﾀｰﾍﾞｰｼｮﾝ",
  "手ｺｷ",
  "手ﾏﾝ",
  "ﾊﾟｲｽﾞﾘ",
  "ﾊﾌﾟﾆﾝｸﾞﾊﾞ",
  "ｾｯｸｽ",
  "ｲﾗﾏﾁ",
  "ﾗﾌﾞﾎ",
  "ﾀﾞｯﾁﾜｲﾌ",
  "ﾗﾌﾞﾄﾞｰﾙ",
  "ｾｸﾛｽ",
  "ｴｯﾁ",
  "ｱﾅﾙ",
  "ｵﾅﾆ",
  "ｵﾅる",
  "ｼｺる",
  "ｼｺｼｺ",
  "ﾌｪﾗ",
  "ｼｯｺ",
  "SMｸ",
  "SMく",
  "ｴｽｴﾑ",
  "ｳﾝﾁ",
  "ｳﾝｺ",
  "ｴﾛ",
  "ｸｿ",
  "ﾊﾞｶ",
  "ｱﾎ",
  "ｸｽﾞ",
  "ｺﾞﾐ",
  "ｶｽ",
  "ﾀﾞﾎﾞ",
  "ﾌﾞｽ",
  "ﾃﾞﾌﾞ",
  "ﾊｹﾞ",
  "ｶﾞｲｼﾞ",
  "ｷﾁｶﾞｲ",
  "くろんぼ",
  "ﾊﾒ撮り",
  "ｸﾞﾛ",
  "ﾚｲﾌﾟ",
  "ｽｶﾄﾛ",
  "ﾅﾁｽ",
  "ﾋﾄﾗｰ",
  "ﾚｲﾊﾟｰ",
  "ﾌｳｿﾞｸ",
  "ﾄﾙｺ風呂",
  "ｿｰﾌﾟ",
  "ﾃﾞﾘﾍﾙ",
  "ﾃﾞﾘﾊﾞﾘｰﾍﾙｽ",
  "箱ﾍﾙ",
  "ﾎﾃﾍﾙ",
  "ｷｬﾊﾞ",
  "ﾋﾟﾝｻﾛ",
  "ﾃﾒー",
  "ﾃﾒｴ",
  "ﾃﾒｪ",
  "ﾎ別",
  "ﾎﾍﾞﾂ",
  "ｺﾞﾑ有",
  "ｺﾞﾑ無",
  "ｼｯｸｽﾅｲﾝ",
  "ｸﾝﾆ",
  "SEX",
  "sex",
  "Sex",
  "SEx",
  "SeX",
  "sEX",
  "sEx",
  "seX",
  "FUCK",
  "Fuck",
  "fuck",
  "anal",
  "fucker",
  "Rape",
  "RAPE",
  "rape",
  "ass",
  "Ass",
  "ASS",
  "kill",
  "Kill",
  "KILL",
];

export default bannedWords;
