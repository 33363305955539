import React from "react";
import SelectCategory from "./SelectCategory"; // SelectCategoryをインポート
import "../styles/SelectCategoryInfo.css"; // CSSファイルをインポート

function SelectCategoryInfo() {
  return (
    <div className="info-container">
      <h2>カテゴリーを選択してください</h2>
      <SelectCategory />
    </div>
  );
}

export default SelectCategoryInfo;
