import { createSlice } from '@reduxjs/toolkit';

export const regionSlice = createSlice({
  name: 'region',
  initialState: {
    japaneseRegionName: '全国',
    // `prefecture` の初期状態を定義
    prefecture: 'all',
  },
  // reducerの定義
  reducers: {
    setJapaneseRegionName: (state, action) => {
      state.japaneseRegionName = action.payload;
    },   
    setPrefecture: (state, action) => {
      state.prefecture = action.payload;
    },
  },
});

// アクションクリエーターのエクスポート
export const { setJapaneseRegionName, setPrefecture } = regionSlice.actions;

// reducerのエクスポート
export default regionSlice.reducer;
