// src/pages/AuthPage.js
import React, { useEffect, useContext, useState } from "react";
import {
  withAuthenticator,
  View,
  Image,
  Text,
  Button,
  Heading,
  useTheme,
  Authenticator,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Auth, API } from "aws-amplify";
import { userProfilesByOwner } from "../graphql/queries";
import { setLoggedIn } from "../features/AuthSlice";
import "@aws-amplify/ui-react/styles.css";
import { ProfileSetupModalContext } from "../App";

const Footer = ({ previousPath }) => {
  const { tokens } = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    if (previousPath) {
      navigate(previousPath, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Button
        variation="link"
        onClick={handleBack}
        style={{
          marginTop: tokens.space.small,
          color: "blue",
          textShadow: "0 0 8px white",
        }}
      >
        Back
      </Button>
      <Text color={tokens.colors.neutral[80]}>
        ©2024 Anitya All Rights Reserved
      </Text>
    </View>
  );
};

function AuthPage({ signOut, user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsProfileSetupModalOpen } = useContext(ProfileSetupModalContext);
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    // 前のパスを記録
    setPreviousPath(location.state?.from || "/");

    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        dispatch(setLoggedIn(true));

        const authUser = await Auth.currentAuthenticatedUser();
        const userProfileData = await API.graphql({
          query: userProfilesByOwner,
          variables: { owner: authUser.attributes.sub, limit: 1 },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        if (
          !userProfileData.data.userProfilesByOwner.items[0].userProvince ||
          !userProfileData.data.userProfilesByOwner.items[0].birthdate
        ) {
          await setIsProfileSetupModalOpen(true);
        }

        const redirectPath = new URLSearchParams(location.search).get(
          "redirect"
        );
        if (redirectPath) {
          navigate(redirectPath, { replace: true });
        } else {
          navigate("/mypage", { replace: true });
        }
      } catch (error) {
        dispatch(setLoggedIn(false));
        console.error("Error");
      }
    };

    checkAuthState();
  }, [dispatch, navigate, location, setIsProfileSetupModalOpen]);

  return <Footer previousPath={previousPath} />;
}

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src={require("../assets/logo192.png")}
          style={{ width: 100, height: 100 }}
        />
      </View>
    );
  },

  Footer({ previousPath }) {
    const { tokens } = useTheme();
    const navigate = useNavigate();

    const handleBack = () => {
      if (previousPath) {
        navigate(previousPath, { replace: true });
      } else {
        navigate(-1);
      }
    };

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Button
          variation="link"
          onClick={handleBack}
          style={{
            marginTop: tokens.space.small,
            color: "blue",
            textShadow: "0 0 8px white",
          }}
        >
          Back
        </Button>
        <Text color={tokens.colors.neutral[80]}>
          ©2024 Anitya All Rights Reserved
        </Text>
      </View>
    );
  },

  SignUp: {
    Header() {
      return (
        <Heading level={5} style={{ marginBottom: "-15px", marginTop: "15px" }}>
          アカウントを作成する
        </Heading>
      );
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();
      return (
        <>
          <Authenticator.SignUp.FormFields />
          <Text color="green">
            パスワードは8文字以上で大文字、小文字、数字を
            <br />
            各1文字以上含めてください
          </Text>
          <CheckboxField
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label={
              <span>
                <a
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "" }}
                >
                  利用規約
                </a>
                、
                <a
                  href="/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "" }}
                >
                  プライバシーポリシー
                </a>
                に同意する
              </span>
            }
          />
        </>
      );
    },
  },
};

const validateCustomSignUp = async (formData) => {
  if (!formData.acknowledgement) {
    return {
      acknowledgement: "You must agree to the Terms and Conditions",
    };
  }
};

export default withAuthenticator(AuthPage, {
  components,
  services: { validateCustomSignUp },
});
