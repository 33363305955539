import React, { useState, useEffect, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  getPublicProfilesByUserProfileId,
  excludeOwnerListPosts,
} from "../graphql/queries";
import { useParams, Link } from "react-router-dom";
import { genderMap } from "../containers/GenderList";
import { categories } from "../containers/CategoryList";
import { japanProvinceMap } from "../containers/JapanProvinceList";
import { japanCitiesMap } from "../containers/JapanCitiesMap";
import styles from "../styles/PublicMyPage.module.css";

const PublicMyPage = () => {
  const { id } = useParams();
  const [publicProfile, setPublicProfile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [posts, setPosts] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [imageUrls, setImageUrls] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const POSTS_PER_PAGE = 5;

  const formatBio = (bio) => {
    if (!bio) return "";
    return bio.replace(/(.{40})/g, "$1\n");
  };

  /* useEffect(() => {
    const fetchPublicProfile = async () => {
      try {
        setIsLoading(true);
        const publicProfileData = await API.graphql(
          graphqlOperation(getPublicProfilesByUserProfileId, {
            nextToken,
            userProfileId: id,
            limit: 1,
          })
        );
        const publicProfile = await publicProfileData.data
          .publicProfilesByUserProfileId.items[0];
        setPublicProfile(publicProfile);

        if (publicProfile.profileImageUrl) {
          const cachedData = getCachedImageUrl(publicProfile.profileImageUrl);
          if (cachedData && cachedData.url) {
            setProfileImageUrl(cachedData.url);
          } else {
            const imageUrl = `https://d1s9xshr26t6r2.cloudfront.net/public/${publicProfile.profileImageUrl}`;
            setImageUrls((prevImageUrls) => ({
              ...prevImageUrls,
              [publicProfile.profileImageUrl]: imageUrl,
            }));
            setProfileImageUrl(imageUrl);
            setCachedImageUrl(
              publicProfile.profileImageUrl,
              imageUrl,
              Date.now(),
              publicProfile.updatedAt
            ); // キャッシュを設定
          }
        }

        const postsData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, {
            filter: { userProfileId: { eq: id } },
          })
        );
        setPosts(postsData.data.listPosts.items);
        setDisplayedPosts(
          postsData.data.listPosts.items.slice(0, POSTS_PER_PAGE)
        );
      } catch (error) {
        console.error("Error fetching public profile or posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublicProfile();
  }, [id]); // imageUrlsを依存関係から削除 */
  useEffect(() => {
    const fetchPublicProfile = async () => {
      try {
        setIsLoading(true);
        const publicProfileData = await API.graphql(
          graphqlOperation(getPublicProfilesByUserProfileId, {
            nextToken,
            userProfileId: id,
            limit: 1,
          })
        );
        const publicProfile = await publicProfileData.data
          .publicProfilesByUserProfileId.items[0];
        setPublicProfile(publicProfile);

        if (publicProfile.profileImageUrl) {
          const cachedData = getCachedImageUrl(publicProfile.profileImageUrl);
          if (cachedData && cachedData.url) {
            setProfileImageUrl(cachedData.url);
          } else {
            const imageUrl = `https://d1s9xshr26t6r2.cloudfront.net/public/${publicProfile.profileImageUrl}`;
            setImageUrls((prevImageUrls) => ({
              ...prevImageUrls,
              [publicProfile.profileImageUrl]: imageUrl,
            }));
            setProfileImageUrl(imageUrl);
            setCachedImageUrl(
              publicProfile.profileImageUrl,
              imageUrl,
              Date.now(),
              publicProfile.updatedAt
            ); // キャッシュを設定
          }
        }

        const postsData = await API.graphql(
          graphqlOperation(excludeOwnerListPosts, {
            filter: { userProfileId: { eq: id } },
          })
        );

        // ここでフィルタリングを行う
        const activePosts = postsData.data.listPosts.items.filter(
          (post) => post.postingStatus === "ACTIVE"
        );

        setPosts(activePosts);
        setDisplayedPosts(activePosts.slice(0, POSTS_PER_PAGE));
      } catch (error) {
        console.error("Error fetching public profile or posts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublicProfile();
  }, [id]); // imageUrlsを依存関係から削除

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(), // キャッシュした時間を保存
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      // parsedDataがオブジェクトであり、必要なプロパティが存在するかをチェック
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          // 144000分以上経過している場合は無効にする
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key); // 有効でないデータを削除
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key); // 有効でないデータを削除
      return null;
    }
  };

  const fetchImageUrl = useCallback(
    async (imageKey, updatedAt) => {
      if (imageKey === "../assets/news_sample.png" || !imageKey) {
        const localImagePath = require("../assets/news_sample.png");
        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          [imageKey]: localImagePath,
        }));
        return localImagePath; // ローカルの画像パスをそのまま返す
      }

      const cachedData = getCachedImageUrl(imageKey);

      if (cachedData) {
        const cachedUpdatedAt = new Date(cachedData.updatedAt);
        const postUpdatedAt = new Date(updatedAt);
        const diffInMinutes = (postUpdatedAt - cachedUpdatedAt) / (1000 * 60);

        if (diffInMinutes < 2) {
          setImageUrls((prevImageUrls) => ({
            ...prevImageUrls,
            [imageKey]: cachedData.url,
          }));

          return cachedData.url;
        }
      }

      try {
        const signedURL = `https://d1s9xshr26t6r2.cloudfront.net/public/${imageKey}`;
        setCachedImageUrl(imageKey, signedURL, updatedAt, updatedAt); // LastModifiedを使わずにupdatedAtを使用

        setImageUrls((prevImageUrls) => ({
          ...prevImageUrls,
          [imageKey]: signedURL,
        }));

        return signedURL;
      } catch (err) {
        console.error("Error fetching image URL:", err);
        return null;
      }
    },
    [imageUrls]
  );

  const handleLoadMoreClick = async () => {
    if (displayedPosts.length < posts.length) {
      const newPosts = posts.slice(
        displayedPosts.length,
        displayedPosts.length + POSTS_PER_PAGE
      );

      // 新しいポストの画像をフェッチ
      await Promise.all(
        newPosts.map(async (post) => {
          if (post.postImageUrl1 && !imageUrls[post.postImageUrl1]) {
            await fetchImageUrl(post.postImageUrl1, post.updatedAt);
          }
        })
      );

      setDisplayedPosts((prevDisplayedPosts) => [
        ...prevDisplayedPosts,
        ...newPosts,
      ]);
    }
  };

  useEffect(() => {
    const fetchInitialImages = async () => {
      const initialPosts = posts.slice(0, POSTS_PER_PAGE);

      await Promise.all(
        initialPosts.map(async (post) => {
          if (post.postImageUrl1 && !imageUrls[post.postImageUrl1]) {
            await fetchImageUrl(post.postImageUrl1, post.updatedAt);
          }
        })
      );
    };

    fetchInitialImages();
  }, [posts, fetchImageUrl]);

  if (!publicProfile) {
    return <div>Loading...</div>;
  }

  const getGenderText = (gender) => {
    return genderMap[gender] || "未設定";
  };

  const getJapaneseProvinceName = (regionKey) => {
    return japanProvinceMap[regionKey] || "不明な地域";
  };

  const getJapaneseCityName = (region, city) => {
    if (!city) return ""; // cityが空やnullの場合、空文字を返す
    return japanCitiesMap[region]?.[city] || "";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const getCategoryName = (path) => {
    const category = categories.find((category) => category.path === path);
    return category ? category.name : "不明なカテゴリー";
  };

  const postsList = displayedPosts.map((post) => {
    const isMobile = window.innerWidth <= 480;

    const truncatedTitleLength = isMobile ? 25 : 50;
    const truncatedTitle =
      post.title.length > truncatedTitleLength
        ? `${post.title.substring(0, truncatedTitleLength)}...`
        : post.title;

    const truncatedContentLength = isMobile ? 50 : 150;
    const truncatedContent =
      post.content.length > truncatedContentLength
        ? `${post.content.substring(0, truncatedContentLength)}...`
        : post.content;

    const imageUrl = imageUrls[post.postImageUrl1];

    return (
      <React.Fragment key={post.id}>
        <div className={styles.listingItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {imageUrl && (
              <div style={{ marginRight: "16px" }}>
                <Link to={`/contents/${post.id}`}>
                  <img src={imageUrl} alt="Post Thumbnail" />
                </Link>
              </div>
            )}

            <div
              style={{ flexGrow: 1, maxWidth: "550px" }}
              className={styles.leftAligned}
            >
              <Link to={`/contents/${post.id}`}>
                <h4 className={styles.titleNext}>{truncatedTitle}</h4>
              </Link>
              <div
                style={{
                  fontSize: "0.8em",
                  color: "gray",
                  marginTop: "-5px",
                }}
              >
                {getJapaneseProvinceName(post.postProvince)}{" "}
                {getJapaneseCityName(post.postProvince, post.postCity)} /{" "}
                {getCategoryName(post.category)}
              </div>
              <p>{truncatedContent}</p>
            </div>

            <div className={styles.postDatelist}>
              <div>
                <span>
                  投稿日: <br />
                  {formatDate(post.createdAt)}
                </span>
              </div>
              <div>
                <span>
                  更新日: <br />
                  {formatDate(post.updatedAt)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className={styles.customHr} />
      </React.Fragment>
    );
  });

  return (
    <div className={styles.profileContainer}>
      <h2 className={styles.profileNickname}>
        {publicProfile.nickname} のページ
      </h2>
      <div className={styles.profileHeader}>
        {profileImageUrl && (
          <img
            className={styles.profileImage}
            src={
              profileImageUrl ===
              "https://d1s9xshr26t6r2.cloudfront.net/public/profileImages/default_images/user_placeholder.png"
                ? require("../assets/user_placeholder.png")
                : profileImageUrl
            }
            alt="Profile"
          />
        )}
        <div className={styles.profileDetails}>
          <p className={styles.profileBio}>
            自己紹介:
            <br />
            {formatBio(publicProfile.bio)
              .split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </p>
          <p className={styles.profileRegion}>
            地域: {getJapaneseProvinceName(publicProfile.userProvince)}{" "}
            {getJapaneseCityName(
              publicProfile.userProvince,
              publicProfile.userCity
            )}
          </p>
          <p className={styles.profileRegion}>
            性別: {getGenderText(publicProfile.gender)}
          </p>
          <p className={styles.profileJoinedDate}>
            登録日: {formatDate(publicProfile.createdAt)}
          </p>
        </div>
      </div>
      <hr />
      <div className={styles.postsList}>
        <h3>投稿一覧</h3>
        {isLoading ? (
          <p>Loading...</p>
        ) : posts.length > 0 ? (
          postsList
        ) : (
          <div>投稿がありません。</div>
        )}
        {displayedPosts.length < posts.length && (
          <div style={{ textAlign: "center", margin: "10px 0" }}>
            <h4
              onClick={handleLoadMoreClick}
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                fontSize: "14px",
                background: "none",
                border: "none",
                color: "gray",
              }}
            >
              ▼さらに読み込む
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicMyPage;
