import React, { useState, useEffect, createContext } from "react";
import Modal from "react-modal";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Auth, API } from "aws-amplify";
import ScrollToTop from "./components/ScrollToTop"; // ScrollToTopコンポーネントをインポート
import DOMPurify from "dompurify"; // DOMPurifyライブラリを使用
//import Breadcrumbs from "./components/Breadcrumbs"; // Breadcrumbsコンポーネントをインポート
import Home from "./components/Home";
import SelectRegion from "./components/SelectRegion";
import SelectCategoryInfo from "./components/SelectCategoryInfo";
import PostScreen from "./components/PostScreen";
import Category from "./components/Category";
import Contents from "./components/Contents";
import ChatPage from "./components/ChatPage";
import Region from "./components/Region";
import AuthPage from "./components/AuthPage";
import MessagesList from "./components/MessagesList";
import Message from "./components/Message";
import MyPage from "./components/MyPage";
import PostEdit from "./components/PostEdit";
import ProfileSetup from "./components/ProfileSetup";
import ChangeAvatar from "./components/ChangeAvatar";
import PublicMyPage from "./components/PublicMyPage";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import SearchResults from "./components/SearchResults";
import Terms from "./components/Terms";
import Aboutus from "./components/Aboutus";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Guideline from "./components/Guideline";
import Donation from "./components/Donation";
import Disclaimer from "./components/Disclaimer";
import Inquiries from "./components/Inquiries";
import VerificationPage from "./components/VerificationPage";
import { setLoggedIn } from "./features/AuthSlice";
import { setJapaneseRegionName } from "./features/RegionSlice";
import { setNickname, setUserProfileId } from "./features/UserProfileSlice";
import { userProfilesByOwner } from "./graphql/queries";
import { Text, useTheme } from "@aws-amplify/ui-react";
import useDebounceReload from "./utils/useDebounceReload";
import CustomCarousel from "./components/CustomCarousel";
import "./App.css";
import logo from "./assets/logo192.png";

/* import {
  TextField,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
} from "@mui/material"; */

Modal.setAppElement("#root"); // アプリケーションのルート要素を設定（#rootは通常の選択）
const ProfileSetupModalContext = createContext();

function App() {
  useDebounceReload(2500);
  const [isSearching, setIsSearching] = useState(false); // 検索中かどうかのステート
  const [isSearchDisabled, setIsSearchDisabled] = useState(false); // 検索ボタンの無効化状態
  const japaneseRegionName = useSelector(
    (state) => state.region.japaneseRegionName
  );
  const [searchQuery, setSearchQuery] = useState(""); // 検索クエリのステートを追加
  const prefecture = useSelector((state) => state.region.prefecture);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isProfileSetupModalOpen, setIsProfileSetupModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isComposing, setIsComposing] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isVerificationPage, setIsVerificationPage] = useState(false);
  const [isFooterPage, setIsFooterPage] = useState(false);

  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // フッターコンポーネントを追加
  // Footerコンポーネントの修正
  function Footer() {
    const { tokens } = useTheme();
    return (
      <footer className="footer">
        <ul>
          <li>
            <Link to="/terms" target="_blank" rel="noopener noreferrer">
              利用規約
            </Link>
          </li>
          <li>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
              プライバシーポリシー
            </Link>
          </li>
          <li>
            <Link to="/disclaimer" target="_blank" rel="noopener noreferrer">
              免責事項
            </Link>
          </li>
          <li>
            <Link to="/guideline" target="_blank" rel="noopener noreferrer">
              ガイドライン
            </Link>
          </li>
          <li>
            <Link to="/aboutus" target="_blank" rel="noopener noreferrer">
              会社概要
            </Link>
          </li>
          <li>
            <Link to="/inquiries" target="_blank" rel="noopener noreferrer">
              お問い合わせ
            </Link>
          </li>
          <li>
            <Link to="/donation" target="_blank" rel="noopener noreferrer">
              寄付のお願い
            </Link>
          </li>
        </ul>
        <Text color={tokens.colors.neutral[80]}>
          ©2024 Anitya All Rights Reserved
        </Text>
      </footer>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      const path = window.location.pathname;
      setIsVerificationPage(path !== "/verify");
    }, 20);
  }, [window.location.pathname]);

  useEffect(() => {
    checkAuthState();
    // 初回レンダリング時にのみ実行されるようにする
    const savedSearchQuery = localStorage.getItem("searchQuery");
    if (savedSearchQuery) {
      setSearchQuery(savedSearchQuery);
    }
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      // ページ更新の処理を記述
      window.location.reload(); // ページをリロードする
      setShouldRefresh(false); // shouldRefreshをfalseに戻す
    }
  }, [shouldRefresh]);

  useEffect(() => {
    const path = window.location.pathname;
    const footerPaths = [
      "/terms",
      "/aboutus",
      "/privacypolicy",
      "/disclaimer",
      "/guideline",
      "/inquiries",
      "/donation",
    ];
    setIsFooterPage(footerPaths.includes(path));
  }, [window.location.pathname]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("Error");
    }
  };

  const checkAuthState = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const currentTime = Math.floor(new Date().getTime() / 1000);

      if (idToken.getExpiration() < currentTime) {
        await signOut();
      } else {
        dispatch(setLoggedIn(true));
        fetchUserProfile(); // ユーザープロファイルを取得
      }
    } catch (error) {
      dispatch(setLoggedIn(false));
    }
  };

  /*  const checkAuthState = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        dispatch(setLoggedIn(true));
        fetchUserProfile(); // ユーザープロファイルを取得
      } else {
        dispatch(setLoggedIn(false));
      }
    } catch (error) {
      dispatch(setLoggedIn(false));
    }
  }; */

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter" && !isComposing) {
      e.preventDefault();
      handleSearch(searchQuery);
    }
  };

  async function fetchUserProfile() {
    try {
      setIsProfileSetupModalOpen(false);
      const user = await Auth.currentAuthenticatedUser();
      const owner = await user.attributes.sub;

      const userData = await API.graphql({
        query: userProfilesByOwner,
        variables: { owner: owner, limit: 1 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (userData.data.userProfilesByOwner.items.length > 0) {
        const profile = userData.data.userProfilesByOwner.items[0];
        await setUserProfile(profile);
        await dispatch(setNickname(profile.nickname)); // ニックネームをReduxストアに格納
        await dispatch(setUserProfileId(profile.id)); // ユーザープロファイルIDをReduxストアに格納

        // regionフィールドがnullまたは未定義の場合にモーダルを開く
        if (!profile.userProvince || !profile.birthdate) {
          await setIsProfileSetupModalOpen(true);
        }

        return profile;
      }
      return null;
    } catch (err) {
      console.error("Error fetching user profile", err);
      return null;
    }
  }

  const handleSearch = async (searchQuery) => {
    if (searchQuery.length < 2) {
      alert("検索語は２文字以上である必要があります");
      return;
    }

    const searchTerms = searchQuery.split(/[\s　]+/);
    const invalidTerms = searchTerms.filter((term) => term.length < 2);
    if (invalidTerms.length > 0) {
      alert("各検索語は２文字以上である必要があります");
      return;
    }

    setIsSearching(true);
    setIsSearchDisabled(true);

    try {
      // 検索クエリをlocalStorageに保存
      localStorage.setItem("searchQuery", searchQuery);

      // サニタイズされた検索クエリ
      const sanitizedQuery = DOMPurify.sanitize(searchQuery);

      window.location.href = `/search-results?keyword=${encodeURIComponent(
        sanitizedQuery
      )}`;
    } catch (error) {
      console.error("Error searching posts", error);
    } finally {
      setIsSearching(false);
      setTimeout(() => setIsSearchDisabled(false), 3000);
    }
  };

  return (
    <ProfileSetupModalContext.Provider
      value={{ isProfileSetupModalOpen, setIsProfileSetupModalOpen }}
    >
      <Router>
        <div className="App">
          {isVerificationPage && !isFooterPage && (
            <>
              <div className="header-container">
                <div className="header-container-upper">
                  <div className="header-row">
                    <Link to="/" className="logo-container">
                      <img
                        src={logo}
                        alt="Logo"
                        style={{ height: "50px", marginRight: "10px" }}
                      />
                      <div>
                        <h4>カコミュ</h4>
                        <h5 class="region-name"> {japaneseRegionName}編</h5>
                      </div>
                    </Link>
                    {!isMobile && (
                      <div className="search-container">
                        <input
                          type="text"
                          id="searchInput"
                          placeholder="検索ワードを入力"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={handleSearchKeyDown}
                          onCompositionStart={() => setIsComposing(true)}
                          onCompositionEnd={() => setIsComposing(false)}
                        />
                        <button
                          className="searchButtonApp"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSearch(searchQuery);
                          }}
                          disabled={isSearching || isSearchDisabled}
                        >
                          {isSearching ? "検索中..." : "検索"}
                        </button>
                      </div>
                    )}
                    <div className="header-nav">
                      {isLoggedIn && (
                        <Link
                          to="/messageslist"
                          className="header-nav-link"
                          style={{ marginRight: "1rem" }}
                        >
                          DMリスト
                        </Link>
                      )}
                      <Link
                        to={isLoggedIn ? "/mypage" : "/auth"}
                        className={`header-nav-link ${
                          isLoggedIn ? "" : "header-login-button"
                        }`}
                      >
                        {isLoggedIn ? "マイページ" : "サインイン"}
                      </Link>
                    </div>
                  </div>
                  {isMobile && (
                    <div className="search-container">
                      <input
                        type="text"
                        placeholder="検索ワードを入力"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={handleSearchKeyDown}
                        onCompositionStart={() => setIsComposing(true)}
                        onCompositionEnd={() => setIsComposing(false)}
                        onFocus={(e) => e.target.select()}
                      />
                      <button
                        className="searchButtonApp"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSearch(searchQuery);
                        }}
                        disabled={isSearching || isSearchDisabled}
                      >
                        {isSearching ? "検索中..." : "検索"}
                      </button>
                    </div>
                  )}
                </div>

                <nav className="fixed-nav">
                  <ul>
                    <li>
                      <Link to={"/region/all"}>全国ホーム</Link>
                    </li>
                    <li>
                      <Link
                        to={
                          japaneseRegionName === "全国"
                            ? "/select-region"
                            : `/region/${prefecture}`
                        }
                      >
                        地域ホーム
                      </Link>
                    </li>
                    <li>
                      <Link to="/select-region">地域選択</Link>
                    </li>
                    <li>
                      <Link to="/select-category">カテゴリー選択</Link>
                    </li>
                    <li>
                      {isLoggedIn && <Link to="/post-screen">投稿画面</Link>}
                    </li>
                  </ul>
                </nav>
              </div>

              <ScrollToTop />
            </>
          )}
          {/* <Breadcrumbs /> */}
          <div className="main-content">
            <Routes>
              <Route
                path="/"
                element={
                  <PublicRoute>
                    <CustomCarousel className="carousel-image" />
                    <Home />
                  </PublicRoute>
                }
              />
              <Route
                path="/auth"
                element={
                  <PublicRoute>
                    <AuthPage />
                  </PublicRoute>
                }
              />
              <Route
                path="/select-region"
                element={
                  <PublicRoute>
                    <SelectRegion />
                  </PublicRoute>
                }
              />
              <Route
                path="/select-category"
                element={
                  <PublicRoute>
                    <SelectCategoryInfo />
                  </PublicRoute>
                }
              />
              <Route
                path="/post-screen"
                element={
                  <PrivateRoute>
                    <PostScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="/region/:prefecture/:category/:page"
                element={
                  <PublicRoute>
                    <Category />
                  </PublicRoute>
                }
              />
              <Route
                path="/region/:prefecture/:category"
                element={
                  <PublicRoute>
                    <Category />
                  </PublicRoute>
                }
              />
              <Route
                path="/contents/:id"
                element={
                  <PublicRoute>
                    <Contents />
                  </PublicRoute>
                }
              />
              <Route
                path="/chatpage/:id"
                element={
                  <PublicRoute>
                    <ChatPage />
                  </PublicRoute>
                }
              />
              <Route
                path="/region/:prefecture"
                element={
                  <PublicRoute>
                    <Region setJapaneseRegionName={setJapaneseRegionName} />
                  </PublicRoute>
                }
              />
              <Route
                path="/userprofile/:id"
                element={
                  <PublicRoute>
                    <PublicMyPage />
                  </PublicRoute>
                }
              />
              <Route
                path="/messageslist"
                element={
                  <PrivateRoute>
                    <MessagesList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/messages/:listId"
                element={
                  <PrivateRoute>
                    <Message />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mypage"
                element={
                  <PrivateRoute>
                    <MyPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/postedit/:id"
                element={
                  <PrivateRoute>
                    <PostEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/changeimages/:id"
                element={
                  <PrivateRoute>
                    <ChangeAvatar />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search-results"
                element={
                  <PublicRoute>
                    <SearchResults />
                  </PublicRoute>
                }
              />
              <Route
                path="/terms"
                element={
                  <PublicRoute>
                    <Terms />
                  </PublicRoute>
                }
              />
              <Route
                path="/aboutus"
                element={
                  <PublicRoute>
                    <Aboutus />
                  </PublicRoute>
                }
              />
              <Route
                path="/privacypolicy"
                element={
                  <PublicRoute>
                    <PrivacyPolicy />
                  </PublicRoute>
                }
              />
              <Route
                path="/disclaimer"
                element={
                  <PublicRoute>
                    <Disclaimer />
                  </PublicRoute>
                }
              />
              <Route
                path="/inquiries"
                element={
                  <PublicRoute>
                    <Inquiries />
                  </PublicRoute>
                }
              />
              <Route
                path="/guideline"
                element={
                  <PublicRoute>
                    <Guideline />
                  </PublicRoute>
                }
              />
              <Route
                path="/donation"
                element={
                  <PublicRoute>
                    <Donation />
                  </PublicRoute>
                }
              />
              <Route path="/verify" element={<VerificationPage />} />
            </Routes>
          </div>
          <Modal
            isOpen={isProfileSetupModalOpen}
            onRequestClose={() => setIsProfileSetupModalOpen(true)}
            contentLabel="プロフィール設定"
            style={{
              content: {
                width: "80%",
                height: "80%",
                margin: "auto",
                padding: "5px",
              },
              overlay: {
                zIndex: "9999",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // 背景の色と透明度を調整
              },
            }}
          >
            <ProfileSetup
              closeModal={() => {
                setIsProfileSetupModalOpen(false);
                setShouldRefresh(true); // ページ更新フラグをtrueに設定
              }}
            />
          </Modal>
          {window.location.pathname !== "/verify" && <Footer />}{" "}
          {/* フッターも条件付きで表示 */}
        </div>
      </Router>
    </ProfileSetupModalContext.Provider>
  );
}

export { ProfileSetupModalContext };
export default App;
