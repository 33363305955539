import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import JapanProvinceList from "../containers/JapanProvinceList";
import { updateUserProfile } from "../graphql/mutations";
import {
  userProfilesByOwner,
  getPublicProfilesByUserProfileId,
} from "../graphql/queries";
import "../styles/ProfileSetup.css";

const ProfileSetup = ({ closeModal }) => {
  const [userProfile, setUserProfile] = useState({});
  const [editValues, setEditValues] = useState({
    nickname: "",
    gender: "", // 初期値は空文字列や、'MALE', 'FEMALE', 'OTHER'のいずれかを設定することもできます
    userCountry: "",
    userProvince: "",
    userCity: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
  });
  /*  const [ipAddress, setIpAddress] = useState(""); */
  const [buttonState, setButtonState] = useState("idle"); // ボタンの状態を管理する状態変数
  const [setShouldRefresh] = useState(false);

  useEffect(() => {
    fetchUserProfile();
    /* fetchIpAddress(); */
  }, []);

  /* async function fetchIpAddress() {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (err) {
      console.error("Error fetching IP address", err);
    }
  } */

  async function fetchUserProfile() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const owner = user.attributes.sub;

      const userData = await API.graphql({
        query: userProfilesByOwner,
        variables: { owner: owner, limit: 1 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (userData.data.userProfilesByOwner.items.length > 0) {
        const userProfile = userData.data.userProfilesByOwner.items[0];
        setUserProfile(userProfile);
        setEditValues({
          nickname: userProfile.nickname,
          gender: userProfile.gender,
          userProvince: userProfile.userProvince,
          birthYear: userProfile.birthYear || "",
          birthMonth: userProfile.birthMonth || "",
          birthDay: userProfile.birthDay || "",
        });
      } else {
        console.warn("User profile not found");
      }
    } catch (err) {
      console.error("Error fetching user profile", err);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setButtonState("loading");

    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      /*   const currentIpAddress = ipAddress;
      const currentIpAddressAddedAt = userProfile.ipAddressAddedAt; */

      const updateData = {
        id: userProfile.id,
        nickname: editValues.nickname,
        gender: editValues.gender,
        userCountry: "Japan",
        userProvince: editValues.userProvince,
        birthdate: `${editValues.birthYear}-${editValues.birthMonth
          .toString()
          .padStart(2, "0")}-${editValues.birthDay
          .toString()
          .padStart(2, "0")}`,
        owner: user.attributes.sub,
        /* ip_addr: currentIpAddress,
        ipAddressAddedAt:
          currentIpAddress && !currentIpAddressAddedAt
            ? new Date().toISOString()
            : currentIpAddressAddedAt, // IPアドレスが新しく追加された場合のみ日時を設定 */
      };

      const updateUserProfileResult = await API.graphql({
        query: updateUserProfile,
        variables: { input: updateData },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const updatedUserProfileId =
        updateUserProfileResult.data.updateUserProfile.id;

      // パブリックプロファイルを取得する
      const publicProfileResponse = await API.graphql({
        query: getPublicProfilesByUserProfileId,
        variables: { userProfileId: updatedUserProfileId },
      });

      // パブリックプロファイルを更新する
      const publicProfileData = {
        id: publicProfileResponse.data.publicProfilesByUserProfileId.items[0]
          .id,
        nickname: editValues.nickname,
        userCountry: "Japan",
        userProvince: editValues.userProvince,
        bio: "はじめまして",
        userProfileId: userProfile.id,
        gender: editValues.gender,
        profileImageUrl: `profileImages/default_images/user_placeholder.png`,
      };

      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/public-profile",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify(publicProfileData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update public profile");
      }

      setUserProfile((prevProfile) => ({ ...prevProfile, ...editValues }));
      alert("プロファイルが更新されました。");
      closeModal();
      setShouldRefresh(true);
    } catch (err) {
      console.error("Error updating profile", err);
    } finally {
      setButtonState("idle");
    }
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setEditValues((prevState) => ({ ...prevState, [name]: value }));
  }

  function isValidDate(year, month, day) {
    const currentDate = new Date();
    const inputDate = new Date(year, month - 1, day); // 月は0-indexedなので、-1する

    if (year < 1900) {
      return false;
    }

    if (inputDate > currentDate) {
      return false;
    }

    return true;
  }

  // 年の選択肢を生成する関数
  function generateYears() {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 1900 + 1 },
      (_, i) => currentYear - i // 最新の年度を上にするために降順で生成
    );
    return years;
  }

  // 月の選択肢を生成する関数
  function generateMonths() {
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    return months;
  }

  // 日の選択肢を生成する関数
  function generateDays(year, month) {
    const daysInMonth = new Date(year, month, 0).getDate();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    return days;
  }

  return (
    <div className="profile-setup">
      <h2>はじめまして！最初にプロフィールを設定しましょう！</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>ニックネーム:</span>
          <input
            type="text"
            name="nickname"
            value={editValues.nickname}
            onChange={handleInputChange}
            onFocus={(e) => e.target.select()}
            required
            disabled={buttonState === "loading"}
          />
        </label>
        <label>
          <span>地域名:</span>
          <JapanProvinceList
            onSelect={(e) =>
              handleInputChange({
                target: { name: "userProvince", value: e.target.value },
              })
            }
            selectedRegion={editValues.userProvince || ""} // 空文字列を初期値として設定
            excludeAll={true} // "全国"オプションを除外する場合はtrueに設定
            disabled={buttonState === "loading"}
          />
        </label>
        <label>
          <span>生年月日(生年月日は設定後に変更できません):</span>
          <div className="date-selectors">
            {/*  <input
              type="number"
              name="birthYear"
              className="no-spin-button"
              value={editValues.birthYear}
              onChange={handleInputChange}
              min="1900"
              max={new Date().getFullYear()}
              placeholder="年"
              required
              style={{ marginRight: "10px" }}
              disabled={buttonState === "loading"}
            /> */}
            <select
              name="birthYear"
              value={editValues.birthYear}
              onChange={handleInputChange}
              required
              style={{ marginRight: "10px" }}
              disabled={buttonState === "loading"}
            >
              <option value="">年</option>
              {generateYears().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <select
              name="birthMonth"
              value={editValues.birthMonth}
              onChange={handleInputChange}
              required
              style={{ marginRight: "10px" }}
              disabled={buttonState === "loading"}
            >
              <option value="">月</option>
              {generateMonths().map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              name="birthDay"
              value={editValues.birthDay}
              onChange={handleInputChange}
              required
              style={{ marginRight: "10px" }}
              disabled={buttonState === "loading"}
            >
              <option value="">日</option>
              {generateDays(editValues.birthYear, editValues.birthMonth).map(
                (day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                )
              )}
            </select>
            {isValidDate(
              editValues.birthYear,
              editValues.birthMonth,
              editValues.birthDay
            ) ? null : (
              <div style={{ color: "red" }}>
                正しい生年月日を入力してください
              </div>
            )}
          </div>
        </label>
        <label>
          <span>性別:</span>
          <select
            name="gender"
            value={editValues.gender}
            onChange={handleInputChange}
            required
            disabled={buttonState === "loading"}
          >
            <option value="">選択してください</option>
            <option value="FEMALE">女性</option>
            <option value="MALE">男性</option>
            <option value="UNANSWERED">回答しない</option>
          </select>
        </label>
        <button
          type="submit"
          disabled={
            buttonState === "loading" || // 保存処理中はボタンを無効化
            !editValues.userProvince ||
            !editValues.gender ||
            !editValues.nickname ||
            !editValues.birthYear ||
            !editValues.birthMonth ||
            !editValues.birthDay ||
            !isValidDate(
              editValues.birthYear,
              editValues.birthMonth,
              editValues.birthDay
            )
          }
        >
          {buttonState === "loading" ? "保存中..." : "保存"}
        </button>
      </form>
    </div>
  );
};

export default ProfileSetup;
