import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Donation.css";

const Donation = () => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("コピーしました", {
        autoClose: 700,
      });
    });
  };

  return (
    <div className="donationStyle">
      <h2>寄付のお願い</h2>
      <p>
        当サイトは少人数で運営しております。私たちの活動を支援するために、寄付をお願いいたします。皆様のご支援が私たちのサービスを維持し、向上させるために活用していきます。以下の方法で寄付を受け付けております。
      </p>

      {/* <div className="bank-info">
        <h3>銀行振込先情報</h3>
        <p>銀行名: 住信SBIネット銀行</p>
        <p>支店名: 法人第一支店（支店コード106）</p>
        <p>口座番号: 普通 1492656</p>
        <p>振込先: フイリアホ－ルデイングス（ド</p>
      </div> */}

      <div className="crypto-info">
        <h3>暗号通貨送付先情報</h3>
        <p>
          ビットコイン: bc1qlaum66y98hhdn3gkjp4pcr0ch3320evd8wyqed
          <button
            onClick={() =>
              handleCopy("bc1qlaum66y98hhdn3gkjp4pcr0ch3320evd8wyqed")
            }
          >
            コピー
          </button>
        </p>
        <p>
          イーサリアム: 0x40302883aCf45b2d4265763781fD1D83a1c91E89
          <button
            onClick={() =>
              handleCopy("0x40302883aCf45b2d4265763781fD1D83a1c91E89")
            }
          >
            コピー
          </button>
        </p>
        <p>
          ソラナ: DQYLHRpSBYdpP2fLbtHvzCXi5okLBvvNXLctaRQBtT6b
          <button
            onClick={() =>
              handleCopy("DQYLHRpSBYdpP2fLbtHvzCXi5okLBvvNXLctaRQBtT6b")
            }
          >
            コピー
          </button>
        </p>
        <p>
          ライトコイン: LYxAmr7iZRLH429UJrPNhB8jvxAHVnFcQN
          <button
            onClick={() => handleCopy("LYxAmr7iZRLH429UJrPNhB8jvxAHVnFcQN")}
          >
            コピー
          </button>
        </p>
        <p>
          ドッジコイン: DDWKYaELRjvVBHC3hwXZeN2jHbU7QKDKLm
          <button
            onClick={() => handleCopy("DDWKYaELRjvVBHC3hwXZeN2jHbU7QKDKLm")}
          >
            コピー
          </button>
        </p>
        <p>
          ポルカドット: 118XJbYDo8u2fjefKXaFfvkSanpF67ee1cmjnynVCf8KY1m
          <button
            onClick={() =>
              handleCopy("118XJbYDo8u2fjefKXaFfvkSanpF67ee1cmjnynVCf8KY1m")
            }
          >
            コピー
          </button>
        </p>
        <p>
          アバランチ: 0x40302883aCf45b2d4265763781fD1D83a1c91E89
          <button
            onClick={() =>
              handleCopy("0x40302883aCf45b2d4265763781fD1D83a1c91E89")
            }
          >
            コピー
          </button>
        </p>
      </div>

      <p>ご協力ありがとうございます。</p>

      <ToastContainer />
    </div>
  );
};

export default Donation;
