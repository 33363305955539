// guideline.js
import React from "react";

const Guideline = () => {
  const guideStyle = {
    width: "80%",
    margin: "0 auto",
    textAlign: "left",
  };
  return (
    <div style={guideStyle}>
      <h2>ガイドライン</h2>
      <p>
        当サイトでは、すべての利用者が快適に情報交換を行えるよう、以下のガイドラインを設けています。
      </p>
      <h3>地域交流と人とのつながり</h3>
      <p>
        当サイトでは、地域の情報共有や人とのつながりを大切にしています。利用者同士が協力し合い、信頼関係を築けるような環境を目指しています。
      </p>
      <h3>コミュニケーションのマナー</h3>
      <p>
        当サイトは様々な方々が利用しています。メッセージのやり取りでは、相手に対する配慮を忘れず、敬意を持った言葉遣いを心がけましょう。
      </p>
      <h3>誹謗中傷の禁止</h3>
      <p>
        他者を傷つけるような発言や公序良俗に反する行動は厳禁です。当サイトでは、誹謗中傷や公序良俗に反する発言・行動を禁止しています。このような行為が確認された場合、利用を制限させていただくことがありますので、ご了承ください。
      </p>
      <h3>詐欺や脅迫の禁止</h3>
      <p>
        当サイトでは、詐欺や脅迫といった犯罪行為を一切禁止しています。このような行為が確認された場合、法的措置を含む厳格な対応を行います。
      </p>
      <h3>わいせつ、ポルノ、性的な内容を含むコンテンツの禁止</h3>
      <p>
        当サイトでは、わいせつ、ポルノ、性的な内容を含む投稿を禁止しています。このような投稿やコメントが確認された場合、直ちに削除し、利用制限を行う場合があります。
      </p>

      <h3>マルチ商法やネズミ講による勧誘行為の禁止</h3>
      <p>
        当サイトでは、マルチ商法やネズミ講の勧誘を一切禁止しています。このような投稿やコメントが確認された場合、直ちに削除し、アカウントの削除や利用制限を行う場合があります。
      </p>
      <h3>反社会的行為の禁止および反社会的勢力の排除</h3>
      <p>
        当サイトでは、反社会的勢力による閲覧・登録・書き込み等サイト内での一切の行為およびアクセスをお断りしています。このような行為が確認された場合、ただちにアカウントの削除や利用制限を行い、必要に応じて国の機関に通報を行います。
      </p>

      <h3>情報の正確性・最新性</h3>
      <p>
        提供する情報が誤っていると、相手に迷惑をかける可能性があります。情報を投稿する前に、内容を再確認してください。また投稿した内容が変更となった場合、すみやかに最新の情報に更新するよう心がけてください。
      </p>
      <h3>スパム行為の禁止</h3>
      <p>
        過度な宣伝やスパム行為はご遠慮ください。広告や宣伝を行う際は、適切なカテゴリを選び、他の利用者がわかりやすいように心がけましょう。
      </p>
    </div>
  );
};

export default Guideline;
