// containers/CategoryList.js
const categories = [
  { name: "求人募集", path: "recruiting" },
  { name: "メンバー募集", path: "joinus" },
  { name: "イベント", path: "event" },
  { name: "賃貸不動産", path: "rentrealestate" },
  { name: "売買不動産", path: "salerealestate" },
  { name: "掲示板", path: "bbs" },
];

// カテゴリー情報をエクスポート
export { categories };
