import React, { useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import {
  getMessage,
  getPublicProfilesByUserProfileId,
} from "../graphql/queries";
import { updateMessage } from "../graphql/mutations";
import DOMPurify from "dompurify";
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { FaFlag } from "react-icons/fa";
import { FaCommentSlash, FaRegTrashCan } from "react-icons/fa6";
import Modal from "react-modal"; // モーダルのインポート
import { createReport } from "../graphql/mutations"; // 通報ミューテーションのインポート
import styles from "../styles/Message.module.css";

function Message() {
  const { listId } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [partnerProfileImageUrl, setPartnerProfileImageUrl] = useState("");
  const [partnerNickname, setpartnerNickname] = useState("");
  const [partnerProfileId, setPartnerProfileId] = useState("");
  const mynickname = useSelector((state) => state.userProfile.nickname);
  const profileid = useSelector((state) => state.userProfile.profileid);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isUndoConfirmOpen, setIsUndoConfirmOpen] = useState(false);
  const [currentMessageIdForAction, setCurrentMessageIdForAction] =
    useState(null);
  const [
    currentMessageCreatedAtForAction,
    setCurrentMessageCreatedAtForAction,
  ] = useState(null);
  const messagesEndRef = useRef(null);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const openDeleteConfirm = (messageId, messageCreatedAt) => {
    setCurrentMessageIdForAction(messageId);
    setCurrentMessageCreatedAtForAction(messageCreatedAt);
    setIsDeleteConfirmOpen(true);
  };

  const openUndoConfirm = (messageId) => {
    setCurrentMessageIdForAction(messageId);
    setIsUndoConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
    setCurrentMessageIdForAction(null);
  };

  const closeUndoConfirm = () => {
    setIsUndoConfirmOpen(false);
    setCurrentMessageIdForAction(null);
  };

  // Reportモーダル用のスタイル
  const reportModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: "350px",
    },
  };

  // Delete ConfirmとUndo Confirmモーダル用のスタイル
  const confirmModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: "150px",
    },
  };

  const fetchCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
    } catch (err) {
      console.error("Error fetching current user:", err);
    }
  };

  const setCheckedAt = () => {
    localStorage.setItem(`checkedAt_${listId}`, new Date().toISOString());
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        const response = await fetch(
          `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/messages?listId=${listId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching messages: ${response.status}`);
        }

        const messagesData = await response.json();

        // キャッシュから既存のメッセージを取得
        const cachedMessages =
          JSON.parse(localStorage.getItem(`messages_${listId}`)) || [];

        // 既存のメッセージIDをセットにして保持
        const cachedMessageIds = new Set(cachedMessages.map((msg) => msg.id));

        // 新しいメッセージだけを追加
        const newMessages = messagesData.filter(
          (msg) => !cachedMessageIds.has(msg.id)
        );

        // キャッシュを更新するためのメッセージのリスト
        const updatedMessages = await cachedMessages
          .map((cachedMessage) => {
            const newMessage = messagesData.find(
              (msg) => msg.id === cachedMessage.id
            );
            return newMessage
              ? { ...cachedMessage, ...newMessage }
              : cachedMessage;
          })
          .filter(
            (message) =>
              !(message.hiddenFrom && message.hiddenFrom.includes(profileid))
          );

        // 新しいメッセージをキャッシュに追加
        const finalUpdatedMessages = await [...updatedMessages, ...newMessages];
        localStorage.setItem(
          `messages_${listId}`,
          JSON.stringify(
            finalUpdatedMessages.filter(
              (message) =>
                !(message.hiddenFrom && message.hiddenFrom.includes(profileid))
            )
          )
        );

        const messagesWithNicknames = await Promise.all(
          finalUpdatedMessages.map(async (message) => {
            const profileData = await API.graphql({
              query: getPublicProfilesByUserProfileId,
              variables: {
                userProfileId: message.contributor,
                limit: 1,
              },
            });
            const profile =
              profileData.data.publicProfilesByUserProfileId.items[0];
            const nickname = profile ? profile.nickname : "";

            return { ...message, nickname };
          })
        );

        // hiddenFromに現在のユーザーIDが含まれていないメッセージだけをセット
        const filteredMessages = await messagesWithNicknames.filter(
          (message) => !message.hiddenFrom?.includes(profileid)
        );
        await setMessages(filteredMessages);
        await setIsLoading(false);

        // チェックした時刻をセット
        setCheckedAt();
      } catch (err) {
        setIsLoading(false);
        console.error("Error fetching messages:", err);
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        /*       behavior: "smooth", */
        block: "end",
      });
    }
  }, [messages]);

  const getCachedImageUrl = (key, updatedAt) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        const updatedDiffInMinutes =
          (new Date(updatedAt).getTime() -
            new Date(parsedData.updatedAt).getTime()) /
          (1000 * 60);

        if (diffInMinutes > 144000 || updatedDiffInMinutes > 1) {
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key);
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key);
      return null;
    }
  };

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: new Date().getTime(),
    };

    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const fetchPartnerProfileImage = async (partnerProfileId) => {
    try {
      const userProfileData = await API.graphql({
        query: getPublicProfilesByUserProfileId,
        variables: {
          userProfileId: partnerProfileId,
          limit: 1,
        },
      });

      const userProfile = await userProfileData.data
        .publicProfilesByUserProfileId.items[0];
      if (userProfile && userProfile.profileImageUrl) {
        const cachedData = getCachedImageUrl(
          userProfile.profileImageUrl,
          userProfile.updatedAt
        );

        if (cachedData) {
          setpartnerNickname(userProfile.nickname);
          setPartnerProfileImageUrl(cachedData.url);
          setPartnerProfileId(userProfile.userProfileId);
          return;
        }

        const profileImageURL = `https://d1s9xshr26t6r2.cloudfront.net/public/${userProfile.profileImageUrl}`;

        setCachedImageUrl(
          userProfile.profileImageUrl,
          profileImageURL,
          userProfile.updatedAt
        );
        setpartnerNickname(userProfile.nickname);
        setPartnerProfileImageUrl(profileImageURL);
      } else {
        setPartnerProfileImageUrl(""); // ユーザープロフィールが見つからない場合は空文字列を設定
        setPartnerProfileId(userProfile.userProfileId);
      }
    } catch (err) {
      console.error("Error fetching partner profile image:", err);
    }
  };

  const isReportFormValid = () => {
    return reportReason.trim() !== "";
  };

  const handleReportSubmit = (messageId) => {
    const sanitizedReportReason = DOMPurify.sanitize(reportReason);
    const sanitizedAdditionalInfo = DOMPurify.sanitize(additionalInfo);

    reportMessage(messageId, sanitizedReportReason, sanitizedAdditionalInfo);
    closeModal();
  };

  const handleReportMessage = (messageId) => {
    setReportReason("");
    setAdditionalInfo("");
    openModal();
    setCurrentMessageId(messageId);
  };

  const reportMessage = async (messageId, reason, additionalInfo) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const owner = user.attributes.sub;

      const sanitizedReason = DOMPurify.sanitize(reason);
      const sanitizedAdditionalInfo = DOMPurify.sanitize(additionalInfo);

      const newReport = {
        reportedEntityId: messageId,
        reportedEntityType: "MESSAGE",
        reason: sanitizedReason,
        additionalInfo: sanitizedAdditionalInfo,
        owner: owner,
        userProfileId: profileid,
        createdAt: new Date().toISOString(),
      };

      await API.graphql({
        query: createReport,
        variables: { input: newReport },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      toast.success("通報が完了しました");
    } catch (err) {
      console.error("Error reporting message:", err);
      toast.error("通報に失敗しました");
    }
  };

  useEffect(() => {
    const fetchCurrentUserAndPartnerProfileImage = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        const response = await fetch(
          `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/messageslist?listId=${listId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching messages list: ${response.status}`);
        }

        const messagesListData = await response.json();
        const { messageFrom, messageTo, blockedBy } = messagesListData;

        const partnerProfileId =
          messageFrom === profileid ? messageTo : messageFrom;

        if (blockedBy && blockedBy.includes(profileid)) {
          setIsBlocked(true);
          await fetchPartnerProfileImage(partnerProfileId);
        } else {
          await fetchPartnerProfileImage(partnerProfileId);
        }
      } catch (err) {
        console.error("Error fetching user or partner profile image:", err);
      }
    };

    fetchCurrentUserAndPartnerProfileImage();
  }, []);

  const updateCheckedStatus = async (messagesListId, profileid) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/messageslist?messagesListId=${messagesListId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({ profileid: profileid }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error updating MessagesList: ${response.status}`);
      }
    } catch (err) {
      console.error("Error updating MessagesList:", err);
    }
  };

  async function addMessage(e) {
    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.idToken.jwtToken;

    e.preventDefault();
    if (inputValue.length > 70) {
      toast.warn("メッセージは70字までです");
      return;
    }
    if (isSubmitting || isComposing) return;

    setIsSubmitting(true);
    try {
      if (!inputValue || !currentUser || !mynickname) {
        setIsSubmitting(false);
        return;
      }
      if (!mynickname) {
        toast.warn("ニックネームを設定してください");
        return;
      }

      const sanitizedInputValue = DOMPurify.sanitize(inputValue);

      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // JWTトークンをヘッダーに追加
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            content: sanitizedInputValue, // サニタイズされた値を使用
            contributor: profileid, // GraphQLのcontributorに対応
            messagesListID: listId, // GraphQLのmessagesListIDに対応
            owner: currentUser.attributes.sub, // GraphQLのownerに対応
          }),
        }
      );

      /*  const response = await API.graphql({
        query: createMessage,
        variables: {
          input: {
            content: sanitizedInputValue,
            contributor: profileid,
            messagesListID: listId,
            owner: currentUser.attributes.sub,
            ip_addr: ipAddress,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }); */

      const newMessage = await response.json();

      const profileData = await API.graphql({
        query: getPublicProfilesByUserProfileId,
        variables: {
          userProfileId: profileid,
          limit: 1,
        },
      });
      const profile = profileData.data.publicProfilesByUserProfileId.items[0];
      const nickname = profile ? profile.nickname : "";

      // キャッシュを更新
      const cachedMessages =
        JSON.parse(localStorage.getItem(`messages_${listId}`)) || [];
      const updatedMessages = [
        ...cachedMessages,
        { ...newMessage, nickname, hiddenFrom: newMessage.hiddenFrom || [] },
      ];
      localStorage.setItem(
        `messages_${listId}`,
        JSON.stringify(updatedMessages)
      );

      setMessages((prevMessages) => [
        ...prevMessages,
        { ...newMessage, nickname, hiddenFrom: newMessage.hiddenFrom || [] },
      ]);
      setInputValue("");

      await toast.success("メッセージを送信しました", {
        autoClose: 900,
      });

      await updateCheckedStatus(listId, profileid);
      await setCheckedAt();
    } catch (err) {
      console.error("Error adding message:", err);
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleHideMessage = async (messageId, messageCreatedAt) => {
    try {
      const messageDate = await new Date(messageCreatedAt);
      const currentDate = await new Date();

      // メッセージが1ヶ月以上前のものであればキャッシュだけ削除
      if ((currentDate - messageDate) / (1000 * 60 * 60 * 24) > 30) {
        // クライアント側の状態を更新
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== messageId)
        );

        // キャッシュを更新
        const cachedMessages =
          (await JSON.parse(localStorage.getItem(`messages_${listId}`))) || [];
        const updatedMessages = cachedMessages.filter(
          (msg) => msg.id !== messageId
        );
        await localStorage.setItem(
          `messages_${listId}`,
          JSON.stringify(updatedMessages)
        );

        toast.success("メッセージを削除しました", {
          autoClose: 900,
        });
        return; // 処理を終了
      }
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const res = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/hiddenMessages",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            id: messageId,
            profileId: profileid,
          }),
        }
      );

      if (res.status === 200) {
        const responseBody = await res.json();
        if (
          responseBody.message ===
          "Message not found, but request processed successfully"
        ) {
          // メッセージが見つからなかった場合、ローカルのキャッシュだけを削除
          // クライアント側の状態を更新
          setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== messageId)
          );

          // キャッシュを更新
          const cachedMessages =
            JSON.parse(localStorage.getItem(`messages_${listId}`)) || [];
          const updatedMessages = cachedMessages.filter(
            (msg) => msg.id !== messageId
          );
          localStorage.setItem(
            `messages_${listId}`,
            JSON.stringify(updatedMessages)
          );

          toast.success("メッセージを削除しました", {
            autoClose: 900,
          });
          return; // 処理を終了
        }
      }

      if (res.status !== 200) {
        throw new Error("Failed");
      }

      // クライアント側の状態を更新
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== messageId)
      );

      // キャッシュを更新
      const cachedMessages =
        JSON.parse(localStorage.getItem(`messages_${listId}`)) || [];
      const updatedMessages = cachedMessages.map((msg) =>
        msg.id === messageId
          ? { ...msg, hiddenFrom: [...(msg.hiddenFrom || []), profileid] }
          : msg
      );
      localStorage.setItem(
        `messages_${listId}`,
        JSON.stringify(updatedMessages)
      );

      await setCheckedAt();

      toast.success("メッセージを削除しました", {
        autoClose: 900,
      });
    } catch (err) {
      console.error("Error hiding message:", err);
      toast.error("メッセージの削除に失敗しました");
    }
  };

  // 送信取り消しメソッド
  async function handleDeleteMessage(messageId) {
    try {
      const currentUserProfileId = profileid; // ここでcurrentUserのprofileidを取得

      // 送信取り消しするメッセージを取得
      const messageData = await API.graphql({
        query: getMessage,
        variables: {
          id: messageId,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const message = messageData.data.getMessage;

      // メッセージが2日前のものであれば、処理を中止
      if (new Date() - new Date(message.createdAt) > 2 * 24 * 60 * 60 * 1000) {
        toast.error("メッセージ送信取り消しは1週間以内に限ります");
        return;
      }

      // メッセージを送信取り消しできるのは、contributorがcurrentUserのprofileidと一致する場合のみ
      if (message.contributor === currentUserProfileId) {
        // メッセージを更新（deletedAtフィールドを設定）
        await API.graphql({
          query: updateMessage,
          variables: {
            input: {
              id: messageId,
              deletedAt: new Date().toISOString(),
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === messageId
              ? { ...msg, deletedAt: new Date().toISOString() }
              : msg
          )
        );

        // キャッシュを更新
        const cachedMessages =
          JSON.parse(localStorage.getItem(`messages_${listId}`)) || [];
        const updatedMessages = cachedMessages.map((msg) =>
          msg.id === messageId
            ? { ...msg, deletedAt: new Date().toISOString() }
            : msg
        );
        localStorage.setItem(
          `messages_${listId}`,
          JSON.stringify(updatedMessages)
        );

        await toast.success("メッセージ送信を取り消しました", {
          autoClose: 900,
        });
      } else {
        toast.error("自分のメッセージのみ送信取り消しできます");
      }
    } catch (err) {
      console.error("Error deleting message:", err);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.isComposing) {
      e.preventDefault();
      addMessage(e);
    }
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  return (
    <div className={styles.messageContainer}>
      <ToastContainer />
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}></div>
        </div>
      )}
      {!isLoading && (
        <>
          {(partnerProfileImageUrl || isBlocked) && (
            <div className={styles.partnerInfo}>
              <Link
                to={`/userprofile/${partnerProfileId}`}
                className={styles.partnerNickname}
              >
                {partnerProfileImageUrl &&
                partnerProfileImageUrl !==
                  "https://d1s9xshr26t6r2.cloudfront.net/public/profileImages/default_images/user_placeholder.png" ? (
                  <img
                    src={partnerProfileImageUrl}
                    alt="Partner Profile"
                    className={styles.partnerProfileImage}
                  />
                ) : (
                  <img
                    src={require("../assets/user_placeholder.png")}
                    alt="Partner Profile"
                    className={styles.partnerProfileImage}
                  />
                )}
              </Link>
              <Link
                to={`/userprofile/${partnerProfileId}`}
                className={styles.partnerNickname}
              >
                {partnerNickname}
              </Link>
            </div>
          )}

          <div className={styles.messagesContainer}>
            {messages
              .filter((message) => !message.hiddenFrom?.includes(profileid)) // ここでフィルタリングする
              .slice()
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((message) => (
                <div
                  key={message.id}
                  className={`${styles.messageContent} ${
                    message.contributor === profileid ? styles.self : ""
                  }`}
                >
                  <div className={styles.authorAndTimestamp}>
                    {message.contributor === profileid ? (
                      <>
                        {/*   <span className={styles.messageTimestamp}>
                          {new Date(message.createdAt).toLocaleString()}
                        </span> */}
                        <span className={styles.messageTimestamp}>
                          {new Date(message.createdAt).toLocaleString("ja-JP", {
                            /* year: "numeric", */
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>

                        <span className={styles.messageAuthor}>
                          {message.nickname || "匿名"}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className={styles.messageAuthor}>
                          {message.nickname || "匿名"}
                        </span>
                        {/*  <span className={styles.messageTimestamp}>
                          {new Date(message.createdAt).toLocaleString()}
                        </span> */}
                        <span className={styles.messageTimestamp}>
                          {new Date(message.createdAt).toLocaleString("ja-JP", {
                            /* year: "numeric", */
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </>
                    )}
                  </div>
                  <Modal
                    isOpen={isDeleteConfirmOpen}
                    onRequestClose={closeDeleteConfirm}
                    style={confirmModalStyles}
                    contentLabel="Delete Confirm Modal"
                  >
                    <h2>
                      メッセージを削除しますか？
                      <br />
                      (自分だけ削除されます)
                    </h2>
                    <div className={styles.buttonContainer}>
                      <button
                        onClick={() => {
                          handleHideMessage(
                            currentMessageIdForAction,
                            currentMessageCreatedAtForAction
                          ); // 修正
                          closeDeleteConfirm(); // 追加
                        }}
                      >
                        はい
                      </button>
                      <button onClick={closeDeleteConfirm}>いいえ</button>
                    </div>
                  </Modal>

                  <Modal
                    isOpen={isUndoConfirmOpen}
                    onRequestClose={closeUndoConfirm}
                    style={confirmModalStyles}
                    contentLabel="Undo Confirm Modal"
                  >
                    <h2>
                      送信を取り消しますか？ <br />
                      (双方のメッセージが削除されます)
                    </h2>
                    <div className={styles.buttonContainer}>
                      <button
                        onClick={() => {
                          handleDeleteMessage(currentMessageIdForAction);
                          closeUndoConfirm(); // 追加
                        }}
                      >
                        はい
                      </button>
                      <button onClick={closeUndoConfirm}>いいえ</button>
                    </div>
                  </Modal>

                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={reportModalStyles}
                    contentLabel="Report Modal"
                  >
                    <h2>通報理由を選択してください</h2>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="Violent_post"
                          checked={reportReason === "Violent_post"}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        暴力的な投稿
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Sexual_post"
                          checked={reportReason === "Sexual_post"}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        性的な投稿
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Discriminatory_post"
                          checked={reportReason === "Discriminatory_post"}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        差別的な投稿
                      </label>
                      <br />
                      <label>
                        <input
                          type="radio"
                          value="Criminal_post"
                          checked={reportReason === "Criminal_post"}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        犯罪的な投稿
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Abusive_post"
                          checked={reportReason === "Abusive_post"}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        虐待的な投稿
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Fraudulent_post"
                          checked={reportReason === "Fraudulent_post"}
                          onChange={(e) => setReportReason(e.target.value)}
                        />
                        詐欺的な投稿
                      </label>
                    </div>
                    <textarea
                      className={styles.modalTextarea}
                      placeholder="任意:通報内容を記入(200文字以内)"
                      value={additionalInfo}
                      onChange={(e) =>
                        setAdditionalInfo(e.target.value.slice(0, 200))
                      }
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        minHeight: "150px",
                      }} // 追加
                    />

                    <div className={styles.buttonContainer}>
                      {" "}
                      <button
                        className={styles.sendReportButton}
                        onClick={() => {
                          if (isReportFormValid()) {
                            handleReportSubmit(currentMessageId);
                            closeModal(); // 追加
                          } else {
                            toast.error("通報理由を選択してください");
                          }
                        }}
                        disabled={!isReportFormValid()} // 送信ボタンを無効にする条件
                      >
                        送信
                      </button>
                      <button onClick={closeModal}>キャンセル</button>
                    </div>
                  </Modal>
                  <span>
                    {message.deletedAt
                      ? "送信が取り消されました。"
                      : message.content}
                  </span>

                  {/*  <span>
                    {message.deletedAt
                      ? "送信が取り消されました。"
                      : message.content.match(/.{1,28}/g).map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                  </span> */}
                  <div className={styles.iconContainer}>
                    {message.contributor === profileid &&
                      !message.deletedAt &&
                      new Date() - new Date(message.createdAt) <=
                        2 * 24 * 60 * 60 * 1000 && (
                        <FaCommentSlash
                          className={`${styles.deleteIcon} ${styles.additionalStyles}`}
                          onClick={() => openUndoConfirm(message.id)}
                        />
                      )}
                    {!message.deletedAt && (
                      <>
                        <FaRegTrashCan
                          className={`${styles.hideIcon} ${styles.additionalStyles}`}
                          onClick={() =>
                            openDeleteConfirm(message.id, message.createdAt)
                          }
                        />

                        {message.contributor !== profileid && (
                          <FaFlag
                            className={`${styles.reportIcon} ${styles.additionalStyles}`}
                            onClick={() => handleReportMessage(message.id)}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            <div ref={messagesEndRef} />
          </div>
          {isBlocked ? (
            <div className={styles.blockedMessage}>
              ブロックしているためメッセージは送れません
            </div>
          ) : (
            <form
              className={styles.inputAndButtonContainer}
              onSubmit={addMessage}
            >
              <textarea
                className={styles.messageInput}
                value={inputValue}
                onChange={(e) => {
                  if (e.target.value.length <= 70) {
                    setInputValue(e.target.value);
                  }
                }}
                placeholder="メッセージを入力"
                rows="1"
                disabled={isSubmitting}
                onKeyPress={handleKeyPress}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
              />

              <button
                type="submit"
                className={`${styles.sendMessageButton} ${
                  isSubmitting || inputValue.trim() === ""
                    ? styles.buttonDisabled
                    : ""
                }`}
                disabled={isSubmitting || inputValue.trim() === ""}
              >
                送信
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
}

export default Message;
