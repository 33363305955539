import React, { useState, useEffect } from "react";
import { API, Auth, Storage } from "aws-amplify";
import { useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import { updatePost } from "../graphql/mutations";
import JapanProvinceList from "../containers/JapanProvinceList";
import JapanCitiesList from "../containers/JapanCitiesList";
import { useLocation } from "react-router-dom";
import styles from "../styles/PostForm.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import Turnstile from "react-turnstile"; // Turnstileのインポート

const PostForm = () => {
  const [formState, setFormState] = useState({
    title: "",
    content: "",
    category: "recruiting",
    postCountry: "Japan",
    postProvince: "",
    postCity: "",
    userProfileId: "",
    owner: "",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    deletedAt: null,
    options: [],
    postingStatus: "ACTIVE",
    postingViews: 0,
    favoritesCount: 0,
    commentsCount: 0,
    postImageUrl1: "",
    /* postImageUrl2: "",
    postImageUrl3: "", */
    postDetails: {
      jobPostDetails: {
        wageStructure: "",
        payCurrency: "",
        salary: "",
        workLocation: "",
        employmentType: "",
        jobCategory: "",
        contactPerson: "",
        email: "",
        phone: "",
        websiteURL: "",
      },
      rentRealEstatePostDetails: {
        rentRealEstateCategory: "",
        payCurrency: "",
        builtYear: "",
        floorPlan: "",
        exclusiveArea: "",
        rent: "",
        deposit: "",
        keyMoney: "",
        moveInDate: "",
        rentRegion: "",
        contactPerson: "",
        email: "",
        websiteURL: "",
      },
      saleRealEstatePostDetails: {
        saleRealEstateCategory: "",
        payCurrency: "",
        salePrice: "",
        floorPlan: "",
        landArea: "",
        buildingArea: "",
        builtYear: "",
        availableDate: "",
        saleRegion: "",
        contactPerson: "",
        email: "",
        websiteURL: "",
      },
      memberRecruitmentDetails: {
        eventDate: "",
        payCurrency: "",
        subCategory: "",
        recruitmentAge: "",
        participationFee: "",
        email: "",
        websiteURL: "",
      },
      eventDetails: {
        startDate: "",
        endDate: "",
        subCategory: "",
        payCurrency: "",
        participationFee: "",
        eventTime: "",
        email: "",
        websiteURL: "",
      },
      bbsDetails: {
        subCategory: "",
      },
    },
    allowDirectMessages: false,
    allowEmailContacts: false,
    emailForContact: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const initialImagePath = "../assets/news_sample.png";
  const [fileData, setFileData] = useState([
    { src: initialImagePath, isInitialImage: true },
  ]);

  /* const [fileData, setFileData] = useState(
    location.state ? location.state.file : [initialImagePath]
  ); */
  /* const [fileData, setFileData] = useState(
    location.state
      ? location.state.file
      : [require("../assets/news_sample.png")]
  ); */
  const [objectUrl, setObjectUrl] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isContentValid, setIsContentValid] = useState(true);
  const [hasFileData, setHasFileData] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [titleLength, setTitleLength] = useState(0);
  const [contentLength, setContentLength] = useState(0);
  const profileid = useSelector((state) => state.userProfile.profileid);
  const [fileError, setFileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false); // Turnstile認証の状態を管理

  const categories = {
    recruiting: "求人募集",
    joinus: "メンバー募集",
    event: "イベント",
    rentrealestate: "賃貸不動産",
    salerealestate: "売買不動産",
    bbs: "掲示板",
  };

  const handleProvinceChange = (event) => {
    const selectedProvince = event.target.value;
    setFormState((currentState) => ({
      ...currentState,
      postProvince: selectedProvince,
      postCity: "", // 県が変更されたら市をリセット
    }));
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return ""; // number が null または undefined の場合は空文字列を返す
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (number) => {
    if (number === null || number === undefined) return ""; // number が null または undefined の場合は空文字列を返す
    return number.toString().replace(/,/g, "");
  };

  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 120,
    height: 120,
  });

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const userId = userInfo.attributes.sub;
        setFormState((currentState) => ({ ...currentState, owner: userId }));
      } catch (err) {
        console.error("Error fetching user info:", err);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    if (!location.state) {
      const initialImage = require("../assets/news_sample.png");
      setFileData([initialImage]);
    }
  }, [location.state]);

  useEffect(() => {
    if (fileData && fileData.length > 0) {
      setHasFileData(true);
    } else {
      setHasFileData(false);
    }
  }, [fileData]);

  useEffect(() => {
    if (fileData.length === 1 && typeof fileData[0] === "string") {
      const objectUrls = [];
      fileData.forEach((file) => {
        const img = new Image();
        img.src = file;
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 120;
          canvas.height = 120;

          ctx.fillStyle = "#fff"; // 背景色を白に設定
          ctx.fillRect(0, 0, canvas.width, canvas.height); // 背景を塗りつぶす

          ctx.drawImage(img, 0, 0, 120, 120);
          const resizedImageUrl = canvas.toDataURL("image/jpeg");
          objectUrls.push(resizedImageUrl);
          await setObjectUrl(objectUrls);
        };
      });
    }
  }, [fileData]);

  useEffect(() => {
    const generateObjectUrls = async () => {
      const objectUrls = [];
      for (const file of fileData) {
        if (file instanceof File) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = 120;
              canvas.height = 120;

              ctx.fillStyle = "#fff"; // 背景色を白に設定
              ctx.fillRect(0, 0, canvas.width, canvas.height); // 背景を塗りつぶす

              ctx.drawImage(img, 0, 0, 120, 120);
              const resizedImageUrl = canvas.toDataURL("image/jpeg");
              objectUrls.push(resizedImageUrl);
              if (objectUrls.length === fileData.length) {
                setObjectUrl(objectUrls);
              }
            };
          };
          reader.readAsDataURL(file);
        } else if (typeof file === "string") {
          objectUrls.push(file);
          if (objectUrls.length === fileData.length) {
            setObjectUrl(objectUrls);
          }
        }
      }
    };

    if (fileData.length > 0) {
      generateObjectUrls();
    }
  }, [fileData]);

  useEffect(() => {
    if (fileData && fileData.length > 0) {
      setHasFileData(true);
    } else {
      setHasFileData(false);
    }
  }, [fileData]);

  useEffect(() => {
    if (
      formState.postDetails.eventDetails.startDate >
      formState.postDetails.eventDetails.endDate
    ) {
      setFormState({
        ...formState,
        postDetails: {
          ...formState.postDetails,
          eventDetails: {
            ...formState.postDetails.eventDetails,
            endDate: formState.postDetails.eventDetails.startDate,
          },
        },
      });
    }
  }, [
    formState.postDetails.eventDetails.startDate,
    formState.postDetails.eventDetails.endDate,
  ]);

  /*  useEffect(() => {
    const setFormCurrentState = async () => {
      try {
        setFormState((currentState) => ({ ...currentState }));
      } catch (err) {
        console.error("Error fetching IP address:", err);
      }
    };

    setFormCurrentState();
  }, []); */

  const handleTurnstileVerify = async (token) => {
    fetch(
      "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/verifyTurnstile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
        },
        body: JSON.stringify({ token }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setIsTurnstileVerified(true); // 認証成功時に状態を更新
        } else {
          setIsTurnstileVerified(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsTurnstileVerified(false);
      });
  };

  const loadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
    });
  };

  const isEmailValid = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

    const japaneseRegex =
      /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]/;

    if (japaneseRegex.test(email)) {
      return false; // メールアドレスに日本語が含まれている場合は無効
    }

    return emailRegex.test(email);
  };

  const addPost = async () => {
    try {
      setIsOverlayVisible(true);
      setIsSubmitting(true);

      const user = await Auth.currentAuthenticatedUser();
      const owner = await user.attributes.sub;
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      if (
        !formState.title ||
        !formState.content ||
        !formState.category ||
        !formState.postProvince
      ) {
        toast.error("すべての必須フィールドを入力してください", {
          autoClose: 2000,
        });
        setIsOverlayVisible(false);
        setIsSubmitting(false);
        return;
      }

      // 給与金額が入力されている場合に給与体系が必須であることを確認する
      if (
        formState.category === "recruiting" &&
        formState.postDetails.jobPostDetails.salary &&
        !formState.postDetails.jobPostDetails.wageStructure
      ) {
        toast.error("給与金額を入力した場合、給与体系は必須です", {
          autoClose: 2000,
        });
        setIsOverlayVisible(false);
        setIsSubmitting(false);
        return;
      }

      let postDetails = {};
      switch (formState.category) {
        case "recruiting":
          postDetails = {
            jobPostDetails: formState.postDetails.jobPostDetails,
          };
          break;
        case "joinus":
          postDetails = {
            memberRecruitmentDetails:
              formState.postDetails.memberRecruitmentDetails,
          };
          break;
        case "event":
          postDetails = { eventDetails: formState.postDetails.eventDetails };
          break;
        case "rentrealestate":
          postDetails = {
            rentRealEstatePostDetails:
              formState.postDetails.rentRealEstatePostDetails,
          };
          break;
        case "salerealestate":
          postDetails = {
            saleRealEstatePostDetails:
              formState.postDetails.saleRealEstatePostDetails,
          };
          break;
        case "bbs":
          postDetails = { bbsDetails: formState.postDetails.bbsDetails };
          break;
        default:
          break;
      }

      // 通貨をJPYに設定
      if (postDetails.jobPostDetails) {
        postDetails.jobPostDetails.payCurrency = "JPY";
      }
      if (postDetails.rentRealEstatePostDetails) {
        postDetails.rentRealEstatePostDetails.payCurrency = "JPY";
      }
      if (postDetails.saleRealEstatePostDetails) {
        postDetails.saleRealEstatePostDetails.payCurrency = "JPY";
      }
      if (postDetails.memberRecruitmentDetails) {
        postDetails.memberRecruitmentDetails.payCurrency = "JPY";
      }
      if (postDetails.eventDetails) {
        postDetails.eventDetails.payCurrency = "JPY";
      }

      const response = await fetch(
        "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/posts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            title: formState.title,
            content: formState.content,
            category: formState.category,
            postCountry: formState.postCountry,
            postProvince: formState.postProvince,
            postCity: formState.postCity,
            owner: owner,
            userProfileId: profileid,
            postDetails: postDetails,
            allowDirectMessages: formState.allowDirectMessages,
            allowEmailContacts: formState.allowEmailContacts,
            emailForContact: formState.emailForContact,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error creating post");
      }

      const data = await response.json();
      const postId = data.id;
      const [postImageUrl1] = await handleCropAndUpload(postId);

      if (!postImageUrl1) {
        toast.warn("投稿は完了しましたが、画像のアップロードに失敗しました", {
          autoClose: 4000,
        });
      } else {
        await API.graphql({
          query: updatePost,
          variables: {
            input: { id: postId, postImageUrl1 },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        toast.success("投稿が完了しました");
      }

      setTimeout(() => {
        setFormState({
          title: "",
          content: "",
          category: "",
          owner: "",
          postProvince: "",
          postDetails: getDefaultPostDetails(),
          allowDirectMessages: false,
          allowEmailContacts: false,
          emailForContact: "",
        });
        setFileData([]);
        setObjectUrl([]);
        setIsSubmitting(false);
        setIsOverlayVisible(false);
        navigate("/mypage#posts");
      }, 2000);
    } catch (err) {
      console.error("Error adding post:", err);
      toast.error("投稿に失敗しました", { autoClose: 3500 });
      setIsSubmitting(false);
      setIsOverlayVisible(false);
    }
  };

  const getDefaultPostDetails = () => ({
    jobPostDetails: {
      salary: 0,
      workLocation: "",
      employmentType: "",
      employmentWage: 0,
      jobCategory: "",
      contactPerson: "",
      email: "",
      phone: "",
      websiteURL: "",
    },
    rentRealEstatePostDetails: {
      rentRealEstateCategory: "",
      builtYear: 0,
      floorPlan: "",
      rent: 0,
      deposit: 0,
      keyMoney: 0,
      moveInDate: "",
      rentRegion: "",
      contactPerson: "",
      email: "",
      websiteURL: "",
    },
    saleRealEstatePostDetails: {
      saleRealEstateCategory: "",
      salePrice: 0,
      floorPlan: "",
      builtYear: 0,
      availableDate: "",
      saleRegion: "",
      contactPerson: "",
      email: "",
      websiteURL: "",
    },
    memberRecruitmentDetails: {
      eventDate: "",
      subCategory: "",
      recruitmentAge: "",
      participationFee: 0,
      email: "",
      websiteURL: "",
    },
    eventDetails: {
      startDate: "",
      endDate: "",
      subCategory: "",
      participationFee: 0,
      eventTime: "",
      email: "",
      websiteURL: "",
    },
    bbsDetails: {
      subCategory: "",
    },
  });

  const handleCropAndUpload = async (postId) => {
    try {
      const uploadedImages = [];

      for (let i = 0; i < objectUrl.length; i++) {
        if (String(fileData[i]).includes("9j/4AAQSkZJRgABAQAAAQABAAD")) {
          // 初期画像の場合、S3にアップロードせずローカルのパスを使用
          uploadedImages.push(initialImagePath);
        } else {
          const canvas = document.createElement("canvas");
          canvas.width = 120;
          canvas.height = 120;

          const ctx = canvas.getContext("2d");
          const img = await loadImage(objectUrl[i]);
          ctx.drawImage(img, 0, 0, 120, 120);

          const result = await new Promise((resolve) =>
            canvas.toBlob(resolve, "image/jpeg")
          );
          const fileName = `image${i + 1}.jpg`;
          const uploadedImage = await Storage.put(
            `posts/${postId}/${fileName}`,
            result,
            {
              contentType: "image/jpg",
              bucket: process.env.REACT_APP_S3_BUCKET_PROD,
              region: process.env.REACT_APP_S3_REGION,
            }
          );

          uploadedImages.push(uploadedImage.key);
        }
      }

      const imageUrls = uploadedImages.map((key, index) => {
        return key ? `${key}` : null;
      });

      return [imageUrls[0] || null, imageUrls[1] || null, imageUrls[2] || null];
    } catch (err) {
      console.error("Error updating post:", err);
      return [null, null, null];
    }
  };

  const setInput = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    if (key === "title") {
      setIsTitleValid(
        sanitizedValue.length >= 3 && sanitizedValue.length <= 50
      );
      setTitleLength(sanitizedValue.length); // タイトルの文字数をカウント
    } else if (key === "content") {
      setIsContentValid(
        sanitizedValue.length >= 10 && sanitizedValue.length <= 700
      );
      setContentLength(sanitizedValue.length);
    }

    setFormState({ ...formState, [key]: sanitizedValue });
  };

  const setJobPostDetail = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormState({
      ...formState,
      postDetails: {
        ...formState.postDetails,
        jobPostDetails: {
          ...formState.postDetails.jobPostDetails,
          [key]: sanitizedValue,
        },
      },
    });
  };

  const setJoinusPostDetail = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormState({
      ...formState,
      postDetails: {
        ...formState.postDetails,
        memberRecruitmentDetails: {
          ...formState.postDetails.memberRecruitmentDetails,
          [key]: sanitizedValue,
        },
      },
    });
  };

  const setEventDetail = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormState({
      ...formState,
      postDetails: {
        ...formState.postDetails,
        eventDetails: {
          ...formState.postDetails.eventDetails,
          [key]: sanitizedValue,
        },
      },
    });
  };

  const setRentRealEstatePostDetail = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormState((prevState) => ({
      ...prevState,
      postDetails: {
        ...prevState.postDetails,
        rentRealEstatePostDetails: {
          ...prevState.postDetails.rentRealEstatePostDetails,
          [key]: sanitizedValue,
        },
      },
    }));
  };

  const setSaleRealEstatePostDetail = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setFormState((prevState) => ({
      ...prevState,
      postDetails: {
        ...prevState.postDetails,
        saleRealEstatePostDetails: {
          ...prevState.postDetails.saleRealEstatePostDetails,
          [key]: sanitizedValue,
        },
      },
    }));
  };

  const renderCategorySpecificFields = () => {
    switch (formState.category) {
      case "recruiting":
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>給与体系：</label>
              <select
                value={formState.postDetails.jobPostDetails.wageStructure}
                onChange={(e) =>
                  setJobPostDetail("wageStructure", e.target.value)
                }
              >
                <option value="">給与体系を選択</option>
                <option value="annual_income">年収</option>
                <option value="monthly_income">月給</option>
                <option value="daily_income">日給</option>
                <option value="hourly_income">時給</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>給与金額：</label>
              <input
                type="text"
                placeholder="給与金額"
                value={formatNumberWithCommas(
                  formState.postDetails.jobPostDetails.salary
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setJobPostDetail("salary", validValue);
                }}
                maxLength="10"
              />
            </div>

            <div className={styles.formRow}>
              <label>雇用形態：</label>
              <select
                value={formState.postDetails.jobPostDetails.employmentType}
                onChange={(e) =>
                  setJobPostDetail("employmentType", e.target.value)
                }
              >
                <option value="">雇用形態を選択</option>
                <option value="full_time">正社員</option>
                <option value="contract">契約社員</option>
                <option value="part_time">アルバイト</option>
                <option value="freelance">業務委託</option>
                <option value="executive">役員</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>職種：</label>
              <input
                type="text"
                placeholder="職種"
                value={formState.postDetails.jobPostDetails.jobCategory}
                onChange={(e) =>
                  setJobPostDetail("jobCategory", e.target.value)
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>勤務地：</label>
              <input
                type="text"
                placeholder="勤務地"
                value={formState.postDetails.jobPostDetails.workLocation}
                onChange={(e) =>
                  setJobPostDetail("workLocation", e.target.value)
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者名：</label>
              <input
                type="text"
                placeholder="担当者名"
                value={formState.postDetails.jobPostDetails.contactPerson}
                onChange={(e) =>
                  setJobPostDetail("contactPerson", e.target.value)
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={formState.postDetails.jobPostDetails.email}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  setJobPostDetail("email", validValue);
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者電話番号：</label>
              <input
                type="tel"
                placeholder="担当者電話番号"
                value={formState.postDetails.jobPostDetails.phone}
                onChange={(e) => {
                  const validValue = e.target.value.replace(/[^\d\+]/g, ""); // 半角数字と+以外を排除
                  setJobPostDetail("phone", validValue);
                }}
                maxLength="14"
                pattern="^\+?[0-9]*$"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL:</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={formState.postDetails.jobPostDetails.websiteURL}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  setJobPostDetail("websiteURL", validValue);
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "joinus":
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>活動頻度：</label>
              <input
                type="text"
                placeholder="活動頻度 (ex: 毎週土曜日, 月1回)"
                value={formState.postDetails.memberRecruitmentDetails.eventDate}
                onChange={(e) =>
                  setJoinusPostDetail("eventDate", e.target.value)
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>サブカテゴリ：</label>
              <select
                value={
                  formState.postDetails.memberRecruitmentDetails.subCategory
                }
                onChange={(e) =>
                  setJoinusPostDetail("subCategory", e.target.value)
                }
              >
                <option value="">サブカテゴリを選択</option>
                <option value="sports">スポーツ</option>
                <option value="fave">推し活</option>
                <option value="gaming">ゲーム</option>
                <option value="travel_buddies">旅行仲間</option>
                <option value="festival">祭り</option>
                <option value="music">音楽</option>
                <option value="art">アート</option>
                <option value="theater_show">演劇/ショー</option>
                <option value="exhibition">展示会</option>
                <option value="parenting">育児</option>
                <option value="party">パーティー</option>
                <option value="seminar">セミナー</option>
                <option value="flea_market">フリーマーケット</option>
                <option value="concert">コンサート</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>募集年齢：</label>
              <input
                type="text"
                placeholder="募集年齢"
                value={
                  formState.postDetails.memberRecruitmentDetails.recruitmentAge
                }
                onChange={(e) =>
                  setJoinusPostDetail("recruitmentAge", e.target.value)
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>参加費：</label>
              <input
                type="text"
                placeholder="参加費"
                value={formatNumberWithCommas(
                  formState.postDetails.memberRecruitmentDetails
                    .participationFee
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setJoinusPostDetail("participationFee", validValue);
                }}
                maxLength="7"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={formState.postDetails.memberRecruitmentDetails.email}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  setJoinusPostDetail("email", validValue);
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL:</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={
                  formState.postDetails.memberRecruitmentDetails.websiteURL
                }
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  setJoinusPostDetail("websiteURL", validValue);
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "event":
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>イベント開始日：</label>
              <input
                type="date"
                placeholder="イベント開始日"
                value={formState.postDetails.eventDetails.startDate}
                onChange={(e) => setEventDetail("startDate", e.target.value)}
              />
            </div>

            <div className={styles.formRow}>
              <label>イベント終了日：</label>
              <input
                type="date"
                placeholder="イベント終了日"
                value={formState.postDetails.eventDetails.endDate}
                onChange={(e) => setEventDetail("endDate", e.target.value)}
              />
            </div>

            <div className={styles.formRow}>
              <label>サブカテゴリ：</label>
              <select
                value={formState.postDetails.eventDetails.subCategory}
                onChange={(e) => setEventDetail("subCategory", e.target.value)}
              >
                <option value="">サブカテゴリを選択</option>
                <option value="sports">スポーツ</option>
                <option value="fave">推し活</option>
                <option value="gaming">ゲーム</option>
                <option value="travel_buddies">旅行仲間</option>
                <option value="festival">祭り</option>
                <option value="music">音楽</option>
                <option value="art">アート</option>
                <option value="theater_show">演劇/ショー</option>
                <option value="exhibition">展示会</option>
                <option value="parenting">育児</option>
                <option value="party">パーティー</option>
                <option value="seminar">セミナー</option>
                <option value="flea_market">フリーマーケット</option>
                <option value="concert">コンサート</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>参加費：</label>
              <input
                type="text"
                placeholder="参加費"
                value={formatNumberWithCommas(
                  formState.postDetails.eventDetails.participationFee
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setEventDetail("participationFee", validValue);
                }}
                maxLength="7"
              />
            </div>

            <div className={styles.formRow}>
              <label>イベント時間：</label>
              <input
                type="text"
                placeholder="イベント時間(ex:10:00~17:00)"
                value={formState.postDetails.eventDetails.eventTime}
                onChange={(e) => setEventDetail("eventTime", e.target.value)}
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={formState.postDetails.eventDetails.email}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  setEventDetail("email", validValue);
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={formState.postDetails.eventDetails.websiteURL}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  setEventDetail("websiteURL", validValue);
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "rentrealestate":
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>賃貸カテゴリ：</label>
              <select
                value={
                  formState.postDetails.rentRealEstatePostDetails
                    .rentRealEstateCategory
                }
                onChange={(e) =>
                  setRentRealEstatePostDetail(
                    "rentRealEstateCategory",
                    e.target.value
                  )
                }
              >
                <option value="">賃貸カテゴリを選択</option>
                <option value="rental_apartment">賃貸マンション</option>
                <option value="rental_house">賃貸戸建</option>
                <option value="rental_land">貸土地</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>家賃：</label>
              <input
                type="text"
                placeholder="家賃"
                value={formatNumberWithCommas(
                  formState.postDetails.rentRealEstatePostDetails.rent
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setRentRealEstatePostDetail("rent", validValue);
                }}
                maxLength="8"
              />
            </div>

            <div className={styles.formRow}>
              <label>敷金：</label>
              <input
                type="text"
                placeholder="敷金"
                value={formatNumberWithCommas(
                  formState.postDetails.rentRealEstatePostDetails.deposit
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setRentRealEstatePostDetail("deposit", validValue);
                }}
                maxLength="8"
              />
            </div>

            <div className={styles.formRow}>
              <label>礼金：</label>
              <input
                type="text"
                placeholder="礼金"
                value={formatNumberWithCommas(
                  formState.postDetails.rentRealEstatePostDetails.keyMoney
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setRentRealEstatePostDetail("keyMoney", validValue);
                }}
                maxLength="8"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                専有面積(m<sup>2</sup>)：
              </label>
              <input
                type="text"
                placeholder="専有面積(m2)"
                value={
                  formState.postDetails.rentRealEstatePostDetails.exclusiveArea
                }
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1); // 2桁以上の場合、0からスタートを禁止
                  }
                  if (value.length <= 4) {
                    setRentRealEstatePostDetail("exclusiveArea", value);
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>築年数：</label>
              <input
                type="text"
                placeholder="築年数"
                value={
                  formState.postDetails.rentRealEstatePostDetails.builtYear
                }
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1); // 2桁以上の場合、0からスタートを禁止
                  }
                  if (value.length <= 3) {
                    setRentRealEstatePostDetail("builtYear", value);
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>間取り：</label>
              <input
                type="text"
                placeholder="間取り(ex:2LDK,1Bedroom +1Bathroom)"
                value={
                  formState.postDetails.rentRealEstatePostDetails.floorPlan
                }
                onChange={(e) =>
                  setRentRealEstatePostDetail("floorPlan", e.target.value)
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>物件所在地：</label>
              <input
                type="text"
                placeholder="物件所在地"
                value={
                  formState.postDetails.rentRealEstatePostDetails.rentRegion
                }
                onChange={(e) =>
                  setRentRealEstatePostDetail("rentRegion", e.target.value)
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>入居可能時期：</label>
              <input
                type="text"
                placeholder="入居可能時期"
                value={
                  formState.postDetails.rentRealEstatePostDetails.moveInDate
                }
                onChange={(e) =>
                  setRentRealEstatePostDetail("moveInDate", e.target.value)
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者名：</label>
              <input
                type="text"
                placeholder="担当者名"
                value={
                  formState.postDetails.rentRealEstatePostDetails.contactPerson
                }
                onChange={(e) =>
                  setRentRealEstatePostDetail("contactPerson", e.target.value)
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={formState.postDetails.rentRealEstatePostDetails.email}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  setRentRealEstatePostDetail("email", validValue);
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={
                  formState.postDetails.rentRealEstatePostDetails.websiteURL
                }
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  setRentRealEstatePostDetail("websiteURL", validValue);
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "salerealestate":
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>不動産の種類：</label>
              <select
                value={
                  formState.postDetails.saleRealEstatePostDetails
                    .saleRealEstateCategory
                }
                onChange={(e) =>
                  setSaleRealEstatePostDetail(
                    "saleRealEstateCategory",
                    e.target.value
                  )
                }
              >
                <option value="">不動産の種類を選択</option>
                <option value="new_apartment">新築マンション</option>
                <option value="used_apartment">中古マンション</option>
                <option value="new_house">新築戸建</option>
                <option value="used_house">中古戸建</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>販売価格：</label>
              <input
                type="text"
                placeholder="販売価格"
                value={formatNumberWithCommas(
                  formState.postDetails.saleRealEstatePostDetails.salePrice
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  setSaleRealEstatePostDetail("salePrice", validValue);
                }}
                maxLength="11"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                土地面積(m<sup>2</sup>)：
              </label>
              <input
                type="text"
                placeholder="土地面積(m2)"
                value={formState.postDetails.saleRealEstatePostDetails.landArea}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1); // 2桁以上の場合、0からスタートを禁止
                  }
                  if (value.length <= 4) {
                    setSaleRealEstatePostDetail("landArea", value);
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>
                建物面積(m<sup>2</sup>)：
              </label>
              <input
                type="text"
                placeholder="建物面積(m2)"
                value={
                  formState.postDetails.saleRealEstatePostDetails.buildingArea
                }
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1); // 2桁以上の場合、0からスタートを禁止
                  }
                  if (value.length <= 4) {
                    setSaleRealEstatePostDetail("buildingArea", value);
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>築年数：</label>
              <input
                type="text"
                placeholder="築年数"
                value={
                  formState.postDetails.saleRealEstatePostDetails.builtYear
                }
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1); // 2桁以上の場合、0からスタートを禁止
                  }
                  if (value.length <= 3) {
                    setSaleRealEstatePostDetail("builtYear", value);
                  }
                }}
                maxLength="3"
              />
            </div>

            <div className={styles.formRow}>
              <label>間取り：</label>
              <input
                type="text"
                placeholder="間取り(ex:2LDK,1Bedroom +1Bathroom)"
                value={
                  formState.postDetails.saleRealEstatePostDetails.floorPlan
                }
                onChange={(e) =>
                  setSaleRealEstatePostDetail("floorPlan", e.target.value)
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>物件所在地：</label>
              <input
                type="text"
                placeholder="物件所在地"
                value={
                  formState.postDetails.saleRealEstatePostDetails.saleRegion
                }
                onChange={(e) =>
                  setSaleRealEstatePostDetail("saleRegion", e.target.value)
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>引き渡し可能時期：</label>
              <input
                type="text"
                placeholder="引き渡し可能時期"
                value={
                  formState.postDetails.saleRealEstatePostDetails.availableDate
                }
                onChange={(e) =>
                  setSaleRealEstatePostDetail("availableDate", e.target.value)
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者名：</label>
              <input
                type="text"
                placeholder="担当者名"
                value={
                  formState.postDetails.saleRealEstatePostDetails.contactPerson
                }
                onChange={(e) =>
                  setSaleRealEstatePostDetail("contactPerson", e.target.value)
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={formState.postDetails.saleRealEstatePostDetails.email}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  setSaleRealEstatePostDetail("email", validValue);
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={
                  formState.postDetails.saleRealEstatePostDetails.websiteURL
                }
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  setSaleRealEstatePostDetail("websiteURL", validValue);
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "bbs":
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>サブカテゴリ：</label>
              <select
                value={formState.postDetails.bbsDetails.subCategory}
                onChange={(e) => {
                  const sanitizedValue = DOMPurify.sanitize(e.target.value);
                  setFormState({
                    ...formState,
                    postDetails: {
                      ...formState.postDetails,
                      bbsDetails: {
                        ...formState.postDetails.bbsDetails,
                        subCategory: sanitizedValue,
                      },
                    },
                  });
                }}
              >
                <option value="">サブカテゴリを選択</option>
                <option value="local_news">地元のニュース</option>
                <option value="local_shops">地元のお店</option>
                <option value="local gourmet">地元グルメ</option>
                <option value="local_products">地元の商品・サービス</option>
                <option value="chitchat">雑談</option>
                <option value="live_commentary">実況</option>
                <option value="sports">スポーツ</option>
                <option value="work">仕事</option>
                <option value="friends">友人</option>
                <option value="romance">恋愛</option>
                <option value="marriage_hunting">婚活</option>
                <option value="marriage">結婚</option>
                <option value="parenting">育児</option>
                <option value="mom_friends">ママ友</option>
                <option value="dad_friends">パパ友</option>
                <option value="relationships">人間関係</option>
                <option value="volunteer">ボランティア</option>
                <option value="money_investment">お金/投資</option>
                <option value="nursing">介護</option>
                <option value="hospital">病院</option>
                <option value="regional_revival">地方創生</option>
                <option value="local_economy">地元経済</option>
              </select>
            </div>
          </div>
        );

      default:
        return null; // 追加: 予期せぬcategoryの値の場合に何も表示しない
    }
  };

  return (
    <div className={styles.container}>
      {isOverlayVisible && <div className={styles.overlay}></div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleCropAndUpload();
        }}
      >
        <div className={styles.imageContainer}>
          {objectUrl.slice(0, 1).map((url, index) => {
            // ここを修正
            const altText = `Image ${index}`;
            return (
              <ReactCrop
                key={index}
                onChange={(c) => setCrop(c)}
                aspect={1}
                keepSelection={true}
              >
                <div key={index} style={{ marginBottom: "0px" }}></div>
                <img
                  src={url}
                  alt={altText}
                  // 画像のスタイルはCSSで設定するので削除
                />
              </ReactCrop>
            );
          })}
        </div>

        <input
          type="file"
          accept=".png, .jpg, .jpeg" // ここでファイルの種類を制限
          onChange={(e) => {
            const files = e.target.files;
            const validTypes = ["image/png", "image/jpg", "image/jpeg"];
            const validFiles = Array.from(files).filter((file) =>
              validTypes.includes(file.type)
            );

            if (validFiles.length !== files.length) {
              setFileError("PNG、JPG、JPEG以外の画像はアップロードできません");
              e.target.value = null;
            } else if (validFiles.length > 1) {
              setFileError("画像は最大1枚までしか選択できません");
              e.target.value = null;
            } else {
              setFileError(""); // エラーメッセージをクリア
              setFileData(validFiles);
            }
          }}
          multiple
        />
        <div style={{ marginBottom: "15px" }}></div>
        <h3 style={{ color: fileError ? "red" : "inherit" }}>
          画像を選択してください。画像は最大1枚まで投稿できます
        </h3>
        {fileError && <p style={{ color: "red" }}>{fileError}</p>}
      </form>
      <ToastContainer />
      <div className={styles.titleForm}>
        <textarea
          type="text"
          onChange={(event) => setInput("title", event.target.value)}
          value={formState.title}
          placeholder="タイトル(3文字以上50文字以下)"
          maxLength="50"
          cols="80"
          /* style={{ color: isTitleValid ? "inherit" : "red" }} */
        />
        <div className={styles.contentLength}>
          <p style={{ color: isTitleValid ? "inherit" : "red" }}>
            {titleLength}/50
          </p>{" "}
          {/* タイトルの文字数カウントを表示 */}
        </div>
      </div>
      <div className={styles.contentForm}>
        <textarea
          onChange={(event) => setInput("content", event.target.value)}
          value={formState.content}
          placeholder="内容(10文字以上700文字以下)"
          maxLength="700"
          rows="11"
          cols="80"
          /* style={{ color: isContentValid ? "inherit" : "red" }} */
        />
        <div className={styles.contentLength}>
          <p style={{ color: isContentValid ? "inherit" : "red" }}>
            {contentLength}/700
          </p>
        </div>
      </div>
      <div>
        <label>ダイレクトメッセージによる問い合わせの可否:</label>
        <label>
          <input
            type="radio"
            value={true}
            checked={formState.allowDirectMessages === true}
            onChange={() =>
              setFormState({ ...formState, allowDirectMessages: true })
            }
          />
          可
        </label>
        <label>
          <input
            type="radio"
            value={false}
            checked={formState.allowDirectMessages === false}
            onChange={() =>
              setFormState({ ...formState, allowDirectMessages: false })
            }
          />
          否
        </label>
      </div>
      <div>
        <label>Eメールによる問い合わせの可否:</label>
        <label>
          <input
            type="radio"
            value={true}
            checked={formState.allowEmailContacts === true}
            onChange={() =>
              setFormState({ ...formState, allowEmailContacts: true })
            }
          />
          可
        </label>
        <label>
          <input
            type="radio"
            value={false}
            checked={formState.allowEmailContacts === false}
            onChange={() =>
              setFormState({ ...formState, allowEmailContacts: false })
            }
          />
          否
        </label>
      </div>
      {formState.allowEmailContacts && (
        <div className="contactEmailForm">
          <label>問い合わせ用メールアドレス:</label>
          <input
            type="email"
            value={formState.emailForContact}
            onChange={(e) => {
              const emailValue = e.target.value;
              setFormState({ ...formState, emailForContact: emailValue });
              setEmailError(
                isEmailValid(emailValue)
                  ? ""
                  : "正しいメールアドレスを入力してください"
              );
            }}
          />
          {emailError && (
            <p style={{ color: "red", margin: "0px" }}>{emailError}</p>
          )}
        </div>
      )}
      <br />
      <div className={styles.horizontalContainer}>
        <select
          className={styles.select}
          value={formState.category}
          onChange={(event) => setInput("category", event.target.value)}
        >
          <option value="">カテゴリーを選択</option>
          {Object.entries(categories).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
        <JapanProvinceList
          className={styles.select}
          onSelect={(event) => setInput("postProvince", event.target.value)}
          onChange={handleProvinceChange}
        />
        <JapanCitiesList
          className={styles.select}
          onSelect={(event) => setInput("postCity", event.target.value)}
          selectedCity={formState.postCity}
          region={formState.postProvince}
          disabled={!formState.postProvince}
        />
      </div>
      <br />
      {renderCategorySpecificFields()}
      <Turnstile
        sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
        onVerify={handleTurnstileVerify}
      />{" "}
      <br />
      <br />
      <button
        className={styles.submitButton}
        onClick={addPost}
        disabled={
          !formState.title ||
          !formState.content ||
          !formState.category ||
          !formState.postProvince ||
          isSubmitting ||
          !isTitleValid ||
          !isContentValid ||
          !hasFileData ||
          !isTurnstileVerified ||
          (formState.allowEmailContacts &&
            !isEmailValid(formState.emailForContact)) ||
          formState.title.length < 3 || // タイトルが3文字未満
          formState.content.length < 10 // 内容が10文字未満
        }
      >
        {isSubmitting ? "投稿中..." : "投稿する"}
      </button>
    </div>
  );
};

export default PostForm;
