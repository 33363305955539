import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API, Auth, Storage } from "aws-amplify";
import JapanProvinceList from "../containers/JapanProvinceList";
import JapanCitiesList from "../containers/JapanCitiesList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { includeOwnerPosts } from "../graphql/queries";
import styles from "../styles/PostEdit.module.css";
import "react-image-crop/dist/ReactCrop.css";
import DOMPurify from "dompurify";
import ReactCrop from "react-image-crop";
import Turnstile from "react-turnstile"; // Turnstileのインポート

function PostEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [objectUrl, setObjectUrl] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [crop, setCrop] = useState({ aspect: 1 });
  const [allowDirectMessages, setAllowDirectMessages] = useState(false);
  const [allowEmailContacts, setAllowEmailContacts] = useState(false);
  const [emailForContact, setEmailForContact] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [titleLength, setTitleLength] = useState(0);
  const [isContentValid, setIsContentValid] = useState(true);
  const [contentLength, setContentLength] = useState(0);
  const [fileError, setFileError] = useState(false);
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false); // Turnstile認証の状態を管理

  const categories = {
    recruiting: "求人募集",
    joinus: "メンバー募集",
    event: "イベント",
    rentrealestate: "賃貸不動産",
    salerealestate: "売買不動産",
    bbs: "掲示板",
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postData = await API.graphql({
          query: includeOwnerPosts,
          variables: { id },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const postDetails = postData.data.getPost.postDetails || {};

        // 画像URLを取得
        const imageUrls = [
          postData.data.getPost.postImageUrl1,
          // postData.data.getPost.postImageUrl2,
          // postData.data.getPost.postImageUrl3,
        ].filter(Boolean);

        // キャッシュ機能を使って画像URLを取得
        const fetchedImages = await Promise.all(
          imageUrls.map((url) =>
            fetchPostImages(url, postData.data.getPost.updatedAt)
          )
        );

        // ここで古い画像のキーを抽出して状態に保存
        const oldImageKeys = imageUrls.map((url) => url.split("/").pop());

        setAllowDirectMessages(
          postData.data.getPost.allowDirectMessages ?? false
        );
        setAllowEmailContacts(
          postData.data.getPost.allowEmailContacts ?? false
        );
        setEmailForContact(postData.data.getPost.emailForContact);

        setPost({
          ...postData.data.getPost,
          postDetails: {
            ...postDetails,
            jobPostDetails: postDetails.jobPostDetails || {},
            memberRecruitmentDetails:
              postDetails.memberRecruitmentDetails || {},
            eventDetails: postDetails.eventDetails || {},
            rentRealEstatePostDetails:
              postDetails.rentRealEstatePostDetails || {},
            saleRealEstatePostDetails:
              postDetails.saleRealEstatePostDetails || {},
            bbsDetails: postDetails.bbsDetails || {},
          },
          images: fetchedImages,
          oldImageKeys, // 古い画像のキーを追加
        });
      } catch (err) {
        console.error("エラーが発生しました:", err);
      }
    };

    fetchPost();
  }, [id]);

  useEffect(() => {
    if (post) {
      setTitleLength(post.title.length);
      setIsTitleValid(post.title.length >= 3 && post.title.length <= 50); // ここを修正
      setContentLength(post.content.length);
      setIsContentValid(
        post.content.length >= 10 && post.content.length <= 700
      ); // ここを修正
    }
  }, [post]);

  const setCachedImageUrl = (key, url, lastModified, updatedAt) => {
    const cachedData = {
      url,
      lastModified,
      updatedAt,
      cacheTime: Date.now(), // キャッシュした時間を保存
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  };

  const getCachedImageUrl = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) {
      return null;
    }
    try {
      const parsedData = JSON.parse(cachedData);
      // parsedDataがオブジェクトであり、必要なプロパティが存在するかをチェック
      if (
        parsedData &&
        typeof parsedData === "object" &&
        "url" in parsedData &&
        "lastModified" in parsedData &&
        "cacheTime" in parsedData
      ) {
        const cacheTime = parsedData.cacheTime;
        const now = Date.now();
        const diffInMinutes = (now - cacheTime) / (1000 * 60);
        if (diffInMinutes > 144000) {
          // 144000分以上経過している場合は無効にする
          localStorage.removeItem(key);
          return null;
        }
        return parsedData;
      } else {
        console.error("Invalid cached data format:", cachedData);
        localStorage.removeItem(key); // 有効でないデータを削除
        return null;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
      localStorage.removeItem(key); // 有効でないデータを削除
      return null;
    }
  };

  const fetchPostImages = async (postImageUrl1, updatedAt) => {
    if (postImageUrl1 === "../assets/news_sample.png" || !postImageUrl1) {
      return postImageUrl1;
    }
    const cachedData = getCachedImageUrl(postImageUrl1);

    if (cachedData) {
      const cachedUpdatedAt = new Date(cachedData.updatedAt);
      const postUpdatedAt = new Date(updatedAt);
      const diffInMinutes = (postUpdatedAt - cachedUpdatedAt) / (1000 * 60);

      if (diffInMinutes < 2) {
        return cachedData.url;
      }
    }

    try {
      // CloudFrontの基本URL
      const cloudFrontBaseUrl = "https://d1s9xshr26t6r2.cloudfront.net";
      // 画像のキーをCloudFront URLに変換
      const imageUrl = `${cloudFrontBaseUrl}/public/${postImageUrl1}`;

      // CloudFront URLをキャッシュに保存
      setCachedImageUrl(postImageUrl1, imageUrl, updatedAt, updatedAt);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching post image:", error);
      return null;
    }
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return ""; // number が null または undefined の場合は空文字列を返す
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const removeCommas = (number) => {
    if (number === null || number === undefined) return ""; // number が null または undefined の場合は空文字列を返す
    return number.toString().replace(/,/g, "");
  };

  const validateEventDates = (startDate, endDate) => {
    if (!startDate || !endDate) return true; // 片方しか入力されていない場合はバリデーションしない
    const start = new Date(startDate);
    const end = new Date(endDate);
    return end >= start;
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let { width, height } = img;

          if (width > height) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          } else {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }

          canvas.width = maxWidth;
          canvas.height = maxHeight;
          ctx.fillStyle = "#fff"; // 背景を白で塗りつぶす
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          const offsetX = (maxWidth - width) / 2;
          const offsetY = (maxHeight - height) / 2;
          ctx.drawImage(img, offsetX, offsetY, width, height);
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          }, file.type);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validTypes = ["image/png", "image/jpg", "image/jpeg"];
    const validFiles = files.filter((file) => validTypes.includes(file.type));

    if (validFiles.length !== files.length) {
      setFileError(true);
      setErrorMessage("PNG、JPG、JPEG以外の画像はアップロードできません");
      event.target.value = null;
      return;
    }

    if (validFiles.length > 1) {
      setFileError(true);
      setErrorMessage("画像は最大1枚までしか選択できません");
      event.target.value = null;
      return;
    }

    setFileError(false); // エラーメッセージをクリア
    setErrorMessage(""); // エラーメッセージをクリア

    const file = validFiles[0];
    resizeImage(file, 800, 800).then((resizedImage) => {
      setFileData([resizedImage]);
      const sanitizedFileName = DOMPurify.sanitize(file.name); // ファイル名をサニタイズ
      const url = URL.createObjectURL(
        new File([resizedImage], sanitizedFileName, { type: file.type })
      );
      setObjectUrl([url]);
    });
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  const handleCropAndUpload = async (postId, oldImageKeys) => {
    const now = new Date().toISOString();
    try {
      const imageChangedFlags = objectUrl.map(
        (url, index) => url !== post.images[index]
      ); // 変更フラグを設定

      // 削除対象のキーをフィルタリング
      const keysToDelete = oldImageKeys.filter(
        (key, index) => imageChangedFlags[index]
      );

      if (keysToDelete.length > 0) {
        await deleteOldImages(keysToDelete);
      }

      const uploadedImages = [];
      for (let i = 0; i < objectUrl.length; i++) {
        if (imageChangedFlags[i]) {
          const canvas = document.createElement("canvas");
          canvas.width = 150;
          canvas.height = 150;

          const ctx = canvas.getContext("2d");
          const img = await loadImage(objectUrl[i]);

          const aspectRatio = img.width / img.height;
          let drawWidth, drawHeight, offsetX, offsetY;

          if (aspectRatio > 1) {
            drawWidth = 150 * aspectRatio;
            drawHeight = 150;
            offsetX = (150 - drawWidth) / 2;
            offsetY = 0;
          } else {
            drawWidth = 150;
            drawHeight = 150 / aspectRatio;
            offsetX = 0;
            offsetY = (150 - drawHeight) / 2;
          }

          ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, "image/jpeg")
          );

          const fileName = `image${i + 1}_${now}.jpg`;
          const uploadedImage = await Storage.put(
            `posts/${postId}/${fileName}`,
            blob,
            {
              level: "public",
              contentType: "image/jpeg",
              bucket: process.env.REACT_APP_S3_BUCKET_PROD,
              region: process.env.REACT_APP_S3_REGION,
            }
          );

          uploadedImages.push(uploadedImage.key);
        } else {
          uploadedImages.push(post.images[i]); // 変更されていない場合は元のキーをそのまま使用する
        }
      }

      return [uploadedImages[0], uploadedImages[1], uploadedImages[2]];
    } catch (err) {
      console.error("Error updating post:", err);
      toast.error("画像のアップロードに失敗しました", { autoClose: 3000 });
      setIsUpdating(false);
      return [null, null, null];
    }
  };

  const updateInput = (key, value, subKey = null, subSubKey = null) => {
    const sanitizedValue = DOMPurify.sanitize(value); // 入力値をサニタイズ
    setPost((prev) => {
      if (subKey && subSubKey) {
        const newPostDetails = {
          ...prev.postDetails,
          [subKey]: {
            ...prev.postDetails[subKey],
            [subSubKey]: sanitizedValue, // サニタイズされた値を使用
          },
        };

        // イベント開始日と終了日のバリデーション
        if (subKey === "eventDetails") {
          const startDate =
            subSubKey === "startDate"
              ? sanitizedValue
              : newPostDetails.eventDetails.startDate;
          const endDate =
            subSubKey === "endDate"
              ? sanitizedValue
              : newPostDetails.eventDetails.endDate;

          if (!validateEventDates(startDate, endDate)) {
            return prev; // バリデーションに失敗した場合は更新しない
          } else {
            setErrorMessage(""); // エラーメッセージをクリア
          }
        }

        return {
          ...prev,
          postDetails: newPostDetails,
        };
      } else if (subKey) {
        return {
          ...prev,
          postDetails: {
            ...prev.postDetails,
            [subKey]: sanitizedValue, // サニタイズされた値を使用
          },
        };
      } else {
        // タイトルのバリデーションと文字数を更新
        if (key === "title") {
          setIsTitleValid(
            sanitizedValue.length >= 3 && sanitizedValue.length <= 50
          ); // ここを修正
          setTitleLength(sanitizedValue.length);
        }
        if (key === "content") {
          setIsContentValid(
            sanitizedValue.length >= 10 && sanitizedValue.length <= 700
          ); // ここを修正
          setContentLength(sanitizedValue.length);
        }

        if (key === "allowDirectMessages") {
          setAllowDirectMessages(sanitizedValue === "true");
        }
        if (key === "allowEmailContacts") {
          setAllowEmailContacts(sanitizedValue === "true");
        }
        if (key === "emailForContact") {
          setEmailForContact(sanitizedValue);
        }

        return {
          ...prev,
          [key]: sanitizedValue, // サニタイズされた値を使用
        };
      }
    });
  };

  const renderImages = () => {
    if (!post) return null;

    // 新しい画像が選択されている場合はそれを表示し、そうでない場合は元の画像を表示
    const imagesToDisplay = objectUrl.length > 0 ? objectUrl : post.images;

    return (
      <div className={styles.imageContainer}>
        {imagesToDisplay.map((url, index) => {
          const altText = `Image ${index}`;
          return (
            <ReactCrop
              key={index}
              onChange={(c) => setCrop(c)}
              aspect={1}
              keepSelection={true}
            >
              <div key={index} style={{ marginBottom: "0px" }}></div>
              <img
                src={
                  url === "../assets/news_sample.png"
                    ? require("../assets/news_sample.png")
                    : url
                }
                alt={altText}
              />
            </ReactCrop>
          );
        })}
      </div>
    );
  };

  const isEmailValid = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

    const japaneseRegex =
      /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]/;

    if (japaneseRegex.test(email)) {
      return false; // メールアドレスに日本語が含まれている場合は無効
    }

    return emailRegex.test(email);
  };

  /* const updatePostData = async () => {
    setIsUpdating(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

      const {
        title,
        content,
        category,
        postProvince,
        postCity,
        postDetails,
        oldImageKeys,
      } = post;

      // 給与金額が入力されていて給与体系が未選択の場合はエラーメッセージを表示して処理を停止
      if (
        postDetails.jobPostDetails &&
        postDetails.jobPostDetails.salary &&
        !postDetails.jobPostDetails.wageStructure
      ) {
        setErrorMessage("給与体系を選択してください");
        setIsUpdating(false);
        return;
      }

      // メールアドレスのバリデーション
      if (allowEmailContacts && !isEmailValid(emailForContact)) {
        setErrorMessage("正しいメールアドレスを入力してください");
        setIsUpdating(false);
        return;
      }

      if (postDetails.jobPostDetails) {
        postDetails.jobPostDetails.payCurrency = "JPY";
      }
      if (postDetails.rentRealEstatePostDetails) {
        postDetails.rentRealEstatePostDetails.payCurrency = "JPY";
      }
      if (postDetails.saleRealEstatePostDetails) {
        postDetails.saleRealEstatePostDetails.payCurrency = "JPY";
      }
      if (postDetails.MemberRecruitmentDetails) {
        postDetails.MemberRecruitmentDetails.payCurrency = "JPY";
      }
      if (postDetails.EventDetails) {
        postDetails.EventDetails.payCurrency = "JPY";
      }

      const uploadedImages = await handleCropAndUpload(post.id, oldImageKeys);

      if (uploadedImages == null) {
        return;
      }

      const updatedPost = {
        id,
        title,
        content,
        category,
        postProvince,
        postCity,
        updatedAt: new Date().toISOString(),
        postDetails,
        postingViews: post.postingViews,
        favoritesCount: post.favoritesCount,
        commentsCount: post.commentsCount,
        postingStatus: "ACTIVE",
        postImageUrl1: uploadedImages[0],
        //postImageUrl2: uploadedImages[1],
        //postImageUrl3: uploadedImages[2],
        allowDirectMessages,
        allowEmailContacts,
        emailForContact,
      };

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/posts`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(updatedPost),
        }
      );

      if (response.ok) {
        toast.success("投稿が更新されました");
        setTimeout(() => {
          navigate("/mypage#posts");
          setIsUpdating(false);
        }, 1100);
      } else {
        setIsUpdating(false);
        console.error("Error updating post");
      }
    } catch (err) {
      setIsUpdating(false);
      console.error("Error updating post:", err);
      toast.error("投稿の更新に失敗しました");
    }
  }; */

  const updatePostData = async () => {
    setIsUpdating(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;

      const {
        title,
        content,
        category,
        postProvince,
        postCity,
        postDetails,
        oldImageKeys,
      } = post;

      // 給与金額が入力されていて給与体系が未選択の場合はエラーメッセージを表示して処理を停止
      if (
        postDetails.jobPostDetails &&
        postDetails.jobPostDetails.salary &&
        !postDetails.jobPostDetails.wageStructure
      ) {
        setErrorMessage("給与体系を選択してください");
        setIsUpdating(false);
        return;
      }

      // メールアドレスのバリデーション
      if (allowEmailContacts && !isEmailValid(emailForContact)) {
        setErrorMessage("正しいメールアドレスを入力してください");
        setIsUpdating(false);
        return;
      }

      if (postDetails.jobPostDetails) {
        postDetails.jobPostDetails.payCurrency = "JPY";
      }
      if (postDetails.rentRealEstatePostDetails) {
        postDetails.rentRealEstatePostDetails.payCurrency = "JPY";
      }
      if (postDetails.saleRealEstatePostDetails) {
        postDetails.saleRealEstatePostDetails.payCurrency = "JPY";
      }
      if (postDetails.MemberRecruitmentDetails) {
        postDetails.MemberRecruitmentDetails.payCurrency = "JPY";
      }
      if (postDetails.EventDetails) {
        postDetails.EventDetails.payCurrency = "JPY";
      }

      const uploadedImages = await handleCropAndUpload(post.id, oldImageKeys);
      if (uploadedImages.includes(null)) {
        return; // 画像のアップロードに失敗した場合は処理を中断
      }

      const updatedPost = {
        id,
        title,
        content,
        category,
        postProvince,
        postCity,
        updatedAt: new Date().toISOString(),
        postDetails,
        postingViews: post.postingViews,
        favoritesCount: post.favoritesCount,
        commentsCount: post.commentsCount,
        postingStatus: "ACTIVE",
        postImageUrl1: uploadedImages[0],
        //postImageUrl2: uploadedImages[1],
        //postImageUrl3: uploadedImages[2],
        allowDirectMessages,
        allowEmailContacts,
        emailForContact,
      };

      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/posts`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify(updatedPost),
        }
      );

      if (response.ok) {
        toast.success("投稿が更新されました");
        setTimeout(() => {
          navigate("/mypage#posts");
          setIsUpdating(false);
        }, 1100);
      } else {
        setIsUpdating(false);
        console.error("Error updating post");
      }
    } catch (err) {
      setIsUpdating(false);
      console.error("Error updating post:", err);
      toast.error("投稿の更新に失敗しました");
    }
  };

  const deleteOldImages = async (imageKeys) => {
    try {
      // 画像キーの配列を最初の1つに制限
      const limitedImageKeys = imageKeys.slice(0, 1);

      // 認証されたユーザーを取得
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const deletePromises = limitedImageKeys.map(async (key) => {
        const filePath = `posts/${post.id}/${key}`; // posts/${postId}/${fileName}の形式に修正
        const response = await fetch(
          `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/postImages?filePath=${filePath}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
              "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error deleting images: ${response.status}`);
        }
      });

      await Promise.all(deletePromises);
    } catch (err) {
      console.error("Error deleting old images:", err);
      //toast.error("古い画像の削除に失敗しました", { autoClose: 3000 });
      setIsUpdating(false);
      throw new Error("Failed to delete old images");
    }
  };

  const endPost = async () => {
    setIsUpdating(true); // 追加
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const jwtToken = currentUser.signInUserSession.idToken.jwtToken;
      // 給与金額が入力されていて給与体系が未選択の場合はエラーメッセージを表示して処理を停止
      if (
        post.postDetails.jobPostDetails &&
        post.postDetails.jobPostDetails.salary &&
        !post.postDetails.jobPostDetails.wageStructure
      ) {
        setErrorMessage("給与体系を選択してください");
        setIsUpdating(false);
        return;
      }
      const response = await fetch(
        `https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/posts`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD,
          },
          body: JSON.stringify({
            id: id,
            postingStatus: "INACTIVE",
          }),
        }
      );
      if (response.ok) {
        toast.success("投稿が終了しました");
        setTimeout(() => {
          navigate("/mypage");
          setIsUpdating(false);
        }, 1000);
      } else {
        setIsUpdating(false);
        console.error("Error ending post");
      }
    } catch (err) {
      setIsUpdating(false);
      console.error("Error ending post:", err);
      toast.error("投稿の終了に失敗しました");
    }
  };

  const handleTurnstileVerify = async (token) => {
    fetch(
      "https://ugdzp4t3e7.execute-api.us-east-1.amazonaws.com/prod/verifyTurnstile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_GW_KEY_PROD, // APIキーを追加
        },
        body: JSON.stringify({ token }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setIsTurnstileVerified(true); // 認証成功時に状態を更新
        } else {
          setIsTurnstileVerified(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsTurnstileVerified(false);
      });
  };

  const renderCategorySpecificFields = () => {
    if (!post || !post.postDetails) return null;

    switch (post.category) {
      case "recruiting":
        const jobPostDetails = post.postDetails.jobPostDetails;

        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>給与体系：</label>
              <select
                value={jobPostDetails.wageStructure || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "jobPostDetails",
                    "wageStructure"
                  )
                }
              >
                <option value="">給与体系を選択</option>
                <option value="annual_income">年収</option>
                <option value="monthly_income">月給</option>
                <option value="daily_income">日給</option>
                <option value="hourly_income">時給</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>給与金額：</label>
              <input
                type="text"
                placeholder="給与金額"
                value={formatNumberWithCommas(
                  String(jobPostDetails.salary || "")
                )}
                onChange={(e) => {
                  let validValue = removeCommas(String(e.target.value)).replace(
                    /[^\d]/g,
                    ""
                  ); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "jobPostDetails",
                    "salary"
                  );
                }}
                maxLength="10"
              />
            </div>

            <div className={styles.formRow}>
              <label>雇用形態：</label>
              <select
                value={jobPostDetails.employmentType || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "jobPostDetails",
                    "employmentType"
                  )
                }
              >
                <option value="">雇用形態を選択</option>
                <option value="full_time">正社員</option>
                <option value="contract">契約社員</option>
                <option value="part_time">アルバイト</option>
                <option value="freelance">業務委託</option>
                <option value="executive">役員</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>職種：</label>
              <input
                type="text"
                placeholder="職種"
                value={jobPostDetails.jobCategory || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "jobPostDetails",
                    "jobCategory"
                  )
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>勤務地：</label>
              <input
                type="text"
                placeholder="勤務地"
                value={jobPostDetails.workLocation || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "jobPostDetails",
                    "workLocation"
                  )
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者名：</label>
              <input
                type="text"
                placeholder="担当者名"
                value={jobPostDetails.contactPerson || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "jobPostDetails",
                    "contactPerson"
                  )
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={jobPostDetails.email || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "jobPostDetails",
                    "email"
                  );
                }}
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者電話番号：</label>
              <input
                type="tel"
                placeholder="担当者電話番号"
                value={jobPostDetails.phone || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(/[^\d\+]/g, ""); // 半角数字と+以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "jobPostDetails",
                    "phone"
                  );
                }}
                maxLength="14"
                pattern="^\+?[0-9]*$"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={jobPostDetails.websiteURL || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "jobPostDetails",
                    "websiteURL"
                  );
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "joinus":
        const memberRecruitmentDetails =
          post.postDetails.memberRecruitmentDetails || {};
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>活動頻度：</label>
              <input
                type="text"
                placeholder="活動頻度 (ex: 毎週土曜日, 月1回)"
                value={memberRecruitmentDetails.eventDate || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "memberRecruitmentDetails",
                    "eventDate"
                  )
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>サブカテゴリ：</label>
              <select
                value={memberRecruitmentDetails.subCategory || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "memberRecruitmentDetails",
                    "subCategory"
                  )
                }
              >
                <option value="">サブカテゴリを選択</option>
                <option value="sports">スポーツ</option>
                <option value="fave">推し活</option>
                <option value="gaming">ゲーム</option>
                <option value="travel_buddies">旅行仲間</option>
                <option value="festival">祭り</option>
                <option value="music">音楽</option>
                <option value="art">アート</option>
                <option value="theater_show">演劇/ショー</option>
                <option value="exhibition">展示会</option>
                <option value="parenting">育児</option>
                <option value="party">パーティー</option>
                <option value="seminar">セミナー</option>
                <option value="flea_market">フリーマーケット</option>
                <option value="concert">コンサート</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>募集年齢：</label>
              <input
                type="text"
                placeholder="募集年齢"
                value={memberRecruitmentDetails.recruitmentAge || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "memberRecruitmentDetails",
                    "recruitmentAge"
                  )
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>参加費：</label>
              <input
                type="text"
                placeholder="参加費"
                value={formatNumberWithCommas(
                  memberRecruitmentDetails.participationFee || ""
                )}
                onChange={(e) => {
                  let validValue = removeCommas(e.target.value).replace(
                    /[^\d]/g,
                    ""
                  ); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "memberRecruitmentDetails",
                    "participationFee"
                  );
                }}
                maxLength="7"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={memberRecruitmentDetails.email || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue), // サニタイズを追加
                    "memberRecruitmentDetails",
                    "email"
                  );
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={memberRecruitmentDetails.websiteURL || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue), // サニタイズを追加
                    "memberRecruitmentDetails",
                    "websiteURL"
                  );
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "event":
        const eventDetails = post.postDetails.eventDetails || {};
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>イベント開始日：</label>
              <input
                type="date"
                placeholder="イベント開始日"
                value={eventDetails.startDate || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "eventDetails",
                    "startDate"
                  )
                }
              />
            </div>

            <div className={styles.formRow}>
              <label>イベント終了日：</label>
              <input
                type="date"
                placeholder="イベント終了日"
                value={eventDetails.endDate || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "eventDetails",
                    "endDate"
                  )
                }
              />
            </div>

            <div className={styles.formRow}>
              <label>サブカテゴリ：</label>
              <select
                value={eventDetails.subCategory || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "eventDetails",
                    "subCategory"
                  )
                }
              >
                <option value="">サブカテゴリを選択</option>
                <option value="sports">スポーツ</option>
                <option value="fave">推し活</option>
                <option value="gaming">ゲーム</option>
                <option value="travel_buddies">旅行仲間</option>
                <option value="festival">祭り</option>
                <option value="music">音楽</option>
                <option value="art">アート</option>
                <option value="theater_show">演劇/ショー</option>
                <option value="exhibition">展示会</option>
                <option value="parenting">育児</option>
                <option value="party">パーティー</option>
                <option value="seminar">セミナー</option>
                <option value="flea_market">フリーマーケット</option>
                <option value="concert">コンサート</option>
                <option value="other">その他</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>参加費：</label>
              <input
                type="text"
                placeholder="参加費"
                value={formatNumberWithCommas(
                  eventDetails.participationFee || ""
                )}
                onChange={(e) => {
                  const numericValue = removeCommas(e.target.value);
                  let validValue = numericValue.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue), // サニタイズされた値を使用
                    "eventDetails",
                    "participationFee"
                  );
                }}
                maxLength="7"
              />
            </div>

            <div className={styles.formRow}>
              <label>イベント時間：</label>
              <input
                type="text"
                placeholder="イベント時間 (ex: 10:00~17:00)"
                value={eventDetails.eventTime || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value), // サニタイズを追加
                    "eventDetails",
                    "eventTime"
                  )
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={eventDetails.websiteURL || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // ASCII文字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue), // サニタイズを追加
                    "eventDetails",
                    "websiteURL"
                  );
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "rentrealestate":
        const rentRealEstatePostDetails =
          post.postDetails.rentRealEstatePostDetails || {}; // デフォルト値を設定
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>賃貸カテゴリ：</label>
              <select
                value={rentRealEstatePostDetails.rentRealEstateCategory || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "rentRealEstatePostDetails",
                    "rentRealEstateCategory"
                  )
                }
              >
                <option value="">賃貸カテゴリを選択</option>
                <option value="rental_apartment">賃貸マンション</option>
                <option value="rental_house">賃貸戸建</option>
                <option value="rental_land">貸土地</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>家賃：</label>
              <input
                type="text"
                placeholder="家賃"
                value={formatNumberWithCommas(
                  rentRealEstatePostDetails.rent || ""
                )}
                onChange={(e) => {
                  let validValue = removeCommas(e.target.value).replace(
                    /[^\d]/g,
                    ""
                  ); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "rentRealEstatePostDetails",
                    "rent"
                  );
                }}
                maxLength="8"
              />
            </div>

            <div className={styles.formRow}>
              <label>敷金：</label>
              <input
                type="text"
                placeholder="敷金"
                value={formatNumberWithCommas(
                  rentRealEstatePostDetails.deposit || ""
                )}
                onChange={(e) => {
                  let validValue = removeCommas(e.target.value).replace(
                    /[^\d]/g,
                    ""
                  ); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "rentRealEstatePostDetails",
                    "deposit"
                  );
                }}
                maxLength="8"
              />
            </div>

            <div className={styles.formRow}>
              <label>礼金：</label>
              <input
                type="text"
                placeholder="礼金"
                value={formatNumberWithCommas(
                  rentRealEstatePostDetails.keyMoney || ""
                )}
                onChange={(e) => {
                  let validValue = removeCommas(e.target.value).replace(
                    /[^\d]/g,
                    ""
                  ); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "rentRealEstatePostDetails",
                    "keyMoney"
                  );
                }}
                maxLength="8"
              />
            </div>

            <div className={styles.formRow}>
              <label>専有面積(m2)：</label>
              <input
                type="text"
                placeholder="専有面積(m2)"
                value={rentRealEstatePostDetails.exclusiveArea || ""}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1);
                  }
                  if (value.length <= 4) {
                    updateInput(
                      "postDetails",
                      DOMPurify.sanitize(value),
                      "rentRealEstatePostDetails",
                      "exclusiveArea"
                    );
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>築年数：</label>
              <input
                type="text"
                placeholder="築年数"
                value={rentRealEstatePostDetails.builtYear || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  let validValue = value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  if (validValue.length <= 3) {
                    updateInput(
                      "postDetails",
                      DOMPurify.sanitize(validValue),
                      "rentRealEstatePostDetails",
                      "builtYear"
                    );
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>間取り：</label>
              <input
                type="text"
                placeholder="間取り(ex:2LDK,1Bedroom +1Bathroom)"
                value={rentRealEstatePostDetails.floorPlan || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "rentRealEstatePostDetails",
                    "floorPlan"
                  )
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>物件所在地：</label>
              <input
                type="text"
                placeholder="物件所在地"
                value={rentRealEstatePostDetails.rentRegion || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "rentRealEstatePostDetails",
                    "rentRegion"
                  )
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>入居可能時期：</label>
              <input
                type="text"
                placeholder="入居可能時期"
                value={rentRealEstatePostDetails.moveInDate || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "rentRealEstatePostDetails",
                    "moveInDate"
                  )
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者名：</label>
              <input
                type="text"
                placeholder="担当者名"
                value={rentRealEstatePostDetails.contactPerson || ""}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(e.target.value),
                    "rentRealEstatePostDetails",
                    "contactPerson"
                  )
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={rentRealEstatePostDetails.email || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "rentRealEstatePostDetails",
                    "email"
                  );
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL:</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={rentRealEstatePostDetails.websiteURL || ""}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "rentRealEstatePostDetails",
                    "websiteURL"
                  );
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "salerealestate":
        const saleRealEstatePostDetails =
          post.postDetails.saleRealEstatePostDetails || {}; // デフォルト値を設定
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>不動産の種類：</label>
              <select
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.saleRealEstateCategory || ""
                )}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    e.target.value,
                    "saleRealEstatePostDetails",
                    "saleRealEstateCategory"
                  )
                }
              >
                <option value="">不動産の種類を選択</option>
                <option value="new_apartment">新築マンション</option>
                <option value="used_apartment">中古マンション</option>
                <option value="new_house">新築戸建</option>
                <option value="used_house">中古戸建</option>
              </select>
            </div>

            <div className={styles.formRow}>
              <label>販売価格：</label>
              <input
                type="text"
                placeholder="販売価格"
                value={formatNumberWithCommas(
                  saleRealEstatePostDetails.salePrice || ""
                )}
                onChange={(e) => {
                  let validValue = removeCommas(e.target.value).replace(
                    /[^\d]/g,
                    ""
                  ); // 半角数字以外を排除
                  // 最初の数字が0でないことを確認
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1);
                  }
                  updateInput(
                    "postDetails",
                    DOMPurify.sanitize(validValue),
                    "saleRealEstatePostDetails",
                    "salePrice"
                  );
                }}
                maxLength="12"
              />
            </div>

            <div className={styles.formRow}>
              <label>土地面積(m2)：</label>
              <input
                type="text"
                placeholder="土地面積(m2)"
                value={saleRealEstatePostDetails.landArea || ""}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1);
                  }
                  if (value.length <= 4) {
                    updateInput(
                      "postDetails",
                      DOMPurify.sanitize(value),
                      "saleRealEstatePostDetails",
                      "landArea"
                    );
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>建物面積(m2)：</label>
              <input
                type="text"
                placeholder="建物面積(m2)"
                value={saleRealEstatePostDetails.buildingArea || ""}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (value.length > 1 && value.startsWith("0")) {
                    value = value.slice(1);
                  }
                  if (value.length <= 4) {
                    updateInput(
                      "postDetails",
                      DOMPurify.sanitize(value),
                      "saleRealEstatePostDetails",
                      "buildingArea"
                    );
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>築年数：</label>
              <input
                type="text"
                placeholder="築年数"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.builtYear || ""
                )}
                onChange={(e) => {
                  let validValue = e.target.value.replace(/[^\d]/g, ""); // 半角数字以外を排除
                  if (validValue.length > 1 && validValue.startsWith("0")) {
                    validValue = validValue.slice(1); // 先頭の0を削除
                  }
                  if (validValue.length <= 3) {
                    updateInput(
                      "postDetails",
                      DOMPurify.sanitize(validValue),
                      "saleRealEstatePostDetails",
                      "builtYear"
                    );
                  }
                }}
              />
            </div>

            <div className={styles.formRow}>
              <label>間取り：</label>
              <input
                type="text"
                placeholder="間取り(ex:2LDK,1Bedroom +1Bathroom)"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.floorPlan || ""
                )}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    e.target.value,
                    "saleRealEstatePostDetails",
                    "floorPlan"
                  )
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>物件所在地：</label>
              <input
                type="text"
                placeholder="物件所在地"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.saleRegion || ""
                )}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    e.target.value,
                    "saleRealEstatePostDetails",
                    "saleRegion"
                  )
                }
                maxLength="50"
              />
            </div>

            <div className={styles.formRow}>
              <label>引き渡し可能時期：</label>
              <input
                type="text"
                placeholder="引き渡し可能時期"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.availableDate || ""
                )}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    e.target.value,
                    "saleRealEstatePostDetails",
                    "availableDate"
                  )
                }
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者名：</label>
              <input
                type="text"
                placeholder="担当者名"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.contactPerson || ""
                )}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    e.target.value,
                    "saleRealEstatePostDetails",
                    "contactPerson"
                  )
                }
                maxLength="30"
              />
            </div>

            <div className={styles.formRow}>
              <label>担当者メール：</label>
              <input
                type="email"
                placeholder="担当者メール"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.email || ""
                )}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  updateInput(
                    "postDetails",
                    validValue,
                    "saleRealEstatePostDetails",
                    "email"
                  );
                }}
                maxLength="40"
              />
            </div>

            <div className={styles.formRow}>
              <label>ウェブサイトURL：</label>
              <input
                type="url"
                placeholder="ウェブサイトURL"
                value={DOMPurify.sanitize(
                  saleRealEstatePostDetails.websiteURL || ""
                )}
                onChange={(e) => {
                  const validValue = e.target.value.replace(
                    /[^\x00-\x7F]/g,
                    ""
                  ); // 半角英数字以外を排除
                  updateInput(
                    "postDetails",
                    validValue,
                    "saleRealEstatePostDetails",
                    "websiteURL"
                  );
                }}
                maxLength="50"
              />
            </div>
          </div>
        );

      case "bbs":
        const bbsDetails = post.postDetails.bbsDetails || {}; // デフォルト値を設定
        return (
          <div className={styles.formGroup}>
            <div className={styles.formRow}>
              <label>サブカテゴリ：</label>
              <select
                value={DOMPurify.sanitize(bbsDetails.subCategory || "")}
                onChange={(e) =>
                  updateInput(
                    "postDetails",
                    e.target.value,
                    "bbsDetails",
                    "subCategory"
                  )
                }
              >
                <option value="">サブカテゴリを選択</option>
                <option value="local_news">地元のニュース</option>
                <option value="local_shops">地元のお店</option>
                <option value="local gourmet">地元グルメ</option>
                <option value="local_products">地元の商品・サービス</option>
                <option value="chitchat">雑談</option>
                <option value="live_commentary">実況</option>
                <option value="sports">スポーツ</option>
                <option value="work">仕事</option>
                <option value="friends">友人</option>
                <option value="romance">恋愛</option>
                <option value="marriage_hunting">婚活</option>
                <option value="marriage">結婚</option>
                <option value="parenting">育児</option>
                <option value="mom_friends">ママ友</option>
                <option value="dad_friends">パパ友</option>
                <option value="relationships">人間関係</option>
                <option value="volunteer">ボランティア</option>
                <option value="money_investment">お金/投資</option>
                <option value="nursing">介護</option>
                <option value="hospital">病院</option>
                <option value="regional_revival">地方創生</option>
                <option value="local_economy">地元経済</option>
              </select>
            </div>
          </div>
        );

      default:
        return null; // 追加: 予期せぬcategoryの値の場合に何も表示しない
    }
  };

  if (!post) return <div>Loading...</div>;

  return (
    <div className={styles.container}>
      {isUpdating && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>更新中...</div>
        </div>
      )}
      <h2>投稿の編集</h2>
      {renderImages()} {/* ここで画像を表示 */}
      <div>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
          multiple
        />
        <h3 style={{ color: fileError ? "red" : "inherit" }}>
          画像を選択してください。画像は最大1枚まで投稿できます
        </h3>
        {fileError && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.titleForm}>
          <textarea
            type="text"
            onChange={(event) => updateInput("title", event.target.value)}
            value={post.title}
            placeholder="タイトル(3文字以上50文字以下)"
            maxLength="50"
            cols="80"
          />
          <div className={styles.contentLength}>
            <p style={{ color: isTitleValid ? "inherit" : "red" }}>
              {titleLength}/50
            </p>{" "}
            {/* タイトルの文字数カウントを表示 */}
          </div>
        </div>
        <div className={styles.contentForm}>
          <textarea
            onChange={(event) => updateInput("content", event.target.value)}
            value={post.content}
            placeholder="内容(10文字以上700文字以下)"
            maxLength="700"
            rows="11"
            cols="80"
          />
          <div className={styles.contentLength}>
            <p style={{ color: isContentValid ? "inherit" : "red" }}>
              {contentLength}/700
            </p>
          </div>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="allowDirectMessages"
              value="true"
              checked={allowDirectMessages === true}
              onChange={(event) =>
                updateInput("allowDirectMessages", event.target.value)
              }
            />
            直接メッセージを許可
          </label>
          <label>
            <input
              type="radio"
              name="allowDirectMessages"
              value="false"
              checked={allowDirectMessages === false || null}
              onChange={(event) =>
                updateInput("allowDirectMessages", event.target.value)
              }
            />
            直接メッセージを許可しない
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="allowEmailContacts"
              value="true"
              checked={allowEmailContacts === true}
              onChange={(event) =>
                updateInput("allowEmailContacts", event.target.value)
              }
            />
            メールでの連絡を許可
          </label>
          <label>
            <input
              type="radio"
              name="allowEmailContacts"
              value="false"
              checked={allowEmailContacts === false || null}
              onChange={(event) =>
                updateInput("allowEmailContacts", event.target.value)
              }
            />
            メールでの連絡を許可しない
          </label>
        </div>

        {allowEmailContacts && (
          <div>
            <label>問い合わせ用メールアドレス:</label>
            <input
              type="email"
              value={emailForContact}
              onChange={(event) =>
                updateInput("emailForContact", event.target.value)
              }
              placeholder="連絡用メールアドレス"
              style={{
                borderColor: isEmailValid(emailForContact) ? "inherit" : "red",
              }}
            />
            {!isEmailValid(emailForContact) && (
              <p style={{ color: "red", margin: "0px" }}>
                正しいメールアドレスを入力してください
              </p>
            )}
          </div>
        )}

        <div className={styles.horizontalContainer}>
          <select
            className={styles.select}
            value={post.category}
            onChange={(event) => updateInput("category", event.target.value)}
            disabled={true}
          >
            <option value="">カテゴリーを選択</option>
            {Object.entries(categories).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
          <JapanProvinceList
            className={styles.select}
            onSelect={(event) =>
              updateInput("postProvince", event.target.value)
            }
            selectedRegion={post.postProvince} // 追加: post.postProvinceを初期値として渡す
          />
          <JapanCitiesList
            className={styles.select}
            onSelect={(event) => updateInput("postCity", event.target.value)}
            selectedCity={post.postCity}
            region={post.postProvince}
            disabled={!post.postProvince}
          />
        </div>

        {renderCategorySpecificFields()}

        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
        <Turnstile
          sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
          onVerify={handleTurnstileVerify}
        />

        <button
          onClick={updatePostData}
          style={{ margin: "15px" }}
          disabled={
            isUpdating ||
            !isTurnstileVerified ||
            titleLength < 3 ||
            titleLength > 50 ||
            contentLength < 10 ||
            contentLength > 700 ||
            !post.postProvince ||
            (post.postDetails.jobPostDetails &&
              post.postDetails.jobPostDetails.salary &&
              !post.postDetails.jobPostDetails.wageStructure) ||
            (allowEmailContacts && !isEmailValid(emailForContact))
          }
        >
          更新して投稿する
        </button>
        <button
          onClick={endPost}
          style={{ margin: "15px" }}
          disabled={
            isUpdating ||
            !isTurnstileVerified ||
            titleLength < 3 ||
            titleLength > 50 ||
            contentLength < 10 ||
            contentLength > 700 ||
            !post.postProvince ||
            (post.postDetails.jobPostDetails &&
              post.postDetails.jobPostDetails.salary &&
              !post.postDetails.jobPostDetails.wageStructure) ||
            (allowEmailContacts && !isEmailValid(emailForContact))
          }
        >
          投稿を非公開にする
        </button>

        <br />
      </form>
      <ToastContainer />
    </div>
  );
}

export default PostEdit;
